/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { format } from 'date-fns';
// import { Controls } from '../dev2Controls/Controls';

import Title from './Title';

import loadMakePayments from '../../pages/MakePayments/hooks/loadMakePayments';
import { currencyFormatter } from '../Utils/currencyFormatter';

interface Stages {
  id: number;
  title: string;
  description: string;
  amountDue: any;
  dueDate: string;
  bacsName: string;
  cardAvailableName: string;
  outstandingBalance: any;
  amountPaid: any;
  pending: any;
  status: string;
}

export default function PaymentPlans(): any {
  const [paymentPlan, setPaymentPlan] = useState<any>();
  const [, setAppId] = useState(null);

  loadMakePayments(setPaymentPlan, setAppId, paymentPlan);

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  if (!paymentPlan) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }
  if (paymentPlan.acceptedPlan === null) {
    return (
      <>
        <Title>My Payment Plan Stages</Title>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          Payment plan not selected
        </Grid>
        <Grid
          container
          spacing={1}
          style={{ padding: '20px' }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} md={6}>
            <Link
              to="/paymentplans"
              style={{ textDecoration: 'none', color: '#ffb81c' }}
            >
              <Button variant="contained" color="primary" disableElevation>
                Select a payment plan
              </Button>
            </Link>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Title>My Payment Plan Stages</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Stage Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell style={{ minWidth: '120px' }}>Amount due</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Amount paid</TableCell>
            {/* <TableCell>Amount Pending</TableCell> */}
            <TableCell>Remaining balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentPlan.stages.map((item: Stages) => (
            <TableRow key={item.id}>
              <TableCell>{item.title}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>
                {item.amountDue ? currencyFormatter(item.amountDue) : ''}
              </TableCell>
              <TableCell>
                {item.dueDate === null
                  ? ''
                  : format(Date.parse(item.dueDate), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell>{item.status} </TableCell>
              <TableCell>
                {currencyFormatter(Number(item.amountPaid))}
              </TableCell>
              {/* <TableCell> {currencyFormatter(Number(item.pending))}</TableCell> */}
              <TableCell>
                {currencyFormatter(item.outstandingBalance)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Grid container>
        <Grid container spacing={1} style={{ padding: '20px' }}>
          <Grid item xs={12} md={6}>
            <Link
              to="/paymentplans"
              style={{ textDecoration: 'none', color: '#ffb81c' }}
            >
              {' '}
              <Button variant="contained" color="primary" disableElevation>
                See more
              </Button>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'block',
              width: '100%',

              textAlign: 'right',
              color: 'red',
            }}
          >
            <Link
              to={{
                pathname: '/makepayments',
              }}
              style={{
                textDecoration: 'none',
              }}
            >
              <Button variant="contained" color="primary" disableElevation>
                Make a Payment
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
