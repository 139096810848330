/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ContactMail, Home, Info } from '@material-ui/icons';

const style = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    background: theme.palette.primary.main,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    background: theme.palette.primary.main,
  },
}));

const StepIcons = (props: any): any => {
  const classes = style();

  const { active, completed, icon } = props;

  const icons = ['', <ContactMail />, <Home />, <Info />];

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[icon]}
    </div>
  );
};

export default StepIcons;
