/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { FiEye } from 'react-icons/fi';

import ReactLoading from 'react-loading';
import { Breadcrumbs, Grid } from '@material-ui/core';
import { format } from 'date-fns';
// import loadAwards from '../hooks/loadAwards';

import { Link } from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';

import api from '../../../services/api';
import Title from './Title';
import AwardPopUp from './AwardPopUp';
import { currencyFormatter } from '../../../components/Utils/currencyFormatter';
import { StyledBreadcrumb } from '../../../components/dev2Controls/StyledBreadCrumb';
import loadMakePayments from '../../MakePayments/hooks/loadMakePayments';
import { useGlobalState } from '../../../services/context/GlobalStateProvider';

interface AwardData {
  amountAwarded: string;
  applicantDecisionDate: string;
  applicationID: string;
  id: string;
  isScholarshipAccepted: string;
  processedDate: string;
  requestedDate: string;
  scholarship: string;
  scholarshipID: string;
  scholarshipStatus: string;
  scholarshipStatusID: string;
  offerExpiryDate: string;
}

// interface data {
//   appliedScholarshipId: string;
//   isScholarshipAccepted: string;
// }

export default function Orders(): any {
  const { userState } = useGlobalState();
  const [ShowPopup, setShowPopup] = useState(false);
  const [scholarshipID, setScholarshipID] = useState('');
  const [amountAwarded, setAmountAwarded] = useState('');
  const [scholarshipStatus, setScholarshipStatus] = useState('');
  const [award, setAward] = useState<AwardData[]>();

  const [appliedScholarshipIdId, setAppliedScholarshipIdId] = useState('');
  const [offerExpiryDate, setOfferExpiryDate] = useState('');
  const [applicantDecisionDate, setApplicantDecisionDate] = useState('');

  const [paymentPlan, setPaymentPlan] = useState<any>();

  loadMakePayments(setPaymentPlan, paymentPlan);

  useEffect(() => {
    async function loadAward(): Promise<void> {
      await api
        .get(
          `/Payment/GetBursaryScholarship?applicationId=${userState.appID}`,
          {
            headers: {
              cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
              Authorization: `Bearer ${userState.idToken}`,
            },
          },
        )
        .then(resp => {
          setAward(resp.data);
        });
    }
    loadAward();
  }, [userState.appID, userState.email, userState.idToken]);

  if (!award) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }

  if (!paymentPlan) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to="/"
          style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
        >
          <StyledBreadcrumb label="Home" icon={<HomeIcon fontSize="small" />} />
        </Link>
        <StyledBreadcrumb label="Awards" />
      </Breadcrumbs>
      <br />
      <Title>My Bursary and Scholarship Applications</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Amount Awarded</TableCell>
            <TableCell>Date Applied</TableCell>
            <TableCell>Offer Expiry Date</TableCell>
            <TableCell>Applicant Decision Date</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {award?.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.scholarship}</TableCell>
              <TableCell>{row.scholarshipStatus}</TableCell>
              <TableCell>
                {' '}
                {row.amountAwarded ? currencyFormatter(row.amountAwarded) : ''}
              </TableCell>
              <TableCell>
                {row.requestedDate === null
                  ? ''
                  : format(Date.parse(row.requestedDate), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell>
                {row.offerExpiryDate === null
                  ? ''
                  : format(Date.parse(row.offerExpiryDate), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell>
                {row.applicantDecisionDate === null
                  ? ''
                  : format(Date.parse(row.applicantDecisionDate), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell>
                {/* <Button
                  variant="contained"
                  fullWidth
                  style={{
                    marginRight: '10px',
                    backgroundColor:
                      row.scholarshipStatus === 'Approved'
                        ? '#00b33c'
                        : '#ffb81c',
                  }} */}
                <Button
                  variant="contained"
                  color={
                    row.scholarshipStatus === 'Approved'
                      ? 'secondary'
                      : 'primary'
                  }
                  disableElevation
                  fullWidth
                  style={{
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    setShowPopup(true);
                    setScholarshipID(row.scholarshipID);
                    setAppliedScholarshipIdId(row.id);
                    setAmountAwarded(row.amountAwarded);
                    setScholarshipStatus(row.scholarshipStatus);
                    setOfferExpiryDate(row.offerExpiryDate);
                    setApplicantDecisionDate(row.applicantDecisionDate);
                  }}
                >
                  <FiEye style={{ marginRight: '10px' }} />
                  {row.scholarshipStatus === 'Approved'
                    ? 'Action Required '
                    : 'View More'}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <br />
      {ShowPopup && (
        <AwardPopUp
          scholarshipID={scholarshipID}
          amountAwarded={amountAwarded}
          scholarshipStatus={scholarshipStatus}
          setShowPopup={setShowPopup}
          setAward={setAward}
          appliedScholarshipIdId={appliedScholarshipIdId}
          offerExpiryDate={offerExpiryDate}
          applicantDecisionDate={applicantDecisionDate}
          paymentPlan={paymentPlan}
        />
      )}
      <ToastContainer />
    </>
  );
}
