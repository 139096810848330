/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import ReactLoading from 'react-loading';
import { Button, Grid, Paper } from '@material-ui/core';

import { format } from 'date-fns';
import loadtransactions from '../../pages/PaymentHistory/hooks/loadTransactions';

import Title from './Title';
import { currencyFormatter } from '../Utils/currencyFormatter';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 250,
  },
}));

export default function Orders(): any {
  const classes = useStyles();
  const [transactions, setTransactions] = useState<any>();

  loadtransactions(setTransactions);

  if (!transactions) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }

  if (transactions.length === 0) {
    return (
      <Grid item xs={12}>
        <Paper className={classes.paper} style={{}}>
          <Title>Payment History</Title>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            No current Transactions
          </Grid>
        </Paper>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper} style={{}}>
        <Title>Payment History</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Transaction type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>

              <TableCell>Payment method</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Payment Reference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.slice(0, 5).map((row: any): any => (
              <TableRow key={row.id}>
                <TableCell>{row.transactionTypeName}</TableCell>

                <TableCell>
                  {row.createdon
                    ? format(new Date(row.createdon), 'dd/MM/yyyy')
                    : ''}
                </TableCell>
                <TableCell>
                  {' '}
                  {row.transactionAmount
                    ? currencyFormatter(row.transactionAmount)
                    : ''}
                </TableCell>

                <TableCell>{row.paymentMethodName}</TableCell>
                <TableCell>
                  {' '}
                  {row.paymentReceived === 862090000
                    ? 'Confirmed'
                    : row.paymentReceived === 862090001
                    ? 'Declined'
                    : 'Processing'}
                </TableCell>
                <TableCell>{row.paymentReference}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid container spacing={1} style={{ padding: '20px' }}>
          <Grid item xs={12} md={6}>
            <Link
              to="/history"
              style={{ textDecoration: 'none', color: '#ffb81c' }}
            >
              <Button variant="contained" color="primary" disableElevation>
                See more
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
