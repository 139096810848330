import { useState, useEffect } from 'react';

import api from '../../../services/api';

import { useGlobalState } from '../../../services/context/GlobalStateProvider';

const LoadAwards: any = () => {
  const [award, setAward] = useState<any>();
  const { userState } = useGlobalState();

  useEffect(() => {
    async function loadAward(): Promise<void> {
      await api
        .get(
          `/Payment/GetBursaryScholarship?applicationId=${userState.appID}`,
          {
            headers: {
              cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
              Authorization: `Bearer ${userState.idToken}`,
            },
          },
        )
        .then(resp => {
          setAward(resp.data);
        });
    }
    loadAward();
  }, [userState.appID, userState.email, userState.idToken]);

  return award;
};

export default LoadAwards;
