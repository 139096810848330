import React from 'react'
import { Radio, RadioGroup as MuiRadioGroup} from '@material-ui/core';
import {  FormControl, FormLabel,FormControlLabel} from '@material-ui/core';

export default function RadioGroup(props) {
const {name, label, value, onChange,items, disabled, error} = props
//console.log('items: ' + JSON.stringify(items));
    return (
        <FormControl  disabled={disabled}
        {...(error && { error: true })}
        >
        <FormLabel >{label}</FormLabel>
        <MuiRadioGroup row
          name={name}
          value={value}
          onChange={onChange}
         // disabled={disabled}
        >

            {

                items.map(
                    (item, index)=>(
                        <FormControlLabel value={item.id} control={<Radio/>}
                              label={item.title} key={index}></FormControlLabel>
                    )
                )
            }


        </MuiRadioGroup>
    </FormControl>
    )
}
