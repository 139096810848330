/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';

import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Slide from '@material-ui/core/Slide';
// import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';

import { Button, Dialog } from '@material-ui/core';
import { FiCheck, FiAlertTriangle } from 'react-icons/fi';

import ReactLoading from 'react-loading';
import { format } from 'date-fns';
import { Alert } from '@material-ui/lab';
import loadScholarshipInfo from '../hooks/loadScholarshipInfo';
// import postOffer from '../hooks/postOffer';
import api from '../../../services/api';
import { currencyFormatter } from '../../../components/Utils/currencyFormatter';
import { useGlobalState } from '../../../services/context/GlobalStateProvider';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    padding: {
      padding: 0,
    },
    mainContent: {
      padding: 30,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

interface scholarship {
  active: string;
  comments: string;
  id: string;
  max: string;
  min: string;
  paymentAwardTypeID: string;
  paymentAwardTypeName: string;
  programme: string;
  programmeID: string;
  title: string;
  typeName: string;
  valueType: string;
}

interface data {
  appliedScholarshipId: string;
  isScholarshipAccepted: string;
}

interface props {
  paymentPlan: any;
  scholarshipID: any;
  amountAwarded: any;
  scholarshipStatus: any;
  setShowPopup: any;
  setAward: any;

  appliedScholarshipIdId: any;
  offerExpiryDate: any;
  applicantDecisionDate: any;
}

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & { children?: React.ReactElement<any, any> },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const AwardPopUp: React.FC<props> = ({
  scholarshipID,
  amountAwarded,
  scholarshipStatus,
  setShowPopup,
  setAward,
  appliedScholarshipIdId,
  offerExpiryDate,
  applicantDecisionDate,
  paymentPlan,
}) => {
  const { userState } = useGlobalState();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();

  const scholarshipInfo: scholarship = loadScholarshipInfo(scholarshipID);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClose = () => {
    setOpen(false);
    setShowPopup(false);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOffer = useCallback(
    async (id: any, status: any) => {
      try {
        const newData: data = {
          isScholarshipAccepted: status,
          appliedScholarshipId: id,
        };

        await api
          .post(`/Payment/OfferDecision`, newData, {
            headers: {
              cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
              Authorization: `Bearer ${userState.idToken}`,
            },
          })
          .then(() => {
            api
              .get(
                `/Payment/GetBursaryScholarship?applicationId=${userState.appID}`,
                {
                  headers: {
                    cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
                    Authorization: `Bearer ${userState.idToken}`,
                  },
                },
              )
              .then(resp => {
                setAward(resp.data);
                toast.success('Award Scholarship Updated');
                handleClose();
              });
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        // console.log(err.response);
      }
    },
    [handleClose, setAward, userState.appID, userState.idToken],
  );

  if (!scholarshipInfo) {
    return (
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={open}
        // TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
        </Grid>
      </Dialog>
    );
  }

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={open}
        // TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Grid container style={{ width: '99%' }}>
          <Grid item xs={12}>
            <div style={{ float: 'right', cursor: 'pointer' }}>
              <CloseIcon onClick={handleClose} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              className={classes.mainContent}
              spacing={1}
            >
              <Grid item xs={12}>
                <b>Title:</b> {scholarshipInfo.title}
              </Grid>
              <Grid item xs={6}>
                <b>Current Status:</b> <br />
                {scholarshipStatus}
              </Grid>
              <Grid item xs={6}>
                <b>Amount Offered:</b> <br />
                {currencyFormatter(amountAwarded)}
              </Grid>

              <Grid item xs={6}>
                <b> Offer expiry date :</b> <br />
                {offerExpiryDate === null
                  ? ''
                  : format(Date.parse(offerExpiryDate), 'dd/MM/yyyy')}
              </Grid>
              <Grid item xs={6}>
                <b> Applicant decision date:</b> <br />
                {applicantDecisionDate === null
                  ? ''
                  : format(Date.parse(applicantDecisionDate), 'dd/MM/yyyy')}
              </Grid>
              {scholarshipStatus === 'Approved' && (
                <Grid item xs={12}>
                  <b>Action Required:</b> Please confirm your acceptance of this
                  offer prior to the Offer Expiry Date shown above. If you have
                  changed your mind and no longer wish to accept this offer,
                  please decline so that the funds can be allocated to other
                  students.
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {scholarshipStatus === 'Approved' && (
          <Grid item xs={12}>
            {paymentPlan.acceptedPlan === null && (
              <div style={{ padding: '20px' }}>
                <Alert severity="warning">
                  To accept the offer, you need to select a payment plan <br />
                  <a href="/paymentplans">Click here to be redirected </a>
                </Alert>
              </div>
            )}
            <div style={{ float: 'right', marginRight: '30px' }}>
              {paymentPlan.acceptedPlan !== null ? (
                <Button
                  variant="contained"
                  style={{ marginRight: '10px', backgroundColor: '#00b33c' }}
                  onClick={() => {
                    handleOffer(appliedScholarshipIdId, true);
                  }}
                >
                  <FiCheck style={{ marginRight: '10px' }} />
                  Accept Offer
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{ marginRight: '10px', backgroundColor: '#FF6D0A' }}
                  disabled
                >
                  <FiAlertTriangle style={{ marginRight: '10px' }} />
                  Accept Offer
                </Button>
              )}

              <Button
                variant="contained"
                style={{ backgroundColor: '#ff4d4d' }}
                onClick={() => {
                  handleOffer(appliedScholarshipIdId, false);
                }}
              >
                <CloseIcon style={{ marginRight: '10px' }} />
                Decline Offer
              </Button>
            </div>
          </Grid>
        )}
        <br />
      </Dialog>
    </div>
  );
};

export default AwardPopUp;
