import { useEffect } from 'react';

import api from '../../../services/api';
import { useGlobalState } from '../../../services/context/GlobalStateProvider';

const LoadPaymentPlans: any = (
  setPaymentPlan: any,
  setAppId: any,
  setTitle: any,
  setState: any,
) => {
  const { userState } = useGlobalState();

  useEffect(() => {
    async function loadPaymentPlan(): Promise<void> {
      await api
        .get(
          `/Payment/GetApplicationAcceptedPlan?applicationId=${userState.appID}`,
          // `/Payment/GetApplicationAcceptedPlan?applicationId=044d94f6-dc61-4504-b4a1-7a9ec236e0a5`,
          {
            headers: {
              cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
              Authorization: `Bearer ${userState.idToken}`,
            },
          },
        )
        .then(resp => {
          // api.get(`/Payment/GetPaymentPlanStages?planId=${resp.data.planId}`)
          api
            .get(
              `/Payment/GetPaymentPlans?applicationId=${userState.appID}&programmeId=${userState.programmeID}`,
              {
                headers: {
                  cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
                  Authorization: `Bearer ${userState.idToken}`,
                },
              },
            )
            .then(resp2 => {
              if (resp.data.acceptedPlan !== null) {
                const selectedPaymentPlan = resp2.data.filter(
                  (item: { id: any }) =>
                    item.id === resp.data.acceptedPlan.planId,
                );
                if (selectedPaymentPlan.length === 0) {
                  api
                    .get(
                      `/Payment/GetApplicationPaymentPlans?applicationId=${userState.appID}`,
                      {
                        headers: {
                          cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
                          Authorization: `Bearer ${userState.idToken}`,
                        },
                      },
                    )
                    .then(respBespoke => {
                      const selectedPaymentPlanBespoke =
                        respBespoke.data.filter(
                          (item: { paymentPlanID: any }) =>
                            item.paymentPlanID ===
                            resp.data.acceptedPlan.planId,
                        );

                      setPaymentPlan(selectedPaymentPlanBespoke);
                    });
                } else {
                  setPaymentPlan(selectedPaymentPlan);
                }

                setTitle(0);
                setState(resp.data.acceptedPlan.refundType.toString());
              } else {
                setPaymentPlan(resp2.data);

                setTitle(1);
                setState('-1');
              }
            });
        });
    }
    loadPaymentPlan();
  }, [
    setAppId,
    setPaymentPlan,
    setState,
    setTitle,
    userState.appID,
    userState.email,
    userState.idToken,
    userState.programmeID,
  ]);
};

export default LoadPaymentPlans;
