/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
// import { isAfter } from 'date-fns/esm';
import api from '../../../services/api';
import { useGlobalState } from '../../../services/context/GlobalStateProvider';

const FetchInvoicesData: any = (
  setMyInvoicesState: any,

  myInvoicesState: any,
) => {
  const { userState } = useGlobalState();
  // let appId = '';
  let id = 0;
  useEffect(() => {
    async function fetchData(): Promise<void> {

          await api
            .get(
              `/Payment/GetInvoiceByApplicationId?jbs_applicationId=${userState.appID}`,
              // `/Payment/GetApplicationAcceptedPlan?applicationId=73797931-629c-eb11-b1ac-000d3adcc6a1`,
              {
                headers: {
                  cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
                  Authorization: `Bearer ${
                    userState.idToken
                  }`,
                },
              },
            )
            .then(resp => {

             setMyInvoicesState({...myInvoicesState,
               myinvoices:  resp.data.map( (element:any)=> {

                id +=1;

                // eslint-disable-next-line no-param-reassign
                element.id =id;;

                return element;

              })
             })

           //  console.log(data);
            });

    }
    fetchData();
    // console.log('Inside useEffect');
    // console.log(globalState.appId);
  }, [userState.idToken, userState.email, setMyInvoicesState]);
  // console.log('Out UseEffect');
  // console.log(globalState.appId);
};

export default FetchInvoicesData;
