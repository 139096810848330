import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import ReactLoading from 'react-loading';
import CardContent from '@material-ui/core/CardContent';

import { Grid, Paper } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';

import HomeIcon from '@material-ui/icons/Home';
import { Link, useRouteMatch } from 'react-router-dom';

import api from '../../../../services/api';
import { StyledBreadcrumb } from '../../../../components/dev2Controls/StyledBreadCrumb';
import { useGlobalState } from '../../../../services/context/GlobalStateProvider';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    textAlign: 'center',
  },
});

interface PaymentParams {
  id: string;
}

export default function Payment(): any {
  const { params } = useRouteMatch<PaymentParams>();
  const classes = useStyles();
  const [value, setValue] = useState<any>('loading');
  const { userState } = useGlobalState();
  useEffect(() => {
    async function loadAward(): Promise<void> {
      await api
        .get(`/payment/details/${params.id}`, {
          headers: {
            cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
            Authorization: `Bearer ${userState.idToken}`,
          },
        })
        .then(response => {
          setValue(response.data);
        });
    }
    setTimeout(() => {
      loadAward();
    }, 1200);
  }, [params.id, userState.idToken]);

  if (value.isPaymentSuccessfulID === 4) {
    return (
      <>
        <Paper style={{ padding: '10px' }}>
          <Link
            to="/"
            style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
          >
            <StyledBreadcrumb
              label="Home"
              icon={<HomeIcon fontSize="small" />}
              style={{ marginBottom: '10px' }}
            />
          </Link>

          <Card className={classes.root}>
            <CardContent style={{ backgroundColor: '#00E874', color: '#ffff' }}>
              <CheckCircleOutlineIcon style={{ fontSize: 150 }} />
              <br />
              <div style={{ fontSize: 30 }}>Thank you, payment approved.</div>
            </CardContent>
          </Card>
        </Paper>
      </>
    );
  }

  if (
    value.isPaymentSuccessfulID === 0 ||
    value.isPaymentSuccessfulID === null
  ) {
    return (
      <>
        <Paper style={{ padding: '10px' }}>
          <StyledBreadcrumb
            label="Home"
            icon={<HomeIcon fontSize="small" />}
            style={{ marginBottom: '10px' }}
          />

          <Card className={classes.root}>
            <CardContent style={{ backgroundColor: '#E83500', color: '#ffff' }}>
              <HighlightOff style={{ fontSize: 150 }} />
              <br />
              <div style={{ fontSize: 30 }}>Payment denied, try again.</div>
            </CardContent>
          </Card>
        </Paper>
      </>
    );
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
    </Grid>
  );
}
