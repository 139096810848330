/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { isAfter } from 'date-fns/esm';
import api from '../../../services/api';

import { useGlobalState } from '../../../services/context/GlobalStateProvider';

const LoadPaymentPlans: any = (
  setGlobalState: any,

  globalState: any,
) => {
  const { userState } = useGlobalState();

  const appId = '';

  useEffect(() => {
    async function loadPaymentPlan(): Promise<void> {
      await api
        .get(
          // `/Payment/GetApplicationAcceptedPlan?applicationId=678a8334-f0f9-eb11-94ef-0022489cc1e4`,
          `/Payment/GetApplicationAcceptedPlan?applicationId=${userState.appID}`,
          // `/Payment/GetApplicationAcceptedPlan?applicationId=73797931-629c-eb11-b1ac-000d3adcc6a1`,
          {
            headers: {
              cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
              Authorization: `Bearer ${userState.idToken}`,
            },
          },
        )
        .then(async resp => {
          if (resp.data.acceptedPlan !== null) {
            const StagesId = resp.data.stages.map((item: any): any => {
              return item.id;
            });
            const FinalList: any[] = [];
            await api
              .get(
                // `/Payment/GetTransactionByStages?applicationId=678a8334-f0f9-eb11-94ef-0022489cc1e4`,
                `/Payment/GetTransactionByStages?applicationId=${userState.appID}`,

                // `/Payment/GetApplicationAcceptedPlan?applicationId=73797931-629c-eb11-b1ac-000d3adcc6a1`,
                {
                  headers: {
                    cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
                    Authorization: `Bearer ${userState.idToken}`,
                  },
                },
              )
              .then(async stageResp => {
                StagesId.map(async (item: any): Promise<any> => {
                  async function returnStage(
                    stageList: any,
                    pendingList: any,
                  ): Promise<any> {
                    const stages = [...resp.data.stages];
                    // Find Index stage - I will change.
                    const index = stages.findIndex(value => value.id === item);
                    // find transaction by id
                    const findTransaction = stageList.findIndex(
                      (elem: any) => elem.stageID === item,
                    );

                    const pending = pendingList.find(
                      (e: any) => e.stageID === item,
                    );

                    // set this stage in a variable.
                    const stage = { ...stages[index] };

                    if (findTransaction !== -1) {
                      // setting a new item in the array - Outstading Balance.
                      stage.outstandingBalance = (
                        stage.amountDue - stageList[findTransaction].stageAmount
                      ).toFixed(2);
                      // Amount paid
                      stage.amountPaid =
                        stageList[findTransaction].stageAmount.toFixed(2) || 0;
                      stage.index = index;

                      // Amount Pending;
                      stage.pending = pending
                        ? pending.stageAmount.toFixed(2)
                        : 0;

                      // status

                      stage.status =
                        // eslint-disable-next-line no-nested-ternary
                        stage.outstandingBalance === 0
                          ? 'Paid'
                          : isAfter(new Date(stage.dueDate), new Date())
                          ? 'Due'
                          : 'Overdue';

                      await FinalList.push(stage);
                    }
                    if (findTransaction === -1) {
                      // setting a new item in the array - Outstading Balance.
                      stage.outstandingBalance = stage.amountDue.toFixed(2);
                      // Amount paid
                      stage.amountPaid = 0;
                      stage.index = index;

                      // Pending
                      stage.pending = 0;

                      // status

                      stage.status =
                        // eslint-disable-next-line no-nested-ternary
                        stage.outstandingBalance === 0
                          ? 'Paid'
                          : isAfter(new Date(stage.dueDate), new Date())
                          ? 'Due'
                          : 'Overdue';

                      await FinalList.push(stage);
                    }
                  }
                  const { payments, refunds, pending } = stageResp.data;

                  // filter all transactions with paymentReceived

                  if (!payments && !refunds) {
                    const fakeData: any[] = [
                      {
                        pending: 0,
                        stageAmount: 0,
                        stageID: -100,
                      },
                    ];

                    const fakePending: any[] = [
                      {
                        pending: 0,
                        stageAmount: 0,
                        stageID: -100,
                      },
                    ];

                    await returnStage(fakeData, fakePending);

                    // Ordering by Index.
                    const orderList = FinalList.sort(
                      (a, b) => a.index - b.index,
                    );
                    const varorderList = orderList.map(obj => ({
                      ...obj,
                      isInvoiceForFullAmount: false,
                      invoiceAmountIfNotFullamount: obj.amountDue,
                      selected: false,
                      amountRequiredError: '',
                      amountTextBoxDisabled: false,
                      index: 0,
                      invoiceAmountError: '',
                      invoiceRequestSubmitted: false,
                    }));

                    setGlobalState({
                      ...globalState,
                      appId,
                      stageData: varorderList,
                    });
                  }
                  const stageList: any[] = [];
                  try {
                    // first, convert data into a Map with reduce
                    const sumPayment = payments
                      .filter((e: any) => e.paymentReceived !== null)
                      .reduce((prev: any, curr: any) => {
                        const count = prev.get(curr.stageID) || 0;
                        prev.set(curr.stageID, curr.stageAmount + count);
                        return prev;
                      }, new Map());

                    // first, convert data into a Map with reduce
                    const deductPayment = refunds.reduce(
                      (prev: any, curr: any) => {
                        const count = prev.get(curr.stageID) || 0;
                        prev.set(curr.stageID, curr.stageAmount - count);
                        return prev;
                      },
                      new Map(),
                    );

                    // then, map your counts object back to an array without duplicates
                    [...sumPayment].map(([stageID, stageAmount]) => {
                      return stageList.push({
                        stageID,
                        stageAmount,
                      });
                    });

                    // then, map your counts object back to an array without duplicates
                    [...deductPayment].map(([stageID, stageAmount]) => {
                      return stageList.push({
                        stageID,
                        stageAmount,
                      });
                    });

                    // first, convert data into a Map with reduce
                    const refundAndPayment = stageList?.reduce(
                      (prev: any, curr: any) => {
                        const count = prev.get(curr.stageID) || 0;
                        prev.set(curr.stageID, curr.stageAmount + count);
                        return prev;
                      },
                      new Map(),
                    );

                    const sumPending = pending.reduce(
                      (prev: any, curr: any) => {
                        const count = prev.get(curr.stageID) || 0;
                        prev.set(curr.stageID, curr.stageAmount + count);
                        return prev;
                      },
                      new Map(),
                    );

                    const Pendings = [...sumPending].map(
                      ([stageID, stageAmount]) => {
                        return {
                          stageID,
                          stageAmount,
                        };
                      },
                    );

                    const fixList = [...refundAndPayment].map(
                      ([stageID, stageAmount]) => {
                        return {
                          stageID,
                          stageAmount,
                        };
                      },
                    );

                    await returnStage(fixList, Pendings);

                    // Ordering by Index.
                    const orderList = FinalList.sort(
                      (a, b) => a.index - b.index,
                    );

                    const varorderList = orderList.map(obj => ({
                      ...obj,
                      isInvoiceForFullAmount: false,
                      invoiceAmountIfNotFullamount:
                        obj.amountDue - obj.amountPaid,
                      selected: false,
                      amountRequiredError: '',
                      amountTextBoxDisabled: false,
                      index: 0,
                      invoiceAmountError: '',
                      invoiceRequestSubmitted: false,
                    }));

                    setGlobalState({
                      ...globalState,
                      appId,
                      stageData: varorderList,
                    });
                  } catch (ex) {
                    console.log(ex);
                  }
                  // set this modification in the state
                });
              });
          } else {
            setGlobalState(resp.data);
          }
        });
    }
    loadPaymentPlan();
  }, [userState.idToken, userState.email, setGlobalState]);
};

export default LoadPaymentPlans;
