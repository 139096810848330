/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Grid, Button, TextField, Breadcrumbs } from '@material-ui/core';
import ReactSelect from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import { toast, ToastContainer } from 'react-toastify';
import ReactLoading from 'react-loading';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

import HomeIcon from '@material-ui/icons/Home';

import loadMakePayments from '../hooks/loadMakePayments';
import { currencyFormatter } from '../../../components/Utils/currencyFormatter';
import { StyledBreadcrumb } from '../../../components/dev2Controls/StyledBreadCrumb';

interface Stages {
  id: number;
  title: string;
  description: string;
  amountDue: any;
  dueDate: string;
  bacsPayments: string;
  cardPayments: string;
  status: string;
  newAmount: any;
  outstandingBalance: any;
  toPay: any;
  amountPaid: any;
}

const OptionsPayment = [
  { value: 0, label: 'BACS' },
  { value: 1, label: 'Card' },
];

export default function MakePayments(): any {
  const [amount, setAmount] = useState<any>('');
  // const [stageSelect, setStageSelect] = useState<any>();
  const [makePayment, setMakePayment] = useState<any>();
  const [appID, setAppId] = useState(null);
  const [paymentPlan, setPaymentPlan] = useState<any>();
  const [outstading, setOutstandig] = useState<any>();
  const [amountPay, setAmountPay] = useState<any>();
  const [paymentMethod, setPaymentMethod] = useState<any>();
  const [paymentOptions, setPaymentOptions] = useState<any[]>();

  // const [clean, setClean] = useState<any>();

  loadMakePayments(setPaymentPlan, setAppId);

  function validateNum(num: any): any {
    // eslint-disable-next-line prettier/prettier
    const re = /^\d*(\.\d{0,2})?$/;

    return re.test(num);
  }

  const recursive = (amountToPay: any): any => {
    if (amountToPay > 0) {
      const stages = [...paymentPlan.stages];

      const value = stages
        .filter((item: Stages) => item.outstandingBalance > 0)
        .filter((item: Stages) => item.newAmount !== 0);

      if (value.length === 0) {
        return 'Error';
      }

      const TotalOutstandingBalance = value.reduce(
        (a: Stages, b: Stages) => a.outstandingBalance + b.outstandingBalance,
      );
      const TotalAmountDue = value.reduce(
        (a: Stages, b: Stages) => a.amountDue + b.amountDue,
      );

      if (TotalOutstandingBalance - amountToPay <= -1) {
        return 'Error';
      }

      if (TotalAmountDue - amountToPay <= -1) {
        return 'Error';
      }

      const stage = value[0];

      const calcAmount = stage.outstandingBalance
        ? stage.outstandingBalance
        : stage.amountDue;

      const newCalc = calcAmount - amountToPay;

      stage.newAmount = newCalc >= 0 ? newCalc.toFixed(2) : 0;
      stage.toPay = amountToPay > calcAmount ? calcAmount : amountToPay;
      stage.appId = appID;
      stage.appNumber = paymentPlan.appNumber;
      const index = stages.findIndex((item: Stages) => item.id === stage.id);
      stages[index] = stage;

      amountToPay -= calcAmount;
      if (amountToPay > 0) {
        recursive(amountToPay);
      }

      // console.log(stage);
      // console.log(amountToPay);
    }
    if (amountToPay === '') {
      const stages = [...paymentPlan.stages];
      stages.map((item: any) => {
        const calcAmount = item.outstandingBalance;

        item.toPay = 0;
        item.newAmount = calcAmount;

        return item;
      });

      setPaymentPlan({ ...paymentPlan, stages });
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): any => {
    if (!validateNum(e.target.value)) {
      return '';
    }

    // When change reset all stages.
    const ResetStages = [...paymentPlan.stages];
    ResetStages.map((item: any) => {
      const calcAmount = item.outstandingBalance;

      item.toPay = 0;
      item.newAmount = calcAmount;

      return item;
    });

    setPaymentPlan({ ...paymentPlan, ResetStages });
    setMakePayment(false);

    const value = recursive(e.target.value);

    if (value === 'Error') {
      const stages = [...paymentPlan.stages];
      [...paymentPlan.stages].map((item: any): any => {
        const calcAmount = item.outstandingBalance;

        item.toPay = 0;
        item.newAmount = calcAmount;
        return item;
      });
      setPaymentPlan({ ...paymentPlan, stages });
      setAmount('');
      return toast.error('Amount not Valid');
    }

    setAmount(e.target.value);

    // setPaymentPlan({ ...paymentPlan, stages });
    return '';
  };

  const handleAdd = (): any => {
    const stages = [...paymentPlan.stages];

    const changesStages = stages.filter(
      stage => stage.toPay || stage.toPay > 0,
    );

    const Outstanding = changesStages.reduce((r: any, a: any) => {
      return r + Number(a.outstandingBalance);
    }, 0);

    const amountToPay = changesStages.reduce((r: any, a: any) => {
      return r + Number(a.toPay);
    }, 0);

    function ValidatePayment(states: any): any {
      const payments: any[] = [];

      // If is more tha One - count and compare if is the same of the total.
      const filterBacs = states.filter(
        (e: { bacsName: string }) => e.bacsName === 'Allowed',
      );

      const filterCards = states.filter(
        (e: { cardAvailableName: string }) => e.cardAvailableName === 'Allowed',
      );

      if (states.length === filterBacs.length) {
        payments.push({ value: 0, label: 'BACS' });
      }

      if (states.length === filterCards.length) {
        payments.push({ value: 1, label: 'Card' });
      }
      setPaymentOptions(payments);
    }

    ValidatePayment(changesStages);

    setOutstandig(Outstanding);
    setAmountPay(amountToPay);

    setMakePayment(changesStages);
    return '';
  };

  const handlePaymentSelect = (): any => {
    if (paymentMethod === 0) {
      return (
        <Link
          to={{
            pathname: '/bacsDetail',
            state: {
              makePayment,
              paymentPlan,
            },
          }}
          style={{
            textDecoration: 'none',
            color: '#ffb81c',
          }}
        >
          {/* <Button
            variant="outlined"
            style={{
              marginRight: '10px',
              color: '#00b33c',
            }}
          >

            Next
          </Button> */}
          <Button
            variant="contained"
            style={{
              color: 'black',
              backgroundColor: '#ffb81c',
            }}
          >
            {/* <FiCheck style={{ marginRight: '10px' }} /> */}
            Next
          </Button>
        </Link>
      );
    }
    if (paymentMethod === 1) {
      return (
        <Link
          to={{
            pathname: '/cardDetail',
            state: {
              makePayment,
              paymentPlan,
            },
          }}
          style={{
            textDecoration: 'none',
            color: '#ffb81c',
          }}
        >
          <Button
            variant="outlined"
            style={{
              marginRight: '10px',
              color: '#00b33c',
            }}
          >
            {/* <FiCheck style={{ marginRight: '10px' }} /> */}
            Next
          </Button>
        </Link>
      );
    }
    return (
      <Button
        variant="outlined"
        style={{
          marginRight: '10px',
          // color: '#00b33c',
        }}
        disabled
      >
        {/* <FiCheck style={{ marginRight: '10px' }} /> */}
        Next
      </Button>
    );
  };

  const handleSelectedPayment = (e: any): any => {
    setPaymentMethod(e.value);
  };

  if (!paymentPlan) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }

  if (paymentPlan.acceptedPlan === null) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        Payment plan not selected
      </Grid>
    );
  }

  // eslint-disable-next-line array-callback-return
  // const options = (paymentPlan.stages || []).map((item: any, index: any) => ({
  //   key: index,
  //   amountPaid: item.amountPaid,
  //   amountDue: item.amountDue,
  //   value: item.id,
  //   label: item.title,
  // }));

  return (
    <>
      <ToastContainer />

      <div>
        <div
          style={{
            padding: '5px',
            marginBottom: '5px',
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              to="/"
              style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
            >
              <StyledBreadcrumb
                label="Home"
                icon={<HomeIcon fontSize="small" />}
              />
            </Link>
            <StyledBreadcrumb label="Make Payments" />
          </Breadcrumbs>
          <br />
          <Alert severity="info">
            As soon as you have made a payment, you will be able to view this in
            the ‘Payment History’ area of the portal. Receipts will be
            automatically emailed once payment has been confirmed. IMPORTANT: If
            you have submitted an invoice request, please don't make a payment
            until after your invoice has been received.
          </Alert>

          <Grid
            container
            spacing={1}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <Grid item xs={12} md={4} lg={4}>
              <ReactSelect
                placeholder="Select payment method"
                name="payment"
                options={OptionsPayment}
                classNamePrefix="react-select"
                menuPosition="fixed"
                onChange={value => handleSelectedPayment(value)}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      {paymentMethod === 0 && 'Bacs'}
      {paymentMethod === 1 && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                elevation={3}
                style={{
                  width: 'auto',
                  height: 'auto',
                }}
              >
                <h3 style={{ color: '#ffb81c', padding: '6px' }}>
                  Stages - {paymentPlan.acceptedPlan.title}
                </h3>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Stage Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Amount due</TableCell>
                      <TableCell>Due Date</TableCell>
                      <TableCell>Outstanding balance</TableCell>
                      <TableCell>Outstanding after payment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentPlan.stages.map((item: Stages) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.status}</TableCell>
                        <TableCell>
                          {item.amountDue
                            ? currencyFormatter(item.amountDue)
                            : ''}
                        </TableCell>
                        <TableCell>
                          {item.dueDate === null
                            ? ''
                            : format(Date.parse(item.dueDate), 'dd/MM/yyyy')}
                        </TableCell>
                        <TableCell>
                          {currencyFormatter(item.outstandingBalance)}
                        </TableCell>
                        <TableCell>
                          {' '}
                          {item.newAmount >= 0
                            ? currencyFormatter(Number(item.newAmount))
                            : ''}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>

          <div>
            <div
              style={{
                padding: '5px',
                marginTop: '15px',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={5} lg={4}>
                      <h3 style={{ color: '#ffb81c', padding: '6px' }}>
                        Make Payment
                      </h3>
                      <div
                        style={{
                          padding: '10px',
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <TextField
                              type="number"
                              id="amount"
                              label="Amount (£)"
                              variant="outlined"
                              size="small"
                              autoComplete="off"
                              value={amount}
                              onChange={handleChange}
                            />
                            <Button
                              variant="outlined"
                              style={{ marginLeft: '15px', color: '#00b33c' }}
                              onClick={handleAdd}
                            >
                              <AddIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={8}>
                      {makePayment ? (
                        <div style={{ padding: '10px' }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Stage Name</TableCell>

                                <TableCell>Outstanding balance</TableCell>
                                <TableCell>Amount to pay</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {makePayment.map((item: Stages) => (
                                <TableRow key={item.id}>
                                  <TableCell>{item.title}</TableCell>

                                  <TableCell>
                                    {' '}
                                    {item.outstandingBalance
                                      ? currencyFormatter(
                                          item.outstandingBalance,
                                        )
                                      : currencyFormatter(item.amountDue)}
                                  </TableCell>
                                  <TableCell>
                                    {item.toPay
                                      ? currencyFormatter(Number(item.toPay))
                                      : ''}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="flex-end"
                            style={{ marginTop: '15px', padding: '15px' }}
                          >
                            <Grid item xs={12} md={6} lg={6}>
                              <Paper>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell />

                                      <TableCell>
                                        Outstanding balance{' '}
                                      </TableCell>
                                      <TableCell>Amount to pay </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>Total</TableCell>

                                      <TableCell>
                                        {currencyFormatter(outstading)}
                                      </TableCell>
                                      <TableCell>
                                        {currencyFormatter(amountPay)}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Paper>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            spacing={1}
                            justifyContent="flex-end"
                            style={{ marginTop: '15px', padding: '15px' }}
                          >
                            <Grid item xs={12} md={4} lg={4}>
                              <ReactSelect
                                placeholder="Select payment method"
                                name="payment"
                                options={paymentOptions}
                                classNamePrefix="react-select"
                                menuPosition="fixed"
                                onChange={value => handleSelectedPayment(value)}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}
    </>
  );
}
