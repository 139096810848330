/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography, Avatar, Chip } from '@material-ui/core';
import User from '@material-ui/icons/AccountCircle';
import { useMsal } from '@azure/msal-react';

import { useGlobalState } from '../../services/context/GlobalStateProvider';

export default function SimpleMenu(): any {
  const { instance } = useMsal();
  const { userState } = useGlobalState();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
      }}
    >
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar>
          <User />
        </Avatar>
        <div
          style={{
            textTransform: 'none',
            alignItems: 'center',
            justifyItems: 'center',
            marginLeft: '10px',
            fontSize: '13px',
          }}
        >
          <Typography>
            {userState.name} <br />
          </Typography>
          <Chip size="small" label={userState.appNumber} />
        </div>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => instance.logoutRedirect()}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
