/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react';
import {
  // FormHelperText,
  MenuItem,
  FormControl,
  makeStyles,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export default function Select(props: any): any {
  const { name, label, value, onChange, error = null, options } = props;

  const findValue = options.find((e: any) => e.id === value);

  const classes = useStyles();
  return (
    <div style={{ marginTop: '-8px' }}>
      <FormControl
        variant="outlined"
        className={classes.root}
        {...(error && { error: true })}
      >
        <TextField
          id="standard-select-currency"
          select
          label={label}
          name={name}
          value={value}
          variant="outlined"
          size="small"
          onChange={onChange}
          {...(error && { error: true })}
          SelectProps={{
            renderValue: () => (findValue ? findValue.title : ''),
          }}
          margin="normal"
        >
          <MenuItem value="">None</MenuItem>
          {options.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </TextField>

        {/* {error && <FormHelperText>{error}</FormHelperText>} */}
      </FormControl>
    </div>
  );
}
