import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  FormControlLabel,
  Grid,
  Typography,
  Checkbox,
  Card,
  // Button,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { format } from 'date-fns';
import { currencyFormatterWithoutSymbol } from '../../../components/Utils/currencyFormatterWithoutSymbol';
import { Controls } from '../../../components/dev2Controls/Controls';

interface Props {
  globalState: any;
  setGlobalState: any;
}

export default function InvoiceRequestPart1({
  globalState,
  setGlobalState,
}: Props): any {
  const invoiceReasons = [
    { id: 862090000, title: 'Personal tax purposes' },
    { id: 862090001, title: 'Payment by employer' },
    { id: 862090002, title: 'Loan company requires invoice' },
    { id: 862090003, title: 'To allow claiming of fees back from employer' },
    {
      id: 862090004,
      title: 'Required by bank to support internantional transfer',
    },
    { id: 862090005, title: 'Other' },
  ];

  // console.log(globalState.stageData);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={12}>
          {/* <Button
            variant="contained"
            style={{
              color: 'black',
              backgroundColor: '#ffb81c',
            }}
          >

            Next1
          </Button> */}
          <Alert severity="warning">
            Please only request an invoice if you have NOT yet made any
            payments, as we are unable to issue invoices once payment(s) have
            been made. If you are a studying a part-time programme over 2 years
            and have opted to pay in annual instalments, please only request
            invoice(s) for the current year <br />
          </Alert>
          <Alert severity="info">
            IMPORTANT NOTE:
            <br />
            You will be able to view all transactions via the portal under the
            Payment History section and can request a receipt for any payment
            via your Programme Admissions Manager. <br /> <br />
            Should you require more than one invoice per stage, please submit
            separate invoice requests for each invoice required – making sure
            that you amend the invoice amount accordingly each time
          </Alert>
        </Grid>
        <Grid container style={{ marginBottom: '10px' }}>
          <Card variant="outlined" style={{ padding: '10px', width: '100%' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Stage Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Amount(£)</TableCell>
                  <TableCell>Due date</TableCell>

                  <TableCell>Amount paid(£)</TableCell>
                  <TableCell>Remaining balance(£)</TableCell>
                  <TableCell>
                    <Checkbox
                      defaultChecked={globalState.allRecordsChecked}
                      onChange={event => {
                        const checked1 = event.target.checked;

                        const stagesInfo = [...globalState.stageData];
                        const varSelectedData = []; // [...globalState.selectedData];

                        const selectedRowIds = [];
                        // if (checked1) {
                        // eslint-disable-next-line no-plusplus
                        for (let ii = 0; ii < stagesInfo.length; ii++) {
                          stagesInfo[ii].selected = checked1;
                          if (checked1) {
                            selectedRowIds.push(stagesInfo[ii].id);

                            stagesInfo[ii].index = ii; // set the index so that it is used in tabs
                            varSelectedData.push(stagesInfo[ii]);
                          }
                          // stateValues.stageData[ii].selected = checked1;
                        }

                        setGlobalState({
                          ...globalState,
                          stageData: stagesInfo,
                          selected: selectedRowIds,
                          selectedData: !checked1 ? [] : varSelectedData,
                          atleastOneStageSelected: varSelectedData.length > 0,
                        });

                        // }
                      }}
                    />
                  </TableCell>
                  <TableCell>Invoice amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {globalState.stageData?.map((row: any) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        {currencyFormatterWithoutSymbol(Number(row.amountDue))}
                      </TableCell>

                      <TableCell>
                        {row.dueDate === null
                          ? ''
                          : format(Date.parse(row.dueDate), 'dd/MM/yyyy')}
                      </TableCell>
                      <TableCell>
                        {currencyFormatterWithoutSymbol(Number(row.amountPaid))}
                      </TableCell>
                      <TableCell>
                        {currencyFormatterWithoutSymbol(
                          Number(row.outstandingBalance),
                        )}
                      </TableCell>

                      <TableCell>
                        <Checkbox
                          // value={row.selected}
                          name="selected"
                          checked={row.selected}
                          // onSelect={() => handleSelect(row.id)}
                          // checked={row.selected}
                          onChange={event => {
                            const checked1 = event.target.checked;
                            //   console.log(checked1);
                            //  console.log('Showing  stateValues.stageData:');
                            // console.log(globalState.stageData);

                            const index = globalState.stageData.findIndex(
                              (e: any) => e.id === row.id,
                            );
                            //  alert(index);
                            // All Stagesc
                            const stagesInfo = [...globalState.stageData];
                            // change the index value
                            stagesInfo[index].selected = checked1;

                            let varSelectedData = [...globalState.selectedData];

                            const selectedRowIds = [...globalState.selected];
                            if (checked1) {
                              if (!selectedRowIds.includes(row.id)) {
                                selectedRowIds.push(row.id);

                                // stagesInfo[ii].index = ii; // set the index so that it is used in tabs
                                varSelectedData.push(stagesInfo[index]);
                              }
                            } else {
                              const indexToDeleteItem =
                                globalState.stageData.findIndex(
                                  (e: any) => e.id === row.id,
                                );

                              // console.log('###index to delete###');
                              // console.log(indexToDeleteItem);
                              selectedRowIds.splice(indexToDeleteItem, 1);
                              /*  const sliced = varSelectedData.splice(
                                indexToDeleteItem,
                                1,
                              ); */

                              varSelectedData = varSelectedData.filter(
                                x => x.id !== row.id,
                              );
                            }

                            let ii = 0;
                            // eslint-disable-next-line no-plusplus
                            for (ii = 0; ii < varSelectedData.length; ii++) {
                              varSelectedData[ii].index = ii;
                            }
                            // console.log(
                            //   '########selcted data after check changed:######',
                            // );
                            // console.log(selectedRowIds);
                            // console.log(varSelectedData);
                            // console.log(
                            //   '########selcted data after check changed:######',
                            // );

                            setGlobalState({
                              ...globalState,
                              stageData: stagesInfo,
                              selectedData: varSelectedData,
                              atleastOneStageSelected:
                                varSelectedData.length > 0,
                              noPaymentStageSelected:
                                varSelectedData.length === 0,
                              errorMessage: '',
                            });
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {/*   <FormControlLabel
                          control={
                            <Checkbox
                              name="isInvoiceForFullAmount"
                              checked={row.isInvoiceForFullAmount}
                              disabled={!row.selected}
                              onChange={event => {
                                const checked1 = event.target.checked;

                                const index = globalState.stageData.findIndex(
                                  (e: any) => e.id === row.id,
                                );

                                // All Stagesc
                                const stagesInfo = [...globalState.stageData];
                                // change the index value
                                stagesInfo[
                                  index
                                ].isInvoiceForFullAmount = checked1;

                                stagesInfo[
                                  index
                                ].isInvoiceForFullAmount = checked1;

                                // disable input box if full amount is checked
                                stagesInfo[
                                  index
                                ].amountTextBoxDisabled = checked1;

                                setGlobalState({
                                  ...globalState,
                                  stageData: stagesInfo,
                                });
                              }}
                            />
                          }
                          label="Full amount"
                        /> */}

                        <Controls.Input
                          name="invoiceAmountIfNotFullamount"
                          label="£"
                          type="number"
                          onInput={() => {
                            // e.target.value =
                            // eslint-disable-next-line radix
                            //  parseFloat(e.target.value);
                            // .toString()
                            // .slice(0, row.amountDue.toString().length);
                          }}
                          value={
                            row.invoiceAmountIfNotFullamount // - row.amountPaid
                          }
                          onChange={(event: any) => {
                            const { value } = event.target;

                            const index = globalState.stageData.findIndex(
                              (e: any) => e.id === row.id,
                            );

                            // All Stagesc
                            const stagesInfo = [...globalState.stageData];
                            const varStagesInfo = stagesInfo;
                            // change the index value
                            varStagesInfo[index].invoiceAmountIfNotFullamount =
                              value;

                            varStagesInfo[index].amountRequiredError =
                              value > row.amountDue
                                ? `Can't be more than £${row.amountDue}`
                                : '';

                            setGlobalState({
                              ...globalState,
                              stageData: varStagesInfo,
                            });
                          }}
                          error={row.amountRequiredError}
                          disabled={row.amountTextBoxDisabled || !row.selected}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card>
        </Grid>

        <Card variant="outlined" style={{ padding: '10px', width: '100%' }}>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Typography component="legend">
                How would you like to split the invoices? <br />
              </Typography>
              <div
                style={{
                  color: 'red',
                  fontSize: 'small',
                }}
              >
                {globalState.isSinglePaymentOrCompanyPaymentErrors}
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="oneInoiceForAllStages"
                    checked={globalState.oneInoiceForAllStages}
                    onChange={event => {
                      const checked1 = event.target.checked;
                      setGlobalState({
                        ...globalState,
                        oneInoiceForAllStages: checked1,
                        oneInvoiceEachForEachStage: !checked1,
                      });
                    }}
                  />
                }
                label="One invoice to cover all stages and amounts entered above"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="oneInvoiceEachForEachStage"
                    checked={globalState.oneInvoiceEachForEachStage}
                    onChange={event => {
                      const checked1 = event.target.checked;
                      setGlobalState({
                        ...globalState,
                        oneInoiceForAllStages: !checked1,
                        oneInvoiceEachForEachStage: checked1,
                      });
                    }}
                  />
                }
                label="A separate invoice for each stage selected and amount entered above"
              />
            </Grid>
          </Grid>
        </Card>

        <Card
          variant="outlined"
          style={{ padding: '10px', width: '100%', marginTop: '10px' }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              <Typography>
                Please select the reason for requiring an invoice:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controls.Select
                name="reasonForInvoiceFromChoice"
                label="Reason for invoice"
                value={globalState.reasonForInvoiceFromChoice}
                onChange={(event: any) => {
                  const { value } = event.target;

                  // console.log(invoiceReasons[recordIndex].title);
                  setGlobalState({
                    ...globalState,
                    reasonForInvoiceFromChoice: value,
                    //  invoiceReasons[recordIndex].title,
                  });
                }}
                error={globalState.reasonForInvoiceFromChoiceErrors}
                options={invoiceReasons}
              />
              <br />
              {globalState.reasonForInvoiceFromChoice === 862090005 ? (
                <div>
                  {/*  <Controls.TextareaAutosize
                    name="reasonForInvoiceFromChoice"
                    label="Invoice reason (Other )"
                    value={globalState.otherReason}
                    minWidth="300"
                    // onChange={handleInputChange}
                    fullwidth
                    error={globalState.reasonForInvoiceFromChoiceError}
                    rowsMin={4}
                    validate
                  /> */}

                  <Controls.Input
                    name="otherReason"
                    label="Invoice reason ( Other )"
                    value={globalState.otherReason}
                    multiline
                    fullWidth
                    onChange={(event: any) => {
                      const { value } = event.target;

                      // const varotherReasonError = globalState.otherReasonError; // save previous error message
                      setGlobalState({
                        ...globalState,
                        otherReason: value,
                        otherReasonError:
                          value !== '' ? '' : 'Please input other reason',
                      });
                    }}
                    error={globalState.otherReasonError}
                  />
                </div>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}
