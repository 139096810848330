/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
import React from 'react';

import { Card, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { IMyInvoicesGlobalState } from './interfaces';
// import {countries} from './constants'

interface Props {
  myInvoicesState: IMyInvoicesGlobalState;
}
const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },

  ControlHeaderlabel: {
    paddingBottom: theme.spacing(1),
    fontSize: 'small',
    color: 'grey',
  },
  ControlValueLabel: { color: 'Black' },
}));

export default function IndividualPaymentViewInvoice({
  myInvoicesState,
}: Props): any {
  // const arrayIndex = myInvoicesState.selectedInvoiceRowId;

  const classes = useStyles();

  // console.log(myInvoicesState.myinvoices);
  const stateValuePointer =
    myInvoicesState.myinvoices[myInvoicesState.selectedInvoiceRowId - 1];

  const invoiceStatus =
    myInvoicesState.myinvoices != null &&
    stateValuePointer != null &&
    stateValuePointer !== undefined
      ? stateValuePointer.invoiceStatus
      : '';

  return (
    <>
      <Card variant="outlined" style={{ padding: '10px', width: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={6}>
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Invoice amount: £
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {(
                  Math.round(stateValuePointer.jbs_invoicevalue * 100) / 100
                ).toFixed(2)}
              </span>
            </Grid>

            <Grid item xs={6}>
              <span className={classes.ControlHeaderlabel}> Status</span> <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {invoiceStatus}
              </span>
            </Grid>
          </Grid>

          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}> Name of payee</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_paymentname}
              </span>
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}> Invoice type</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.companyRequest ? 'Company' : 'Individual'}
              </span>
            </Grid>
            <Grid item xs={12} sm={8}>
              <span className={classes.ControlHeaderlabel}> Email address</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {/* 'thirupathi.kapparjdkdjskdjsakdsdjksdjskdskdskdsdjskdjskdjskdskdsa@gmail.com ' */}
                {stateValuePointer.jbs_emailaddress}
              </span>
              {/*  <Controls.Input
                disabled
                name="InvoiceEmailAddress"
                label="Email address"
                value={stateValuePointer.jbs_emailaddress}
                type="email"


                fullWidth
                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}>Phone</span> <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_Billingcontactphonenumber}
              </span>
              {/*   <Controls.Input
                disabled
                name="InvoicePhoneNumber"
                label="Phone"
                type="number"
                value={stateValuePointer.jbs_phonenumber}
                onInput={(e: any) => {
                  e.target.value = Math.max(
                    0,
                    // eslint-disable-next-line radix
                    parseInt(e.target.value),
                  )
                    .toString()
                    .slice(0, 14);
                }}


                size="small"
              /> */}
            </Grid>

            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Invoice address line1{' '}
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_addressline1}
              </span>
              {/*  <Controls.Input
                disabled
                name="InvoiceAddressLine1"
                label="Invoice address line 1"
                fullWidth
                value={stateValuePointer.jbs_addressline1}

                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Invoice address line2
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_addressline2}
              </span>
              {/*   <Controls.Input
                disable
                name="InvoiceAddressLine2"
                label="Invoice address line 2"
                fullWidth
                value={stateValuePointer.jbs_addressline2}


                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}> City</span> <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_city}
              </span>
              {/*  <Controls.Input
                disabled
                name="InvoiceCity"
                label="City"
                fullWidth
                value={stateValuePointer.jbs_city}

                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}> County state</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_countystate}
              </span>
              {/*  <Controls.Input
                disabled
                name="InvoiceCountryState"
                label="County state"
                fullWidth
                value={stateValuePointer.jbs_countystate}


                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}>Country</span> <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.country}
              </span>
              {/*  <Controls.Input
                disabled
                name="InvoiceCountry"
                label="Country"
                value={stateValuePointer.jbs_country}

                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}> Postcode</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_postcode}
              </span>
              {/*  <Controls.Input
                disabled
                name="InvoicePostCode"
                label="Postcode"
                value={stateValuePointer.jbs_postcode}

                size="small"
              /> */}
            </Grid>

            <Grid item sm={4}>
              <span className={classes.ControlHeaderlabel}>Reference</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_ponumber}
              </span>
            </Grid>

            <Grid item sm={8}>
              <span className={classes.ControlHeaderlabel}>Invoice reason</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {/* ===   stateValuePointer.jbs_reason */}
                {stateValuePointer.jbs_invoicereason === 862090005
                  ? stateValuePointer.jbs_reason
                  : stateValuePointer.jbs_invoicereason_name}
              </span>
            </Grid>
          </Grid>
        </div>
      </Card>
    </>
  );
}
