/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ReactLoading from 'react-loading';
import { Button, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import Title from './Title';
import loadAwards from '../../pages/Award/hooks/loadAwards';
import { currencyFormatter } from '../Utils/currencyFormatter';

interface AwardData {
  amountAwarded: string;
  applicantDecisionDate: string;
  applicationID: string;
  id: string;
  isScholarshipAccepted: string;
  processedDate: string;
  requestedDate: string;
  scholarship: string;
  scholarshipID: string;
  scholarshipStatus: string;
  scholarshipStatusID: string;
  offerExpiryDate: string;
}

export default function Orders(): any {
  const rows: AwardData[] = loadAwards();
  if (!rows) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }

  if (rows.length === 0) {
    return (
      <>
        <Title>My Bursary and Scholarship Applications</Title>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          No scholarships or Bursaries applied for
        </Grid>
      </>
    );
  }

  return (
    <>
      <Title>My Bursary and Scholarship Applications</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Scholarship</TableCell>
            <TableCell>Status</TableCell>
            <TableCell style={{ minWidth: '120px' }}>Amount Awarded</TableCell>
            <TableCell>Date Applied</TableCell>
            <TableCell>Offer Expiry Date</TableCell>
            <TableCell>Applicant Decision Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.scholarship}</TableCell>
              <TableCell>{row.scholarshipStatus}</TableCell>
              <TableCell>{currencyFormatter(row.amountAwarded)}</TableCell>
              <TableCell>
                {row.requestedDate === null
                  ? ''
                  : format(Date.parse(row.requestedDate), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell>
                {row.offerExpiryDate === null
                  ? ''
                  : format(Date.parse(row.offerExpiryDate), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell>
                {row.applicantDecisionDate === null
                  ? ''
                  : format(Date.parse(row.applicantDecisionDate), 'dd/MM/yyyy')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container spacing={1} style={{ padding: '20px' }}>
        <Grid item xs={12} md={6}>
          <Link
            to="/Award"
            style={{ textDecoration: 'none', color: '#ffb81c' }}
          >
            <Button variant="contained" color="primary" disableElevation>
              See more
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}
