import React, { useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReactLoading from 'react-loading';
import { Grid, Button } from '@material-ui/core';

import { Link } from 'react-router-dom';

import { format } from 'date-fns';

import fetchInvoicesData from '../../pages/MakeInvoice/hooks/fetchInvoicesData';

import Title from '../../pages/MakeInvoice/components/Title';
import { currencyFormatter } from '../Utils/currencyFormatter';

interface Props {
  // appId: any;
  isCompactView: any;
}

const initialValues = {
  isCompactView: false,
  selectedInvoiceRowId: -1,
  myinvoices: null,
};

export default function MyInvoicesDashBoardView({
  // appId,
  isCompactView,
}: Props): any {
  const [myInvoicesState, setMyInvoicesState] = useState<any>(initialValues);

  fetchInvoicesData(setMyInvoicesState, myInvoicesState);
  // fetchGeneratedInvoicesData(setGeneratedInvoicesState, generatedInvoicesState);
  // console.log(isCompactView);

  const varIvoicesData = myInvoicesState.myinvoices?.slice(0, 5); // showing only 5 rows in compact view

  // const invoiceStatus =
  //   myInvoicesState.myinvoices != null &&
  //   myInvoicesState.myinvoices[myInvoicesState.selectedInvoiceRowId - 1] !=
  //     null &&
  //   myInvoicesState.myinvoices[myInvoicesState.selectedInvoiceRowId - 1] !==
  //     undefined
  //     ? myInvoicesState.myinvoices[myInvoicesState.selectedInvoiceRowId - 1]
  //         .invoiceStatus
  //     : '';

  if (!myInvoicesState.myinvoices) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }

  return (
    <>
      {' '}
      <Title>My Invoices</Title>
      <Grid container spacing={1}>
        {/*  <Grid item lg={12}>
          <Alert severity="info">My invoices</Alert>
        </Grid> */}
        <Grid container style={{ marginBottom: '10px' }}>
          {/* <Card variant="outlined" style={{ padding: '10px', width: '100%' }}> */}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Payee</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Date requested</TableCell>
                <TableCell> Status </TableCell>

                <TableCell style={{ display: isCompactView ? 'none' : '' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {varIvoicesData.map((row: any) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.jbs_paymentname}</TableCell>
                    <TableCell>
                      {currencyFormatter(row.jbs_invoicevalue)}
                    </TableCell>
                    <TableCell>
                      {row.createdon === null
                        ? ''
                        : format(Date.parse(row.createdon), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell>{row.invoiceStatus}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ padding: '20px' }}>
        <Grid item xs={12} md={6}>
          <Link
            to={{
              pathname: '/myinvoices',
            }}
            style={{
              textDecoration: 'none',
            }}
          >
            <Button variant="contained" color="primary" disableElevation>
              SEE MORE
            </Button>
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'block',
            width: '100%',

            textAlign: 'right',
            color: 'red',
          }}
        >
          <Link
            to={{
              pathname: '/makeInvoice',
            }}
            style={{
              textDecoration: 'none',
            }}
          >
            <Button variant="contained" color="primary" disableElevation>
              REQUEST AN INVOICE
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}
