import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import Deposits from '../../components/items/MyDetails';
// import FeeSummary from '../../components/items/FeeSummary';

// import TasksList from '../../components/items/TasksList';
import ReactLoading from 'react-loading';
import Awards from '../../components/items/Awards';
import OutstandingPayments from '../../components/items/OutstandingPayments';
import PaymentHistory from '../../components/items/PaymentHistory';

import MyInvoicesDashBoardView from '../../components/items/MyInvoicesDashBoardView';
import loadMakePayments from '../MakePayments/hooks/loadMakePayments';
import loadAwards from '../Award/hooks/loadAwards';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 250,
  },
}));

interface AwardData {
  amountAwarded: string;
  applicantDecisionDate: string;
  applicationID: string;
  id: string;
  isScholarshipAccepted: string;
  processedDate: string;
  requestedDate: string;
  scholarship: string;
  scholarshipID: string;
  scholarshipStatus: string;
  scholarshipStatusID: string;
  offerExpiryDate: string;
}

const Home: React.FC = () => {
  const classes = useStyles();
  const rows: AwardData[] = loadAwards();
  const [paymentPlan, setPaymentPlan] = useState<any>();
  const [, setAppId] = useState(null);

  loadMakePayments(setPaymentPlan, setAppId, paymentPlan);

  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  if (!paymentPlan) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }

  if (!rows) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={1}>
        {paymentPlan.acceptedPlan === null && (
          <>
            <Grid item xs={12}>
              <Paper className={classes.paper} style={{ height: 'auto' }}>
                <OutstandingPayments />
              </Paper>
            </Grid>

            {rows.length !== 0 && (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Awards />
                </Paper>
              </Grid>
            )}

            <PaymentHistory />
          </>
        )}
        {paymentPlan.acceptedPlan !== null && (
          <>
            <Grid item xs={12}>
              <Paper className={classes.paper} style={{}}>
                <PaymentHistory />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper} style={{ height: 'auto' }}>
                <OutstandingPayments />
              </Paper>
            </Grid>

            {rows.length !== 0 && (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Awards />
                </Paper>
              </Grid>
            )}

            <Grid item xs={12}>
              <Paper className={classes.paper} style={{ height: 'auto' }}>
                <MyInvoicesDashBoardView
                  // appId="b9db5ea8-4c9e-e911-a994-000d3ab3984f"
                  isCompactView
                />
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Home;
