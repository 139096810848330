export const invoicesDataInitialised = (keyOrId: number): any => {
  const invoicesDataInitialValues = {
    isIndividualPaymentInvoice: false,
    isCompanyPaymentInvoice: false,
    id: keyOrId,
    invoiceDataIfIndividual: [
      {
        InvoiceNameOfThePayee: '',
        InvoiceAddressLine1: '',
        InvoiceAddressLine2: '',
        InvoiceCity: '',
        InvoiceCountryState: '',
        InvoiceCountry: '',
        InvoicePostCode: '',
        InvoiceEmailAddress: '',
        InvoicePhoneNumber: '',
        InvoiceReference: '',
        AdditionalInfo: '',
      },
    ],

    invoiceErrorsIfIndividual: [
      {
        InvoiceNameOfThePayee: '',
        InvoiceAddressLine1: '',
        InvoiceAddressLine2: '',
        InvoiceCity: '',
        InvoiceCountryState: '',
        InvoiceCountry: '',
        InvoicePostCode: '',
        InvoiceEmailAddress: '',
        InvoicePhoneNumber: '',
        InvoiceReference: '',
      },
    ],

    invoiceDataIfCompanyPayment: [
      {
        CompanyPayCompanyName: '',

        CompanyPayInvoiceAddressLine1: '',
        CompanyPayInvoiceAddressLine2: '',
        CompanyPayCity: '',
        CompanyPayCountryState: '',
        CompanyPayCountry: '',
        CompanyPayPostCode: '',
        CompanyPayRegistrationNumber: '',
        CompanyPayVatNo: '',
        CompanyPayBillingContactName: '',
        CompanyPayBillingContactNumber: '',
        CompanyPayPurchaseOrderNoOrInvoiceRef: '',
        CompanyPayEmailAddressToSendInvoice: '',
        CompanyPayDoesInvoiceNeedsUploadToPortal: '',
        AdditionalInfo: '',

        CompanyPayInvoiceUploadPortalUrl: '',
      },
    ],

    invoiceErrorsIfCompanyPayment: [
      {
        CompanyPayCompanyName: '',

        CompanyPayInvoiceAddressLine1: '',
        CompanyPayInvoiceAddressLine2: '',
        CompanyPayCity: '',
        CompanyPayCountryState: '',
        CompanyPayCountry: '',
        CompanyPayPostCode: '',
        CompanyPayRegistrationNumber: '',
        CompanyPayVatNo: '',
        CompanyPayBillingContactName: '',
        CompanyPayBillingContactNumber: '',
        CompanyPayPurchaseOrderNoOrInvoiceRef: '',
        CompanyPayEmailAddressToSendInvoice: '',
        CompanyPayDoesInvoiceNeedsUploadToPortal: '',
        CompanyPayInvoiceUploadPortalUrl: '',
      },
    ],
  };

  return invoicesDataInitialValues;
};

export const invoicesDataInitialValues = [
  {
    isIndividualPaymentInvoice: false,
    isCompanyPaymentInvoice: false,
    id: '',
    invoiceDataIfIndividual: [
      {
        InvoiceNameOfThePayee: '',
        InvoiceAddressLine1: '',
        InvoiceAddressLine2: '',
        InvoiceCountryState: '',
        InvoiceCountry: '',
        InvoicePostCode: '',
        InvoiceEmailAddress: '',
        InvoicePhoneNumber: '',
        InvoiceReference: '',
      },
    ],

    invoiceErrorsIfIndividual: [
      {
        InvoiceNameOfThePayee: '',
        InvoiceAddressLine1: '',
        InvoiceAddressLine2: '',
        InvoiceCountryState: '',
        InvoiceCountry: '',
        InvoicePostCode: '',
        InvoiceEmailAddress: '',
        InvoicePhoneNumber: '',
        InvoiceReference: '',
      },
    ],

    invoiceDataIfCompanyPayment: [
      {
        CompanyPayCompanyName: '',

        CompanyPayInvoiceAddressLine1: '',
        CompanyPayInvoiceAddressLine2: '',
        CompanyPayCountryState: '',
        CompanyPayCountry: '',
        CompanyPayPostCode: '',
        CompanyPayRegistrationNumber: '',
        CompanyPayVatNo: '',
        CompanyPayBillingContactName: '',
        CompanyPayBillingContactNumber: '',
        CompanyPayPurchaseOrderNoOrInvoiceRef: '',
        CompanyPayEmailAddressToSendInvoice: '',
        CompanyPayDoesInvoiceNeedsUploadToPortal: '',
      },
    ],

    invoiceErrorsIfCompanyPayment: [
      {
        CompanyPayCompanyName: '',

        CompanyPayInvoiceAddressLine1: '',
        CompanyPayInvoiceAddressLine2: '',
        CompanyPayCountryState: '',
        CompanyPayCountry: '',
        CompanyPayPostCode: '',
        CompanyPayRegistrationNumber: '',
        CompanyPayVatNo: '',
        CompanyPayBillingContactName: '',
        CompanyPayBillingContactNumber: '',
        CompanyPayPurchaseOrderNoOrInvoiceRef: '',
        CompanyPayEmailAddressToSendInvoice: '',
        CompanyPayDoesInvoiceNeedsUploadToPortal: '',
      },
    ],
  },
];

export const initialValues = {
  isTheCurrentFormValid: false,
  activeStep: 1,
  totalSteps: 1,
  activeTabIndex: 0,
  totalTabsCount: 0,
  noOfInvoicesSubmitted: 0,

  isInvoiceTabsActive: false,
  allRecordsChecked: false,
  atleastOneStageSelected: false,
  selected: [
    /*   {
      id: 1,
      amountDue: 300,
      isInvoiceForFullAmount: true,
      invoiceAmountIfNotFullamount: 0,
    },
    {
      id: 2,
      amountDue: 400,
      isInvoiceForFullAmount: false,
      invoiceAmountIfNotFullamount: 100,
    },
    {
      id: 3,
      amountDue: 1200,
      isInvoiceForFullAmount: true,
      invoiceAmountIfNotFullamount: 0,
    }, */
  ],

  selectedData: [
    // { invoiceRequestSubmitted: false, id: -1 },
    /*   {
      index: 0,
      title: 'Invoice 1',
      id: '123',
      isFullAmount: false,
      amountIfNotFullamount: '',
    },
    {
      index: 1,
      title: 'Invoice 2',
      id: '1234',
      isFullAmount: false,
      amountIfNotFullamount: '',
    },
    {
      index: 2,
      title: 'Invoice 3',
      id: '1235',
      isFullAmount: false,
      amountIfNotFullamount: '',
    }, */
  ],

  oneInoiceForAllStages: false,
  oneInvoiceEachForEachStage: false,
  isIndividualPaymentInvoice: false,
  isCompanyPaymentInvoice: false,

  isSinglePaymentOrCompanyPaymentErrors: '',

  reasonForInvoiceFromChoice: '',
  reasonForInvoiceFromChoiceErrors: '',
  otherReason: '',
  otherReasonError: '',

  invoicesData: invoicesDataInitialised(12345673445512567), // [invoicesDataInitialValues],

  stageData: [
    /*  {
      id: 1,
      title: 'stage 1',
      totalFees: 3456.5,
      description: 'test payment1 for stage1',
      selected: false,
    },
    {
      id: 2,
      title: 'stage 2',
      totalFees: 2450.5,
      description: 'test payment1 for stage2',
      selected: false,
    },
    {
      id: 3,
      title: 'stage 3',
      totalFees: 1500.5,
      description: 'test payment1 for stage3',
      selected: true,
    }, */
  ],
};
