/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
// import { isAfter } from 'date-fns/esm';
import api from '../../../services/api';
import { useGlobalState } from '../../../services/context/GlobalStateProvider';

const FetchGeneratedInvoicesData: any = (
  setGeneratedInvoicesState: any,

  // generatedInvoicesState: any,
) => {

  const { userState } = useGlobalState();
  // let appId = '';

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    async function fetchData(): Promise<void> {

          await api
            .get(
              `/Document/GetInvoiceFiles?applicationId=${userState.appID}`,
              // `/Payment/GetApplicationAcceptedPlan?applicationId=73797931-629c-eb11-b1ac-000d3adcc6a1`,
              {
                headers: {
                  cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
                  Authorization: `Bearer ${
                    userState.idToken
                  }`,
                },
              },
            )
            .then(resp => {


              let index = 0;
              const values =  resp.data.map( (element:any)=> {

                    index +=1;

                    // eslint-disable-next-line no-param-reassign
                    element.rowId =index;
                    // eslint-disable-next-line no-param-reassign
                    element.url = element["@microsoft.graph.downloadUrl"];
                    return element;
                  })


              setGeneratedInvoicesState(values);

            });

    }
    fetchData();

  }, [userState.email, userState.idToken, setGeneratedInvoicesState]);
};

export default FetchGeneratedInvoicesData;
