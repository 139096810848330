import Input from './Input';
import RadioGroup from './RadioGroup.js';
import Select from './Select';
import CheckBox from './CheckBox.js';
// import DatePicker from './DatePicker.js';
import Button from './Button';
import ActionButton from './ActionButton.js';
import TextareaAutosize from './TextareaAutosize';
import SelectCountries from './SelectCountries';

export const Controls = {
  Input,
  RadioGroup,
  Select,
  CheckBox,
  Button,
  ActionButton,
  TextareaAutosize,
  SelectCountries,
};
