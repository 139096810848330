import React from 'react';
import { TextField, Grid, Typography } from '@material-ui/core';
import { useStateValue } from '../StateContext';

const ContactForm = (): any => {
  const [{ formValues }, dispatch] = useStateValue();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Personal Details</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          label="Title"
          name="title"
          variant="outlined"
          size="small"
          style={{ width: '100px' }}
          required
          value={formValues.title}
          onChange={e =>
            dispatch({
              type: 'editFormValue',
              key: 'title',
              value: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="First Name"
          name="firstname"
          variant="outlined"
          size="small"
          required
          fullWidth
          value={formValues.firstname}
          onChange={e =>
            dispatch({
              type: 'editFormValue',
              key: 'firstname',
              value: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Middle Name"
          name="middlename"
          variant="outlined"
          size="small"
          fullWidth
          value={formValues.middlename}
          onChange={e =>
            dispatch({
              type: 'editFormValue',
              key: 'middlename',
              value: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Last Name"
          name="lastname"
          variant="outlined"
          size="small"
          required
          fullWidth
          value={formValues.lastname}
          onChange={e =>
            dispatch({
              type: 'editFormValue',
              key: 'lastname',
              value: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="Email Address"
          name="toemail"
          variant="outlined"
          size="small"
          required
          fullWidth
          value={formValues.toemail}
          onChange={e =>
            dispatch({
              type: 'editFormValue',
              key: 'toemail',
              value: e.target.value,
            })
          }
        />
      </Grid>
    </>
  );
};

export default ContactForm;
