/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import { Card, Grid, Typography } from '@material-ui/core';

import { Controls } from '../../../components/dev2Controls/Controls';
import { IglobalState } from './interfaces';

interface Props {
  globalState: IglobalState;
  setGlobalState: any;
}

export default function CompanyPayment({
  globalState,
  setGlobalState,
}: Props): any {
  const yesNoChoiceValues = [
    { id: 'yes', title: 'Yes' },
    { id: 'no', title: 'No' },
  ];

  const stateValuePointer =
    globalState.invoicesData[globalState.activeTabIndex]
      .invoiceDataIfCompanyPayment[0];
  const stateErrorPointer =
    globalState.invoicesData[globalState.activeTabIndex]
      .invoiceErrorsIfCompanyPayment[0];
  const arrayIndex = globalState.activeTabIndex;
  return (
    <>
      <Card variant="outlined" style={{ padding: '10px', width: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography component="legend">
                Amount due : £
                {/* globalState.selectedData[arrayIndex].outstandingBalance */}
                {globalState.oneInoiceForAllStages
                  ? globalState.totalStagesAmountIfOneInvoiceForAllStages
                  : globalState.selectedData[arrayIndex].outstandingBalance}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography component="legend">
                Invoice - Amount: £
                {/*
                  globalState.selectedData[arrayIndex]
                    .invoiceAmountIfNotFullamount
                */}
                {globalState.oneInoiceForAllStages
                  ? globalState.totalInvoiceAmountIfOneInvoiceForAllStages
                  : globalState.selectedData[arrayIndex]
                      .invoiceAmountIfNotFullamount}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="CompanyPayCompanyName"
                label="Company name"
                value={stateValuePointer.CompanyPayCompanyName}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayCompanyName = !value
                    ? '*'
                    : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayCompanyName = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayCompanyName}
                size="small"
                // helperText="As it should appear on invoice"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="CompanyPayInvoiceAddressLine1"
                label="Invoice address line1"
                fullWidth
                value={stateValuePointer.CompanyPayInvoiceAddressLine1}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayInvoiceAddressLine1 =
                    !value ? '*' : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayInvoiceAddressLine1 =
                    value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayInvoiceAddressLine1}
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="CompanyPayInvoiceAddressLine2"
                label="Invoice address line2"
                fullWidth
                value={stateValuePointer.CompanyPayInvoiceAddressLine2}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };

                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayInvoiceAddressLine2 =
                    value;
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayInvoiceAddressLine2}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                fullWidth
                name="CompanyPayRegistrationNumber"
                label="Company registration (or equivalent) no."
                value={stateValuePointer.CompanyPayRegistrationNumber}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayRegistrationNumber =
                    !value ? '*' : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayRegistrationNumber =
                    value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayRegistrationNumber}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                fullWidth
                name="CompanyPayEmailAddressToSendInvoice"
                label="Email"
                value={stateValuePointer.CompanyPayEmailAddressToSendInvoice}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayEmailAddressToSendInvoice =
                    !value ? '*' : '';

                  const re =
                    /^(([^<>()[\].,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                  // const re =
                  // '^(([^<>()[].,;:s@"]+(.[^<>()[].,;:s@"]+)*)|(".+"))@(([^<>()[].,;:s@"]+.)+[^<>()[].,;:s@"]{2,})$/i';

                  const errror = re.test(value) ? '' : 'Email not valid';

                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayEmailAddressToSendInvoice =
                    errror;

                  varNewInvoicesData[arrayIndex].newValuesEntered = true;

                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayEmailAddressToSendInvoice =
                    value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayEmailAddressToSendInvoice}
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                fullWidth
                name="CompanyPayCity"
                label="City"
                value={stateValuePointer.CompanyPayCity}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayCity = !value
                    ? '*'
                    : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayCity = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayCity}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                fullWidth
                name="CompanyPayCountryState"
                label="County state"
                value={stateValuePointer.CompanyPayCountryState}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayCountryState = !value
                    ? '*'
                    : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayCountryState = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayCountryState}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controls.SelectCountries
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="InvoiceCountry"
                label="Country"
                value={stateValuePointer.CompanyPayCountry}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayCountry = !value
                    ? '*'
                    : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;

                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayCountry = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayCountry}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                fullWidth
                name="CompanyPayPostCode"
                label="Post code"
                value={stateValuePointer.CompanyPayPostCode}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayPostCode = !value
                    ? '*'
                    : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayPostCode = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayPostCode}
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="CompanyPayVatNo"
                label="VAT (or equivalent) number"
                value={stateValuePointer.CompanyPayVatNo}
                fullWidth
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayVatNo = !value
                    ? '*'
                    : '';

                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayVatNo = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayVatNo}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                fullWidth
                name="CompanyPayBillingContactName"
                label="Billing contact name"
                value={stateValuePointer.CompanyPayBillingContactName}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayBillingContactName =
                    !value ? '*' : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayBillingContactName =
                    value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayBillingContactName}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                fullWidth
                name="CompanyPayBillingContactNumber"
                label="Billing contact phone number"
                value={stateValuePointer.CompanyPayBillingContactNumber}
                type="number"
                onInput={(e: any) => {
                  e.target.value = e.target.value.toString().slice(0, 14);
                }}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayBillingContactNumber =
                    !value ? '*' : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayBillingContactNumber =
                    value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayBillingContactNumber}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                fullWidth
                name="CompanyPayPurchaseOrderNoOrInvoiceRef"
                label="Purchase order no./Billing ref."
                value={stateValuePointer.CompanyPayPurchaseOrderNoOrInvoiceRef}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayPurchaseOrderNoOrInvoiceRef =
                    !value ? '*' : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayPurchaseOrderNoOrInvoiceRef =
                    value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayPurchaseOrderNoOrInvoiceRef}
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controls.RadioGroup
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                row
                name="CompanyPayDoesInvoiceNeedsUploadToPortal"
                label="Does invoice need uploading to a company portal for payment?"
                items={yesNoChoiceValues}
                error={
                  stateErrorPointer.CompanyPayDoesInvoiceNeedsUploadToPortal
                }
                value={
                  stateValuePointer.CompanyPayDoesInvoiceNeedsUploadToPortal
                }
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayDoesInvoiceNeedsUploadToPortal =
                    !value ? '*' : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayDoesInvoiceNeedsUploadToPortal =
                    value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
              />
            </Grid>
            {/* CompanyPayDoesInvoiceNeedsUploadToPortal */}
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              hidden={
                !(
                  stateValuePointer.CompanyPayDoesInvoiceNeedsUploadToPortal ===
                  'yes'
                )
              }
            >
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                fullWidth
                name="CompanyPayInvoiceUploadPortalUrl"
                label="Upload portal url"
                value={stateValuePointer.CompanyPayInvoiceUploadPortalUrl}
                onChange={(event: any) => {
                  const { value } = event.target;

                  const varNewInvoicesData = { ...globalState.invoicesData };
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfCompanyPayment[0].CompanyPayInvoiceUploadPortalUrl =
                    !value ? '*' : '';
                  varNewInvoicesData[arrayIndex].newValuesEntered = true;
                  varNewInvoicesData[
                    arrayIndex
                  ].invoiceDataIfCompanyPayment[0].CompanyPayInvoiceUploadPortalUrl =
                    value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varNewInvoicesData,
                  });
                }}
                error={stateErrorPointer.CompanyPayInvoiceUploadPortalUrl}
                size="small"
              />
            </Grid>
          </Grid>
        </div>
      </Card>
    </>
  );
}
