/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import { Card, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { IMyInvoicesGlobalState } from './interfaces';
// import theme from '../../../components/layout/theme';

interface Props {
  myInvoicesState: IMyInvoicesGlobalState;
}

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },

  ControlHeaderlabel: {
    paddingBottom: theme.spacing(1),
    fontSize: 'small',
    color: 'grey',
  },
  ControlValueLabel: { color: 'Black' },
}));

export default function CompanyPaymentViewInvoice({
  myInvoicesState,
}: Props): any {
  const classes = useStyles();

  const stateValuePointer =
    myInvoicesState.myinvoices[myInvoicesState.selectedInvoiceRowId - 1];
  return (
    <>
      <Card variant="outlined" style={{ padding: '10px', width: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={6}>
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Invoice amount: £
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {(
                  Math.round(stateValuePointer.jbs_invoicevalue * 100) / 100
                ).toFixed(2)}
              </span>
            </Grid>

            <Grid item xs={6}>
              <span className={classes.ControlHeaderlabel}> Invoice type</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.companyRequest ? 'Company' : 'Individual'}
              </span>
            </Grid>
          </Grid>

          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12} sm={8}>
              {/*  <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  className={classes.ControlHeaderlabel}
                >
                  Company name
                </FormLabel>
                <FormLabel
                  component="legend"
                  className={classes.ControlValueLabel}
                >
                  {stateValuePointer.jbs_name}
                </FormLabel>
              </FormControl> */}
              <span className={classes.ControlHeaderlabel}> Company name</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_paymentname}
              </span>
            </Grid>

            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Invoice address line1
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_addressline1}
              </span>
              {/*  <Controls.Input
                disabled
                name="CompanyPayInvoiceAddressLine1"
                label="Invoice address line1"
                fullWidth
                value={stateValuePointer.jbs_addressline1}
                size="small"
              /> */}
            </Grid>

            <Grid item xs={12} sm={4}>
              {/* <Controls.Input
                disabled
                name="CompanyPayInvoiceAddressLine2"
                label="Invoice address line2"
                fullWidth
                value={stateValuePointer.jbs_addressline2}
                size="small"
              /> */}
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Invoice address line2
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_addressline2 ? (
                  stateValuePointer.jbs_addressline2
                ) : (
                  <div style={{ color: '#fff' }}>-</div>
                )}
              </span>
            </Grid>
            <Grid item xs={12} sm={4}>
              {/*  <Controls.Input
                disabled
                fullWidth
                name="CompanyPayRegistrationNumber"
                label="Company registration"
                value={stateValuePointer.jbs_companyregistrationnumber}
                size="small"
              /> */}
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Company registration
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_companyregistrationnumber}
              </span>
            </Grid>
            <Grid item xs={12} sm={8}>
              <span className={classes.ControlHeaderlabel}> Email</span> <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_emailaddress}
              </span>
              {/*  <Controls.Input
                disabled
                fullWidth
                name="CompanyPayEmailAddressToSendInvoice"
                label="Email"
                value={stateValuePointer.jbs_emailaddress}
                size="small"
              /> */}
            </Grid>

            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}> City</span> <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_city}
              </span>
              {/*  <Controls.Input
                disabled
                fullWidth
                name="CompanyPayCity"
                label="City"
                value={stateValuePointer.jbs_city}
                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}> County state</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_countystate}
              </span>
              {/*  <Controls.Input
                disabled
                fullWidth
                name="CompanyPayCountryState"
                label="County state"
                value={stateValuePointer.jbs_countystate}
                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}> Country</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.country}
              </span>
              {/*  <Controls.SelectCountries
                disabled
                name="InvoiceCountry"
                label="Country"
                value={stateValuePointer.jbs_country}
                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}> Post code</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_postcode}
              </span>
              {/* <Controls.Input
                disabled
                fullWidth
                name="CompanyPayPostCode"
                label="Post code"
                value={stateValuePointer.jbs_postcode}
                size="small"
              /> */}
            </Grid>

            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}>
                {' '}
                VAT (or equivalent) number
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_VATnumber}
              </span>
              {/*  <Controls.Input
                disabled
                name="CompanyPayVatNo"
                label="VAT (or equivalent) number"
                value={stateValuePointer.jbs_VATnumber}
                fullWidth
                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={8}>
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Billing contact name
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_Billingcontactname}
              </span>
              {/* <Controls.Input
                disabled
                fullWidth
                name="CompanyPayBillingContactName"
                label="Billing contact name"
                value={stateValuePointer.jbs_name}
                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Billing contact phone number
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_Billingcontactphonenumber}
              </span>
              {/* <Controls.Input
                disabled
                fullWidth
                name="CompanyPayBillingContactNumber"
                label="Billing contact phone number"
                value={stateValuePointer.jbs_phonenumber}
                type="number"
                onInput={(e: any) => {
                  e.target.value = Math.max(
                    0,
                    // eslint-disable-next-line radix
                    parseInt(e.target.value),
                  )
                    .toString()
                    .slice(0, 14);
                }}
                size="small"
              /> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Purchase order no./Billing ref.
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_ponumber}
              </span>
              {/*  <Controls.Input
                disabled
                fullWidth
                name="CompanyPayPurchaseOrderNoOrInvoiceRef"
                label="Purchase order no./Billing ref."
                value={stateValuePointer.jbs_ponumber}
                size="small"
              /> */}
            </Grid>

            <Grid item xs={12} md={6}>
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Does invoice need uploading to a company portal for payment?
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_Uploadrequired ? 'Yes' : 'No'}
              </span>
              {/*  <Controls.RadioGroup
                disabled
                row
                name="CompanyPayDoesInvoiceNeedsUploadToPortal"
                label="Does invoice needs uploading to a company portal for payment"
                items={yesNoChoiceValues}
                value={!!stateValuePointer.jbs_Uploadrequired}
              /> */}
            </Grid>
            {/* CompanyPayDoesInvoiceNeedsUploadToPortal */}
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              hidden={!stateValuePointer.jbs_Uploadrequired}
            >
              <span className={classes.ControlHeaderlabel}>
                {' '}
                Upload portal url
              </span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_companyportalurl}
              </span>
              {/*  <Controls.Input
                disabled
                fullWidth
                name="CompanyPayInvoiceUploadPortalUrl"
                label="Upload portal url"
                value={stateValuePointer.jbs_companyportalurl}
                size="small"
              /> */}
            </Grid>
            <Grid item sm={8}>
              <span className={classes.ControlHeaderlabel}>Invoice reason</span>{' '}
              <br />
              <span className={classes.ControlValueLabel}>
                {' '}
                {stateValuePointer.jbs_invoicereason === 862090005
                  ? stateValuePointer.jbs_reason
                  : stateValuePointer.jbs_invoicereason_name}
              </span>
            </Grid>
          </Grid>
        </div>
      </Card>
    </>
  );
}
