/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react';
import {
  // FormHelperText,
  MenuItem,
  FormControl,
  makeStyles,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const countries = [
  { id: 100000001, title: 'Aruba' },
  { id: 100000002, title: 'Afghanistan' },
  { id: 100000003, title: 'Aland Islands' },
  { id: 100000004, title: 'Albania' },
  { id: 100000005, title: 'Algeria' },
  { id: 100000006, title: 'American Samoa' },
  { id: 100000007, title: 'Andorra' },
  { id: 100000008, title: 'Angola' },
  { id: 100000009, title: 'Anguilla' },
  { id: 100000010, title: 'Antarctica' },
  { id: 100000011, title: 'Antigua and Barbuda' },
  { id: 100000012, title: 'Argentina' },
  { id: 100000013, title: 'Armenia' },
  { id: 100000014, title: 'Australia' },
  { id: 100000015, title: 'Austria' },
  { id: 100000016, title: 'Azerbaijan' },
  { id: 100000017, title: 'Bahamas' },
  { id: 100000018, title: 'Bahrain' },
  { id: 100000019, title: 'Bangladesh' },
  { id: 100000020, title: 'Barbados' },
  { id: 100000021, title: 'Belarus' },
  { id: 100000022, title: 'Belgium' },
  { id: 100000023, title: 'Belize' },
  { id: 100000024, title: 'Benin' },
  { id: 100000025, title: 'Bermuda' },
  { id: 100000026, title: 'Bhutan' },
  { id: 100000027, title: 'Bolivia' },
  { id: 100000028, title: 'Bosnia and Herzegovina' },
  { id: 100000029, title: 'Botswana' },
  { id: 100000030, title: 'Bouvet Island' },
  { id: 100000031, title: 'Brazil' },
  { id: 100000032, title: 'British Indian Ocean Territory' },
  { id: 100000033, title: 'British National Overseas' },
  { id: 100000034, title: 'British Overseas Territories' },
  { id: 100000035, title: 'Brunei Darussalam' },
  { id: 100000036, title: 'Bulgaria' },
  { id: 100000037, title: 'Burkina Faso' },
  { id: 100000038, title: 'Burundi' },
  { id: 100000039, title: 'Cambodia' },
  { id: 100000040, title: 'Cameroon' },
  { id: 100000041, title: 'Canada' },
  { id: 100000042, title: 'Cape Verde' },
  { id: 100000043, title: 'Cayman Islands' },
  { id: 100000044, title: 'Central African Republic' },
  { id: 100000045, title: 'Chad' },
  { id: 100000046, title: 'Channel Islands' },
  { id: 100000047, title: 'Chile' },
  { id: 100000048, title: 'China' },
  { id: 100000049, title: 'Christmas Island' },
  { id: 100000050, title: 'Cocos (Keeling) Islands' },
  { id: 100000052, title: 'Colombia' },
  { id: 100000053, title: 'Comoros' },
  { id: 100000054, title: 'Congo' },
  { id: 100000055, title: 'Congo, The Democratic Republic of' },
  { id: 100000056, title: 'Cook Islands' },
  { id: 100000057, title: 'Costa Rica' },
  { id: 100000058, title: "Cote D'Ivoire" },
  { id: 100000059, title: 'Croatia' },
  { id: 100000060, title: 'Cuba' },
  { id: 100000061, title: 'Cyprus' },
  { id: 100000062, title: 'Czech Republic' },
  { id: 100000063, title: 'Denmark' },
  { id: 100000064, title: 'Djibouti' },
  { id: 100000065, title: 'Dominica' },
  { id: 100000066, title: 'Dominican Republic' },
  { id: 100000067, title: 'East Timor' },
  { id: 100000068, title: 'Ecuador' },
  { id: 100000069, title: 'Egypt' },
  { id: 100000070, title: 'El Salvador' },
  { id: 100000071, title: 'Equatorial Guinea' },
  { id: 100000072, title: 'Eritrea' },
  { id: 100000073, title: 'Estonia' },
  { id: 100000074, title: 'Ethiopia' },
  { id: 100000075, title: 'Falkland Islands (Malvinas)' },
  { id: 100000076, title: 'Faroe Islands' },
  { id: 100000077, title: 'Fiji' },
  { id: 100000078, title: 'Finland' },
  { id: 100000079, title: 'Macedonia, Former Yugoslav Republic of' },
  { id: 100000080, title: 'France' },
  { id: 100000081, title: 'French Guiana' },
  { id: 100000082, title: 'French Polynesia' },
  { id: 100000083, title: 'French Southern Territories' },
  { id: 100000084, title: 'Gabon' },
  { id: 100000085, title: 'Gambia' },
  { id: 100000086, title: 'Georgia' },
  { id: 100000087, title: 'Germany' },
  { id: 100000088, title: 'Ghana' },
  { id: 100000089, title: 'Gibraltar' },
  { id: 100000090, title: 'Greece' },
  { id: 100000091, title: 'Greenland' },
  { id: 100000092, title: 'Grenada' },
  { id: 100000093, title: 'Guadeloupe' },
  { id: 100000094, title: 'Guam' },
  { id: 100000095, title: 'Guatemala' },
  { id: 100000096, title: 'Guernsey' },
  { id: 100000097, title: 'Guinea' },
  { id: 100000098, title: 'Guinea-Bissau' },
  { id: 100000099, title: 'Guyana' },
  { id: 100000100, title: 'Haiti' },
  { id: 100000101, title: 'Heard and McDonald Islands' },
  { id: 100000102, title: 'Holy See (Vatican City State)' },
  { id: 100000103, title: 'Honduras' },
  {
    id: 100000104,
    title: 'Hong Kong, The Special Administrative Region of China',
  },
  { id: 100000105, title: 'Hungary' },
  { id: 100000106, title: 'Iceland' },
  { id: 100000107, title: 'India' },
  { id: 100000108, title: 'Indonesia' },
  { id: 100000109, title: 'Iran (Islamic Republic Of)' },
  { id: 100000110, title: 'Iraq' },
  { id: 100000111, title: 'Ireland' },
  { id: 100000112, title: 'Isle of Man' },
  { id: 100000113, title: 'Israel' },
  { id: 100000114, title: 'Italy' },
  { id: 100000115, title: 'Jamaica' },
  { id: 100000116, title: 'Japan' },
  { id: 100000117, title: 'Jersey' },
  { id: 100000118, title: 'Jordan' },
  { id: 100000119, title: 'Kazakhstan' },
  { id: 100000120, title: 'Kenya' },
  { id: 100000121, title: 'Kiribati' },
  { id: 100000122, title: "Korea, Democratic People's Republic of" },
  { id: 100000123, title: 'Korea, Republic of' },
  { id: 100000124, title: 'Kosovo' },
  { id: 100000125, title: 'Kuwait' },
  { id: 100000126, title: 'Kyrgyzstan' },
  { id: 100000127, title: 'Laos' },
  { id: 100000128, title: 'Latvia' },
  { id: 100000129, title: 'Lebanon' },
  { id: 100000130, title: 'Lesotho' },
  { id: 100000131, title: 'Liberia' },
  { id: 100000132, title: 'Libya' },
  { id: 100000133, title: 'Liechtenstein' },
  { id: 100000134, title: 'Lithuania' },
  { id: 100000135, title: 'Luxembourg' },
  { id: 100000136, title: 'Macao' },
  { id: 100000137, title: 'Madagascar' },
  { id: 100000138, title: 'Malawi' },
  { id: 100000139, title: 'Malaysia' },
  { id: 100000140, title: 'Maldives' },
  { id: 100000141, title: 'Mali' },
  { id: 100000142, title: 'Malta' },
  { id: 100000143, title: 'Marshall Islands' },
  { id: 100000144, title: 'Martinique' },
  { id: 100000145, title: 'Mauritania' },
  { id: 100000146, title: 'Mauritius' },
  { id: 100000147, title: 'Mayotte' },
  { id: 100000148, title: 'Mexico' },
  { id: 100000149, title: 'Micronesia' },
  { id: 100000150, title: 'Moldova, Republic of' },
  { id: 100000151, title: 'Monaco' },
  { id: 100000152, title: 'Mongolia' },
  { id: 100000153, title: 'Montserrat' },
  { id: 100000154, title: 'Morocco' },
  { id: 100000155, title: 'Mozambique' },
  { id: 100000156, title: 'Myanmar' },
  { id: 100000157, title: 'Namibia' },
  { id: 100000158, title: 'Nauru' },
  { id: 100000159, title: 'Nepal' },
  { id: 100000160, title: 'Netherlands' },
  { id: 100000161, title: 'Netherlands Antilles' },
  { id: 100000162, title: 'New Caledonia' },
  { id: 100000163, title: 'New Zealand' },
  { id: 100000164, title: 'Nicaragua' },
  { id: 100000165, title: 'Niger' },
  { id: 100000166, title: 'Nigeria' },
  { id: 100000167, title: 'Niue' },
  { id: 100000168, title: 'Norfolk Island' },
  { id: 100000169, title: 'Northern Mariana Islands' },
  { id: 100000170, title: 'Norway' },
  { id: 100000173, title: 'Oman' },
  { id: 100000174, title: 'Pakistan' },
  { id: 100000175, title: 'Palau' },
  { id: 100000176, title: 'Palestinian Territory' },
  { id: 100000177, title: 'Panama' },
  { id: 100000178, title: 'Papua New Guinea' },
  { id: 100000179, title: 'Paraguay' },
  { id: 100000180, title: 'Peru' },
  { id: 100000181, title: 'Philippines' },
  { id: 100000182, title: 'Pitcairn' },
  { id: 100000183, title: 'Poland' },
  { id: 100000184, title: 'Portugal' },
  { id: 100000185, title: 'Puerto Rico' },
  { id: 100000186, title: 'Qatar' },
  { id: 100000187, title: 'Montenegro, Republic of' },
  { id: 100000188, title: 'Serbia, Republic of' },
  { id: 100000189, title: 'Reunion' },
  { id: 100000190, title: 'Romania' },
  { id: 100000191, title: 'Russian Federation' },
  { id: 100000192, title: 'Rwanda' },
  { id: 100000193, title: 'Saint Barthelemy' },
  { id: 100000194, title: 'Saint Helena' },
  { id: 100000195, title: 'Saint Kitts and Nevis' },
  { id: 100000196, title: 'Saint Lucia' },
  { id: 100000197, title: 'Saint Martin' },
  { id: 100000198, title: 'Saint Pierre and Miquelon' },
  { id: 100000199, title: 'Samoa' },
  { id: 100000200, title: 'San Marino' },
  { id: 100000201, title: 'Sao Tome and Principe' },
  { id: 100000202, title: 'Saudi Arabia' },
  { id: 100000203, title: 'Senegal' },
  { id: 100000204, title: 'Seychelles' },
  { id: 100000205, title: 'Sierra Leone' },
  { id: 100000206, title: 'Singapore' },
  { id: 100000207, title: 'Slovakia' },
  { id: 100000208, title: 'Slovenia' },
  { id: 100000209, title: 'Solomon Islands' },
  { id: 100000210, title: 'Somalia' },
  { id: 100000211, title: 'South Africa' },
  { id: 100000212, title: 'South Sudan' },
  { id: 100000213, title: 'Spain' },
  { id: 100000214, title: 'Sri Lanka' },
  { id: 100000215, title: 'St Vincent and the Grenadines' },
  { id: 100000216, title: 'Stateless' },
  { id: 100000217, title: 'Sth Georgia &amp; Sth Sandwich Is' },
  { id: 100000218, title: 'Sudan' },
  { id: 100000219, title: 'Suriname' },
  { id: 100000220, title: 'Svalbard and Jan Mayen' },
  { id: 100000221, title: 'Swaziland' },
  { id: 100000222, title: 'Sweden' },
  { id: 100000223, title: 'Switzerland' },
  { id: 100000224, title: 'Syrian Arab Republic' },
  { id: 100000225, title: 'Taiwan' },
  { id: 100000226, title: 'Tajikistan' },
  { id: 100000227, title: 'Tanzania, United Republic of' },
  { id: 100000228, title: 'Thailand' },
  { id: 100000229, title: 'Togo' },
  { id: 100000230, title: 'Tokelau' },
  { id: 100000231, title: 'Tonga' },
  { id: 100000232, title: 'Trinidad and Tobago' },
  { id: 100000233, title: 'Tunisia' },
  { id: 100000234, title: 'Turkey' },
  { id: 100000235, title: 'Turkmenistan' },
  { id: 100000236, title: 'Turks and Caicos Islands' },
  { id: 100000237, title: 'Tuvalu' },
  { id: 100000238, title: 'Uganda' },
  { id: 100000239, title: 'Ukraine' },
  { id: 100000240, title: 'United Arab Emirates' },
  { id: 100000241, title: 'United Kingdom' },
  { id: 100000242, title: 'United States' },
  { id: 100000244, title: 'Uruguay' },
  { id: 100000245, title: 'US Minor Outlying Islands' },
  { id: 100000246, title: 'Uzbekistan' },
  { id: 100000247, title: 'Vanuatu' },
  { id: 100000248, title: 'Venezuela' },
  { id: 100000249, title: 'Viet Nam' },
  { id: 100000250, title: 'Virgin Islands (British)' },
  { id: 100000251, title: 'Virgin Islands (U.S.)' },
  { id: 100000252, title: 'Wallis and Futuna Islands' },
  { id: 100000253, title: 'Western Sahara' },
  { id: 100000254, title: 'Yemen' },
  { id: 100000255, title: 'Yugoslavia' },
  { id: 100000256, title: 'Zambia' },
  { id: 100000257, title: 'Zimbabwe' },
];

export default function SelectCountries(props: any): any {
  const { name, label, value, onChange, error = null, disabled } = props;

  const findValue = countries.find((e: any) => e.id === value);

  const classes = useStyles();
  return (
    <div style={{ marginTop: '-8px' }}>
      <FormControl
        variant="outlined"
        className={classes.root}
        {...(error && { error: true })}
      >
        <TextField
          id="standard-select-currency"
          select
          label={label}
          name={name}
          value={value}
          disabled={disabled}
          variant="outlined"
          size="small"
          onChange={onChange}
          {...(error && { error: true })}
          SelectProps={{
            renderValue: () => (findValue ? findValue.title : ''),
          }}
          margin="normal"
        >
          <MenuItem value="">None</MenuItem>
          {countries.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title}
            </MenuItem>
          ))}
        </TextField>
        {/* {error && <FormHelperText>{error}</FormHelperText>} */}
      </FormControl>
    </div>
  );
}
