/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Paper,
  Grid,
  Button,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Breadcrumbs,
} from '@material-ui/core';

import HomeIcon from '@material-ui/icons/Home';

import { useHistory, Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import ReactLoading from 'react-loading';

import { format } from 'date-fns';
import { toast, ToastContainer } from 'react-toastify';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from './Title';
import api from '../../../services/api';
import loadPaymentPlans from '../hooks/loadPaymentPlans';
import { StyledBreadcrumb } from '../../../components/dev2Controls/StyledBreadCrumb';
import { useGlobalState } from '../../../services/context/GlobalStateProvider';

interface Stages {
  id: number;
  title: string;
  description: string;
  amountDue: string;
  dueDate: string;
  bacsName: string;
  cardAvailableName: string;
}

interface PaymentPlanData {
  id: string;
  title: string;
  reference: string;
  depositValue: string;
  totalFees: number;
  planTypeID: number;
  programmeID: string;
  // jbs_processeddate: string;
  description: string;
  comments: string;
  overviewLink: string;
  paymentPlanStatusID: string;
  paymentPlanStatus: string;

  stages: Stages[];
}

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      // margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 'auto',
  },
}));

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);

export default function PaymentPlans(): any {
  const { userState } = useGlobalState();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [paymentPlan, setPaymentPlan] = useState<PaymentPlanData[] | null>(
    null,
  );
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [appID, setAppId] = useState(null);
  const [formID, setFormID] = useState<any>();
  const [formTitle, setFormTitle] = useState<any>();
  const [newTitle, setTitle] = useState(null);

  const [loading, setLoading] = useState(false);

  const [state, setState] = React.useState('');

  loadPaymentPlans(setPaymentPlan, setAppId, setTitle, setState);

  // const [expanded, setExpanded] = React.useState<string | false>('panel1');

  // const handleChange = (panel: string) => (
  //   event: React.ChangeEvent<any>,
  //   newExpanded: boolean,
  // ) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  const handleChangeRadio = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): any => {
    setState((event.target as HTMLInputElement).value);
  };

  const handlePlan = useCallback(
    async (applicationID: any, planID: any, title: any) => {
      try {
        setLoading(true);
        if (state === '-1') {
          setLoading(false);
          return toast.error(
            'Please make sure you have selected Refund of overpayments',
          );
        }
        const refundType = state;

        const newData = {
          applicationID: userState.appID,
          planID,
          title,
          refundType,
          applicationNumber: userState.appNumber,
        };

        await api
          .post(`/Payment/AcceptPaymentPlan `, newData, {
            headers: {
              cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
              Authorization: `Bearer ${userState.idToken}`,
            },
          })
          .then(() => {
            toast.success('Plan successfully selected');
            setTimeout(() => {
              history.push(`/`);
            }, 3200);
          });
        return '';
      } catch (err) {
        // eslint-disable-next-line no-console
        // console.log(err.response);
      }
      return '';
    },
    [history, state, userState.appID, userState.appNumber, userState.idToken],
  );

  const handleClickOpen = (): any => {
    setOpen(true);
  };

  const handleClose = (): any => {
    setOpen(false);
  };

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  if (!state && !paymentPlan && !newTitle) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }

  return (
    <>
      <ToastContainer />
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to="/"
          style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
        >
          <StyledBreadcrumb label="Home" icon={<HomeIcon fontSize="small" />} />
        </Link>

        <StyledBreadcrumb label="Payment Plan" />
      </Breadcrumbs>

      <br />
      <Title>
        {newTitle === 0 ? 'My Payment Plan' : 'Select Payment Plan'}
      </Title>

      {paymentPlan?.map((row, index) => (
        <Accordion
          square
          // expanded={expanded === row.id}
          // onChange={handleChange(row.id)}

          key={index}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>{row.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Paper className={fixedHeightPaper}>
              <div key={index}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Plan Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Total fee</TableCell>
                      <TableCell>Deposit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={row.id}>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        {row.totalFees ? `£${row.totalFees}` : ''}
                      </TableCell>
                      <TableCell>
                        {' '}
                        {row.depositValue ? `£${row.depositValue}` : ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div
                  style={{
                    padding: '5px',
                    marginBottom: '5px',
                  }}
                >
                  <h3 style={{ color: '#ffb81c', padding: '6px' }}>Stages</h3>
                  <Paper
                    className={fixedHeightPaper}
                    elevation={3}
                    style={{
                      width: 'auto',
                      height: 'auto',
                    }}
                  >
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Stage Name</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Amount due</TableCell>
                          <TableCell>Due Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.stages.map((item: Stages) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>
                              {item.amountDue ? `£${item.amountDue}` : ''}
                            </TableCell>
                            <TableCell>
                              {item.dueDate === null
                                ? ''
                                : format(
                                    Date.parse(item.dueDate),
                                    'dd/MM/yyyy',
                                  )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {/* Fix margin */}
                    <div style={{ padding: '1px' }} />
                  </Paper>
                </div>
                <Divider />
                <Grid container style={{ width: '99%', padding: '10px' }}>
                  <Grid item xs={6}>
                    <Title>Refunding of overpayments</Title>

                    <p style={{ fontSize: '15px' }}>
                      Tuition fees can sometimes be slightly overpaid as a
                      result of currency exchange rate fluctuations. Please
                      indicate here whether or not you would be prepared to
                      donate any final overpayment of less than £20 to the
                      <a
                        href="https://www.jbs.cam.ac.uk/aboutus/giving/cambridge-judge-student-hardship-fund/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        &nbsp;CJBS Hardship Fund{' '}
                      </a>{' '}
                      rather than receive a refund. Any overpayments in excess
                      of £20 will always be refunded.
                    </p>
                  </Grid>
                  {state !== '' ? (
                    <Grid item xs={6}>
                      <div style={{ padding: '20px', marginTop: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={state}
                            onChange={handleChangeRadio}
                          >
                            <FormControlLabel
                              value="862090001"
                              control={<Radio color="primary" />}
                              label="Please donate overpayment less than £20 to hardship fund."
                              disabled={newTitle === 0}
                              style={{ marginBottom: '10px' }}
                            />
                            <Divider />
                            <FormControlLabel
                              value="862090000"
                              control={<Radio color="primary" />}
                              label="Please refund any overpayment, regardless of value."
                              disabled={newTitle === 0}
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
                {newTitle === 1 ? (
                  <div
                    style={{
                      padding: '15px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div>
                      <Button
                        variant="outlined"
                        style={{ marginRight: '10px', color: '#00b33c' }}
                        onClick={() => {
                          handleClickOpen();
                          setFormID(row.id);
                          setFormTitle(row.title);
                        }}
                      >
                        Select Payment Plan
                      </Button>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to select this payment plan?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          {!loading ? (
                            <>
                              <Button
                                onClick={() =>
                                  handlePlan(appID, formID, formTitle)
                                }
                                color="primary"
                              >
                                Yes
                              </Button>
                              <Button
                                onClick={handleClose}
                                color="default"
                                autoFocus
                              >
                                Cancel
                              </Button>
                            </>
                          ) : (
                            <ReactLoading type="cylon" color="#ffb81c" />
                          )}
                        </DialogActions>
                      </Dialog>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Paper>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
