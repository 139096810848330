import { Chip, emphasize, styled } from '@material-ui/core';

export const StyledBreadcrumb = styled(Chip)(({ theme }: any) => {
  const backgroundColor = theme.palette.grey[100];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});
