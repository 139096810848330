/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

export default function ButtonStyles(props: any): any {
  const { text, variant, size, onClick, hidden, ...other } = props;

  const useStyles = makeStyles(() => ({
    root: {
      // color: '#00b33c',
    },
    label: { textTransform: 'none' },
  }));

  const classes = useStyles();

  return (
    <Button
      className={classes.root}
      variant={variant || 'contained'}
      size={size || 'large'}
      onClick={onClick}
      hidden={hidden}
      classes={{ root: classes.root, label: classes.label }}
      {...other}
    >
      {text}
    </Button>
  );
}
