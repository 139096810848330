import React from 'react';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InteractionType, IPublicClientApplication } from '@azure/msal-browser';
// BrowserRouter is the router implementation for HTML5 browsers (vs Native).
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import GlobalStyle from './styles/global';
import Routes from './routes';

import theme from './components/layout/theme';
import { loginRequest } from './services/Auth/authProvider';
import { GlobalStateProvider } from './services/context/GlobalStateProvider';

type AppProps = {
  pca: IPublicClientApplication;
};

export function App({ pca }: AppProps): JSX.Element {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
        >
          <ThemeProvider theme={theme}>
            <GlobalStateProvider>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </GlobalStateProvider>
            <GlobalStyle />
          </ThemeProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </>
  );
}
