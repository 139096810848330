/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Container, Item } from './styles';
import logo from '../../assets/logo.png';

function Maintenance() {
  return (
    <Container>
      <Item>
        <img src={logo} alt="" />
        <Paper style={{ padding: '20px' }}>
          <h2 style={{ padding: '1rem' }}>This site is down for maintenance</h2>
          {/* <Alert severity="warning"></Alert> */}

          <p style={{ margin: '1rem' }}>Please check back shortly.</p>
        </Paper>
      </Item>
    </Container>
  );
}

export default Maintenance;
