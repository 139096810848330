/* eslint-disable react/require-default-props */
import React, { createContext, useContext, useReducer } from 'react';
import { reducer, initialState } from './reducer';

const StateContext = createContext({});

interface props {
  children?: any;
}

export const StateProvider = ({ children }: props): any => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = (): any => useContext(StateContext);
