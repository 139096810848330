/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { ToastContainer } from 'react-toastify';

import { Paper, Grid, Breadcrumbs, Card } from '@material-ui/core';
import { Link } from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';

import { Alert } from '@material-ui/lab';
import GridPayment from '../GridPayment';
import Main from './Stepper/Main';
import { StyledBreadcrumb } from '../../../../components/dev2Controls/StyledBreadCrumb';

interface Props {
  location: any;
}

export default function CardDetail({ location }: Props): any {
  const { state } = location;

  return (
    <Paper style={{ padding: '10px' }}>
      <ToastContainer />
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to="/"
          style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
        >
          <StyledBreadcrumb label="Home" icon={<HomeIcon fontSize="small" />} />
        </Link>
        <Link
          to="/makepayments"
          style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
        >
          <StyledBreadcrumb label="Make Payments" />
        </Link>

        <StyledBreadcrumb label="Details" />
      </Breadcrumbs>

      <br />

      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <GridPayment makePayment={state.makePayment} />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Card variant="outlined" style={{ padding: '10px' }}>
            <Alert severity="warning">
              <b>PLEASE NOTE:</b> As part of their fraud prevention measures,
              card issuers may decline card payments that are outside of your
              usual spending patterns. In the event that your payment is
              declined, please contact the card issuer.
            </Alert>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Main makePayment={state.makePayment} />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{
            padding: '15px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        />
      </Grid>
    </Paper>
  );
}
