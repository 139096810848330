import React from 'react';
import { Card } from '@material-ui/core';

import Stepper from './Stepper';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Main({ makePayment }: any): any {
  return (
    <Card variant="outlined" style={{ padding: '10px' }}>
      <Stepper makePayment={makePayment} />
    </Card>
  );
}
