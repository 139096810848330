import React, { useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import { Grid, Card, Button, Breadcrumbs } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';

import { format } from 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import HomeIcon from '@material-ui/icons/Home';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import fetchInvoicesData from '../hooks/fetchInvoicesData';
import fetchGeneratedInvoicesData from '../hooks/fetchGeneratedInvoicesData';
import { IInvoiceForView } from './interfaces';

import { Controls } from '../../../components/dev2Controls/Controls';
import Title from './Title';

import IndividualPaymentViewInvoice from './IndividualPaymentViewInvoice';
import CompanyPaymentViewInvoice from './CompanyPaymentViewInvoice';
import { StyledBreadcrumb } from '../../../components/dev2Controls/StyledBreadCrumb';

interface Props {
  // appId: any;
  isCompactView: any;
}

const useStyles = makeStyles(theme => ({
  root: {
    // margin: 0,
    // padding: theme.spacing(8),
  },
  seeMore: {
    marginTop: theme.spacing(2),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));
/*
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
}); */

const initialValues = {
  isCompactView: false,
  selectedInvoiceRowId: -1,
  myinvoices: null,
};

export default function MyInvoices({ isCompactView }: Props): any {
  const [myInvoicesState, setMyInvoicesState] = useState<any>(initialValues);
  const [generatedInvoicesState, setGeneratedInvoicesState] = useState<any>();
  const [open, setOpen] = React.useState(false);

  // const handleClickOpen = (): any => {
  //   setOpen(true);
  // };

  const handleClose = (): any => {
    setOpen(false);
  };

  // console.log(myInvoicesState);
  const classes = useStyles();

  fetchInvoicesData(setMyInvoicesState, myInvoicesState);
  fetchGeneratedInvoicesData(setGeneratedInvoicesState, generatedInvoicesState);
  // console.log(isCompactView);

  const varIvoicesData = myInvoicesState.myinvoices;

  // const invoiceStatus =
  //   myInvoicesState.myinvoices != null &&
  //   myInvoicesState.myinvoices[myInvoicesState.selectedInvoiceRowId - 1] !=
  //     null &&
  //   myInvoicesState.myinvoices[myInvoicesState.selectedInvoiceRowId - 1] !==
  //     undefined
  //     ? myInvoicesState.myinvoices[myInvoicesState.selectedInvoiceRowId - 1]
  //         .invoiceStatus
  //     : '';

  if (!myInvoicesState.myinvoices) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }
  /*  if (!generatedInvoicesState) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  } */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  // const preventDefault = (event: any) => event.preventDefault();

  // console.log(generatedInvoicesState);

  return (
    <>
      <Paper style={{ padding: '20px' }}>
        <ToastContainer />
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            to="/"
            style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
          >
            <StyledBreadcrumb
              label="Home"
              icon={<HomeIcon fontSize="small" />}
            />
          </Link>

          <StyledBreadcrumb label="My Invoices" />
        </Breadcrumbs>
        <br />

        {!generatedInvoicesState ? (
          ''
        ) : (
          <Grid container style={{ marginBottom: '10px' }}>
            <Title>Generated invoices</Title>
            <Card style={{ padding: '10px', width: '100%' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>

                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {generatedInvoicesState.map((row: any) => {
                    return (
                      <TableRow key={row.rowId}>
                        <TableCell>
                          {' '}
                          {row.name.substring(0, row.name.indexOf('.'))}{' '}
                        </TableCell>
                        <TableCell>
                          {/* <a href={row.url}>Download</a> */}
                          <a
                            href={row.url}
                            style={{ fontSize: '15px', textDecoration: 'none' }}
                          >
                            <IconButton
                              color="primary"
                              // onClick={() => {
                              //   const { url } = row;
                              //   window.open(url);
                              // }}
                            >
                              <ArrowDownwardIcon
                                style={{ fontSize: '15px', color: 'black' }}
                              />{' '}
                              <span style={{ fontSize: '15px' }}>DOWNLOAD</span>
                            </IconButton>
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>
          </Grid>
        )}
        <Title>My Invoices</Title>
        <Grid container spacing={1}>
          {/*  <Grid item lg={12}>
          <Alert severity="info">My invoices</Alert>
        </Grid> */}

          <Grid container style={{ marginBottom: '10px' }}>
            <Card style={{ padding: '10px', width: '100%' }}>
              {/* <Card variant="outlined" style={{ padding: '10px', width: '100%' }}> */}
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Payee</TableCell>
                    <TableCell>Value</TableCell>

                    <TableCell>Date requested</TableCell>
                    <TableCell> Status </TableCell>

                    <TableCell
                      style={{ display: isCompactView ? 'none' : '' }}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {varIvoicesData.map((row: IInvoiceForView) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>{row.jbs_paymentname}</TableCell>
                        <TableCell>
                          £
                          {(
                            Math.round(row.jbs_invoicevalue * 100) / 100
                          ).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {row.createdon === null
                            ? ''
                            : format(Date.parse(row.createdon), 'dd/MM/yyyy')}
                        </TableCell>
                        <TableCell>{row.invoiceStatus}</TableCell>
                        <TableCell
                          style={{ display: isCompactView ? 'none' : '' }}
                        >
                          <Controls.Button
                            text="SHOW DETAILS"
                            variant="contained"
                            style={{
                              color: 'black',
                              backgroundColor: '#ffb81c',
                            }}
                            onClick={() => {
                              setMyInvoicesState({
                                ...myInvoicesState,
                                selectedInvoiceRowId: row.id,
                              });

                              setOpen(true);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        // fullScreen
        open={open}
        onClose={handleClose}
        //  TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            {myInvoicesState.selectedInvoiceRowId > 0
              ? myInvoicesState.myinvoices[
                  myInvoicesState.selectedInvoiceRowId - 1
                ].jbs_name
              : ''}

            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="CLOSE"
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
            {/*  <Typography variant="h6" className={classes.title}>
              Sound
            </Typography> */}
          </Toolbar>
        </AppBar>
        {myInvoicesState.selectedInvoiceRowId > 0 &&
        myInvoicesState.myinvoices[myInvoicesState.selectedInvoiceRowId - 1]
          .individualRequest === true ? (
          <IndividualPaymentViewInvoice myInvoicesState={myInvoicesState} />
        ) : (
          ''
        )}

        {myInvoicesState.selectedInvoiceRowId > 0 &&
        myInvoicesState.myinvoices[myInvoicesState.selectedInvoiceRowId - 1]
          .companyRequest === true ? (
          <CompanyPaymentViewInvoice myInvoicesState={myInvoicesState} />
        ) : (
          ''
        )}

        {/*   <CompanyPaymentViewInvoice myInvoicesState={myInvoicesState} />

        <IndividualPaymentViewInvoice myInvoicesState={myInvoicesState} /> */}

        <br />

        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
          alignContent="flex-end"
          alignItems="flex-end"
          style={{ padding: '20px' }}
        >
          <div
            style={{
              display: 'block',
              width: '100%',

              textAlign: 'right',
              color: 'red',
            }}
          >
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                style={{
                  color: 'black',
                  backgroundColor: '#ffb81c',
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                GO BACK
              </Button>
            </Grid>
          </div>
        </Grid>
      </Dialog>
    </>
  );
}
