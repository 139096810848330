/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import MaterialTable, { MTableToolbar } from 'material-table';
import HomeIcon from '@material-ui/icons/Home';
import ReactLoading from 'react-loading';
import { Breadcrumbs, Grid, Paper } from '@material-ui/core';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import TableIcons from './TableIcons';
import loadtransactions from '../hooks/loadTransactions';

import Title from './Title';
import { currencyFormatter } from '../../../components/Utils/currencyFormatter';
import { StyledBreadcrumb } from '../../../components/dev2Controls/StyledBreadCrumb';

export default function PaymentHistory(): any {
  const [transactions, setTransactions] = useState<any>();

  loadtransactions(setTransactions);

  if (!transactions) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <ReactLoading type="bubbles" color="#ffb81c" height="5%" width="5%" />
      </Grid>
    );
  }
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to="/"
          style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
        >
          <StyledBreadcrumb label="Home" icon={<HomeIcon fontSize="small" />} />
        </Link>
        <StyledBreadcrumb label="Payment History" />
      </Breadcrumbs>
      <br />

      <MaterialTable
        title={<Title>Payment History</Title>}
        columns={[
          { title: 'Transaction type', field: 'transactionTypeName' },
          { title: 'Stages', field: 'stages', hidden: true, export: false },
          {
            title: 'Date',
            field: 'createdon',
            render: rowData =>
              rowData.createdon
                ? `${format(new Date(rowData.createdon), 'dd/MM/yyyy')}`
                : '',
          },
          {
            title: 'Amount',
            field: 'transactionAmount',
            render: rowData =>
              rowData.transactionAmount
                ? currencyFormatter(rowData.transactionAmount)
                : '',
          },

          { title: 'Payment method', field: 'paymentMethodName' },

          {
            title: 'Status',
            field: 'paymentReceived',
            render: rowData =>
              rowData.paymentReceived === 862090000
                ? 'Confirmed'
                : rowData.paymentReceived === 862090001
                ? 'Declined'
                : 'Processing',
          },
          { title: 'Payment Reference', field: 'paymentReference' },
        ]}
        icons={TableIcons}
        data={transactions}
        options={{
          // filtering: true,
          exportFileName: 'Payments',
          search: true,
          pageSize: 10,
          pageSizeOptions: [10, 30, 60],
          exportButton: true,
          padding: 'dense',
          rowStyle: {
            fontSize: 14,
          },
        }}
        detailPanel={rowData => {
          return (
            <div style={{ marginLeft: '45px', marginBottom: '30px' }}>
              <MaterialTable
                columns={[
                  { title: 'Stage Name', field: 'planStageName' },
                  {
                    title: 'Amount',
                    field: 'billAmount',
                    render: data =>
                      data.billAmount ? currencyFormatter(data.billAmount) : '',
                  },
                ]}
                icons={TableIcons}
                data={rowData.stages}
                options={{
                  // filtering: true,
                  search: false,
                  paging: false,
                  exportButton: false,
                  padding: 'dense',
                  showTitle: false,
                  rowStyle: {
                    fontSize: 14,
                    backgroundColor: '#EEE',
                  },
                }}
                components={{
                  Toolbar: props => (
                    <div
                      style={{
                        height: '0px',
                      }}
                    >
                      <MTableToolbar {...props} />
                    </div>
                  ),
                  Container: props => <Paper {...props} elevation={0} />,
                }}
              />
            </div>
          );
        }}
        components={{
          Container: props => <Paper {...props} elevation={0} />,
        }}
      />

      <br />
    </>
  );
}
