/* eslint-disable react/button-has-type */
import React from 'react';
import ImageUploading from 'react-images-uploading';
import { PhotoCamera } from '@material-ui/icons';

import { Grid, Button } from '@material-ui/core';

export default function App(): any {
  // console.log(state);
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChange = (imageList: any, addUpdateIndex: any): any => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  // const handleSubmit = (): any => {
  //   // data for submit
  //   console.log(imageList, addUpdateIndex);
  //   setImages(imageList);
  // };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div
                className="upload__image-wrapper"
                style={{ padding: '15px', textAlign: 'center' }}
              >
                {imageList.length === 0 && (
                  <Button
                    variant="outlined"
                    style={
                      isDragging
                        ? { color: 'red' }
                        : {
                            marginRight: '10px',

                            width: '100%',
                            height: '13vh',
                          }
                    }
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <PhotoCamera fontSize="large" />
                  </Button>
                )}
                &nbsp;
                {imageList.length < 0 && (
                  <button onClick={onImageRemoveAll}>Remove all images</button>
                )}
                {imageList.map((image: any, index: any): any => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="image-item">
                    <img src={image.data_url} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <Button
                        onClick={() => onImageUpdate(index)}
                        variant="outlined"
                        style={{ marginRight: '10px' }}
                      >
                        Update
                      </Button>
                      <Button
                        onClick={() => onImageRemove(index)}
                        variant="outlined"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </Grid>
      </Grid>
    </>
  );
}
