/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Container, Item } from './styles';
import logo from '../../assets/logo.png';

interface Props {
  valueError: string;
}

function Error({ valueError }: Props) {
  return (
    <Container>
      <Item>
        <img src={logo} alt="" />
        <Paper style={{ padding: '20px' }}>
          <h2 style={{ padding: '1rem' }}>Access Denied</h2>
          <Alert severity="warning">{valueError}</Alert>

          <p style={{ margin: '1rem' }}>
            You do not currently have permission to access this portal.
          </p>
          <p style={{ margin: '1rem' }}>
            <strong>
              Please contact your Programme Administrator if you feel this is
              incorrect.{' '}
            </strong>
          </p>
        </Paper>
      </Item>
    </Container>
  );
}

export default Error;
