/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { isAfter } from 'date-fns/esm';
import api from '../../../services/api';

import { useGlobalState } from '../../../services/context/GlobalStateProvider';

const LoadPaymentPlans: any = (setPaymentPlan: any) => {
  const { userState } = useGlobalState();

  useEffect(() => {
    async function loadPaymentPlan(): Promise<void> {
      await api
        .get(
          // `/Payment/GetApplicationAcceptedPlan?applicationId=678a8334-f0f9-eb11-94ef-0022489cc1e4`,
          `/Payment/GetApplicationAcceptedPlan?applicationId=${userState.appID}`,
          // `/Payment/GetApplicationAcceptedPlan?applicationId=73797931-629c-eb11-b1ac-000d3adcc6a1`,
          {
            headers: {
              cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
              Authorization: `Bearer ${userState.idToken}`,
            },
          },
        )
        .then(async (resp: { data: { acceptedPlan: null; stages: any[] } }) => {
          if (resp.data.acceptedPlan !== null) {
            const StagesId = resp.data.stages.map((item: any): any => {
              return item.id;
            });
            const FinalList: any[] = [];
            await api
              .get(
                // `/Payment/GetTransactionByStages?applicationId=678a8334-f0f9-eb11-94ef-0022489cc1e4`,
                `/Payment/GetTransactionByStages?applicationId=${userState.appID}`,

                // `/Payment/GetApplicationAcceptedPlan?applicationId=73797931-629c-eb11-b1ac-000d3adcc6a1`,
                {
                  headers: {
                    cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
                    Authorization: `Bearer ${userState.idToken}`,
                  },
                },
              )
              .then(
                async (stageResp: {
                  data: { payments: any; refunds: any; pending: any };
                }) => {
                  StagesId.map(async (item: any): Promise<any> => {
                    async function returnStage(
                      stageList: any,
                      pendingList: any,
                    ): Promise<any> {
                      const stages = [...resp.data.stages];
                      // Find Index stage - I will change.
                      const index = stages.findIndex(
                        value => value.id === item,
                      );
                      // find transaction by id
                      const findTransaction = stageList.findIndex(
                        (elem: any) => elem.stageID === item,
                      );

                      const pending = pendingList.find(
                        (e: any) => e.stageID === item,
                      );

                      // set this stage in a variable.
                      const stage = { ...stages[index] };

                      if (findTransaction !== -1) {
                        // setting a new item in the array - Outstading Balance.
                        stage.outstandingBalance =
                          stage.amountDue -
                          stageList[findTransaction].stageAmount;

                        // Amount paid
                        stage.amountPaid =
                          stageList[findTransaction].stageAmount || 0;
                        stage.index = index;

                        // Amount Pending;
                        stage.pending = pending ? pending.stageAmount : 0;

                        // status

                        stage.status =
                          // eslint-disable-next-line no-nested-ternary
                          stage.outstandingBalance === 0 ||
                          stage.outstandingBalance === '0.00'
                            ? 'Paid'
                            : isAfter(new Date(stage.dueDate), new Date())
                            ? 'Due'
                            : stage.dueDate
                            ? 'Overdue'
                            : 'Due';

                        await FinalList.push(stage);
                      }
                      if (findTransaction === -1) {
                        // setting a new item in the array - Outstading Balance.
                        stage.outstandingBalance = stage.amountDue;
                        // Amount paid
                        stage.amountPaid = 0;
                        stage.index = index;

                        // Pending
                        stage.pending = pending ? pending.stageAmount : 0;

                        // status
                        stage.status =
                          // eslint-disable-next-line no-nested-ternary
                          stage.outstandingBalance === 0
                            ? 'Paid'
                            : isAfter(new Date(stage.dueDate), new Date())
                            ? 'Due'
                            : stage.dueDate
                            ? 'Overdue'
                            : 'Due';

                        await FinalList.push(stage);
                      }
                    }
                    const { payments, refunds, pending } = stageResp.data;

                    // filter all transactions with paymentReceived

                    if (!payments && !refunds) {
                      const fakeData: any[] = [
                        {
                          pending: 0,
                          stageAmount: 0,
                          stageID: -100,
                        },
                      ];

                      const fakePending: any[] = [
                        {
                          pending: 0,
                          stageAmount: 0,
                          stageID: -100,
                        },
                      ];

                      await returnStage(fakeData, fakePending);

                      // Ordering by Index.
                      const orderList = FinalList.sort(
                        (a, b) => a.index - b.index,
                      );
                      return setPaymentPlan({
                        ...resp.data,
                        stages: orderList,
                        appId: userState.appID,
                        appNumber: userState.appNumber,
                      });
                    }

                    // first, convert data into a Map with reduce
                    const sumPayment = payments
                      .filter((e: any) => e.paymentReceived !== null)
                      .reduce((prev: any, curr: any) => {
                        const count = prev.get(curr.stageID) || 0;
                        prev.set(curr.stageID, curr.stageAmount + count);
                        return prev;
                      }, new Map());

                    // Add refund amount to stage amount if refund value is 862090000
                    refunds.forEach((refund: any) => {
                      if (refund.paymentReceived === 862090000) {
                        const stage = sumPayment.get(refund.stageID);
                        if (stage) {
                          sumPayment.set(
                            refund.stageID,
                            stage + refund.stageAmount,
                          );
                        } else {
                          sumPayment.set(refund.stageID, refund.stageAmount);
                        }
                      }
                    });

                    // first, convert data into a Map with reduce
                    const deductPaymentPending = refunds
                      .filter((e: any) => e.paymentReceived !== 862090000)
                      .reduce((prev: any, curr: any) => {
                        const count = prev.get(curr.stageID) || 0;
                        prev.set(curr.stageID, curr.stageAmount + count);
                        return prev;
                      }, new Map());

                    const stageList: any[] = [];
                    // then, map your counts object back to an array without duplicates
                    [...sumPayment].map(([stageID, stageAmount]) => {
                      return stageList.push({
                        stageID,
                        stageAmount,
                      });
                    });

                    // first, convert data into a Map with reduce
                    const refundAndPayment = stageList.reduce(
                      (prev: any, curr: any) => {
                        const count = prev.get(curr.stageID) || 0;
                        prev.set(curr.stageID, curr.stageAmount + count);
                        return prev;
                      },
                      new Map(),
                    );

                    const sumPending = pending.reduce(
                      (prev: any, curr: any) => {
                        const count = prev.get(curr.stageID) || 0;
                        prev.set(curr.stageID, curr.stageAmount + count);
                        return prev;
                      },
                      new Map(),
                    );

                    const pendingList: any[] = [];
                    [...sumPending].map(([stageID, stageAmount]) => {
                      return pendingList.push({
                        stageID,
                        stageAmount,
                      });
                    });

                    [...deductPaymentPending].map(([stageID, stageAmount]) => {
                      return pendingList.push({
                        stageID,
                        stageAmount,
                      });
                    });

                    const fixPendingList = pendingList.reduce(
                      (prev: any, curr: any) => {
                        const count = prev.get(curr.stageID) || 0;
                        prev.set(curr.stageID, curr.stageAmount + count);
                        return prev;
                      },
                      new Map(),
                    );

                    const pendingFixList = [...fixPendingList].map(
                      ([stageID, stageAmount]) => {
                        return {
                          stageID,
                          stageAmount,
                        };
                      },
                    );

                    const fixList = [...refundAndPayment].map(
                      ([stageID, stageAmount]) => {
                        return {
                          stageID,
                          stageAmount,
                        };
                      },
                    );

                    await returnStage(fixList, pendingFixList);

                    // Ordering by Index.
                    const orderList = FinalList.sort(
                      (a, b) => a.index - b.index,
                    );

                    setPaymentPlan({
                      ...resp.data,
                      stages: orderList,
                      appId: userState.appID,
                      appNumber: userState.appNumber,
                    });
                  });
                },
              );
          } else {
            setPaymentPlan(resp.data);
          }
        });
    }
    loadPaymentPlan();
  }, [
    setPaymentPlan,
    userState.appID,
    userState.appNumber,
    userState.email,
    userState.idToken,
  ]);
};

export default LoadPaymentPlans;
