/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
import React from 'react';

import { Card, Grid, Typography } from '@material-ui/core';

import { Controls } from '../../../components/dev2Controls/Controls';
import { IglobalState } from './interfaces';

interface Props {
  globalState: IglobalState;
  setGlobalState: any;
}

export default function IndividualPayment({
  globalState,
  setGlobalState,
}: Props): any {
  const arrayIndex = globalState.activeTabIndex;

  const stateValuePointer =
    globalState.invoicesData[arrayIndex].invoiceDataIfIndividual[0];
  const stateErrorPointer =
    globalState.invoicesData[arrayIndex].invoiceErrorsIfIndividual[0];

  return (
    <>
      <Card variant="outlined" style={{ padding: '10px', width: '100%' }}>
        <div style={{ flexGrow: 1 }}>
          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography component="legend">
                Amount due : £
                {globalState.oneInoiceForAllStages
                  ? globalState.totalStagesAmountIfOneInvoiceForAllStages
                  : globalState.selectedData[arrayIndex].outstandingBalance}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography component="legend">
                Invoice - Amount: £
                {globalState.oneInoiceForAllStages
                  ? globalState.totalInvoiceAmountIfOneInvoiceForAllStages
                  : globalState.selectedData[arrayIndex]
                    .invoiceAmountIfNotFullamount}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12} lg={5}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="InvoiceNameOfThePayee"
                label="Name of payee"
                value={stateValuePointer.InvoiceNameOfThePayee}
                onChange={(event: any) => {
                  const { value } = event.target;

                  // All Stagesc
                  const varInvoicesData = { ...globalState.invoicesData };
                  varInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfIndividual[0].InvoiceNameOfThePayee = !value
                      ? 'Name required'
                      : '';
                  varInvoicesData[arrayIndex].newValuesEntered = true;

                  varInvoicesData[
                    arrayIndex
                  ].invoiceDataIfIndividual[0].InvoiceNameOfThePayee = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varInvoicesData,
                  });
                }}
                error={stateErrorPointer.InvoiceNameOfThePayee}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="InvoiceEmailAddress"
                label="Email address"
                value={stateValuePointer.InvoiceEmailAddress}
                type="email"
                onChange={(event: any) => {
                  const { value } = event.target;

                  // All Stagesc
                  const varInvoicesData = { ...globalState.invoicesData };

                  const re =
                    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                  // const re =
                  // '^(([^<>()[].,;:s@"]+(.[^<>()[].,;:s@"]+)*)|(".+"))@(([^<>()[].,;:s@"]+.)+[^<>()[].,;:s@"]{2,})$/i';

                  const errror = re.test(value) ? '' : 'Email not valid';

                  varInvoicesData[
                    arrayIndex
                  ].invoiceDataIfIndividual[0].InvoiceEmailAddress = value;
                  varInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfIndividual[0].InvoiceEmailAddress = errror;
                  varInvoicesData[arrayIndex].newValuesEntered = true;
                  setGlobalState({
                    ...globalState,
                    invoicesData: varInvoicesData,
                  });
                }}
                error={stateErrorPointer.InvoiceEmailAddress}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="InvoicePhoneNumber"
                label="Phone"
                type="number"
                value={stateValuePointer.InvoicePhoneNumber}
                onInput={(e: any) => {
                  e.target.value =
                  e.target.value.toString()
                    .slice(0, 14);
                }}

              /*   onInput={(e: any) => {
                  e.target.value = Math.max(
                    0,
                    // eslint-disable-next-line radix
                    parseInt(e.target.value),
                  )
                    .toString()
                    .slice(0, 14);
                }}  */
                onChange={(event: any) => {
                  const { value } = event.target;

                  // All Stagesc
                  const varInvoicesData = { ...globalState.invoicesData };
                  varInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfIndividual[0].InvoicePhoneNumber = !value
                      ? '*'
                      : '';

                  varInvoicesData[arrayIndex].newValuesEntered = true;

                  varInvoicesData[
                    arrayIndex
                  ].invoiceDataIfIndividual[0].InvoicePhoneNumber = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varInvoicesData,
                  });
                }}
                error={stateErrorPointer.InvoicePhoneNumber}
                size="small"
              />
            </Grid>

            <Grid item xs={12} lg={5}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="InvoiceAddressLine1"
                label="Invoice address line 1"
                fullWidth
                value={stateValuePointer.InvoiceAddressLine1}
                onChange={(event: any) => {
                  const { value } = event.target;

                  // All Stagesc
                  const varInvoicesData = { ...globalState.invoicesData };

                  varInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfIndividual[0].InvoiceAddressLine1 = !value
                      ? 'Address line1 required'
                      : '';
                  varInvoicesData[arrayIndex].newValuesEntered = true;

                  varInvoicesData[
                    arrayIndex
                  ].invoiceDataIfIndividual[0].InvoiceAddressLine1 = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varInvoicesData,
                  });
                }}
                error={stateErrorPointer.InvoiceAddressLine1}
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="InvoiceAddressLine2"
                label="Invoice address line 2"
                fullWidth
                value={stateValuePointer.InvoiceAddressLine2}
                onChange={(event: any) => {
                  const { value } = event.target;

                  // All Stagesc
                  const varInvoicesData = { ...globalState.invoicesData };

                  varInvoicesData[
                    arrayIndex
                  ].invoiceDataIfIndividual[0].InvoiceAddressLine2 = value;
                  varInvoicesData[arrayIndex].newValuesEntered = true;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varInvoicesData,
                  });
                }}
                error={stateErrorPointer.InvoiceAddressLine2}
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="InvoiceCity"
                label="City"
                fullWidth
                value={stateValuePointer.InvoiceCity}
                onChange={(event: any) => {
                  const { value } = event.target;

                  // All Stagesc
                  const varInvoicesData = { ...globalState.invoicesData };

                  varInvoicesData[
                    arrayIndex
                  ].invoiceDataIfIndividual[0].InvoiceCity = value;
                  varInvoicesData[arrayIndex].newValuesEntered = true;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varInvoicesData,
                  });
                }}
                error={stateErrorPointer.InvoiceCity}
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="InvoiceCountryState"
                label="County state"
                fullWidth
                value={stateValuePointer.InvoiceCountryState}
                onChange={(event: any) => {
                  const { value } = event.target;

                  // All Stagesc
                  const varInvoicesData = { ...globalState.invoicesData };

                  varInvoicesData[
                    arrayIndex
                  ].invoiceDataIfIndividual[0].InvoiceCountryState = value;
                  varInvoicesData[arrayIndex].newValuesEntered = true;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varInvoicesData,
                  });
                }}
                error={stateErrorPointer.InvoiceCountryState}
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.SelectCountries
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="InvoiceCountry"
                label="Country"
                value={stateValuePointer.InvoiceCountry}
                onChange={(event: any) => {
                  const { value } = event.target;

                  // All Stagesc
                  const varInvoicesData = { ...globalState.invoicesData };

                  varInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfIndividual[0].InvoiceCountry = !value
                      ? 'Country name required'
                      : '';
                  varInvoicesData[arrayIndex].newValuesEntered = true;

                  varInvoicesData[
                    arrayIndex
                  ].invoiceDataIfIndividual[0].InvoiceCountry = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varInvoicesData,
                  });
                }}
                error={stateErrorPointer.InvoiceCountry}
                size="small"
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="InvoicePostCode"
                label="Postcode"
                value={stateValuePointer.InvoicePostCode}
                onChange={(event: any) => {
                  const { value } = event.target;

                  // All Stagesc
                  const varInvoicesData = { ...globalState.invoicesData };
                  varInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfIndividual[0].InvoicePostCode = !value
                      ? '*'
                      : '';
                  varInvoicesData[arrayIndex].newValuesEntered = true;
                  varInvoicesData[
                    arrayIndex
                  ].invoiceDataIfIndividual[0].InvoicePostCode = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varInvoicesData,
                  });
                }}
                error={stateErrorPointer.InvoicePostCode}
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <Controls.Input
                disabled={globalState.invoicesData[arrayIndex].requestSubmitted}
                name="InvoiceReference"
                label="Reference"
                value={stateValuePointer.InvoiceReference}
                onChange={(event: any) => {
                  const { value } = event.target;

                  // All Stagesc
                  const varInvoicesData = { ...globalState.invoicesData };
                  varInvoicesData[
                    arrayIndex
                  ].invoiceErrorsIfIndividual[0].InvoiceReference = !value
                      ? '*'
                      : '';
                  varInvoicesData[arrayIndex].newValuesEntered = true;
                  varInvoicesData[
                    arrayIndex
                  ].invoiceDataIfIndividual[0].InvoiceReference = value;

                  setGlobalState({
                    ...globalState,
                    invoicesData: varInvoicesData,
                  });
                }}
                error={stateErrorPointer.InvoiceReference}
                size="small"
              />
            </Grid>


          </Grid>
        </div>
      </Card>
    </>
  );
}
