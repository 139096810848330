import axios from 'axios';

const production = 'pay.jbs.cam.ac.uk';
const staging = 'cjbs-webapp-payment-portal-staging.azurewebsites.net';
const dev = `cjbs-webapp-payment-portal-development.azurewebsites.net`;
const liveStaging = 'cjbs-webapp-payment-portal-live-stage.azurewebsites.net';
const localhost = 'localhost';

function findPoint(url: any): any {
  if (url === dev || url === localhost) {
    return 'https://cjbs-webapp-enrolment-api-development.azurewebsites.net';
  }
  if (url === staging) {
    return 'https://cjbs-webapp-enrolment-api-staging.azurewebsites.net';
  }
  if (url === liveStaging) {
    return 'https://cjbs-webapp-enrolment-api-live-stage.azurewebsites.net';
  }
  if (url === production) {
    return 'https://cjbs-webapp-enrolment-api.azurewebsites.net';
  }
  return 'none';
}

const api = axios.create({
  baseURL: findPoint(window.location.hostname),
});

// https://cjbs-webapp-enrolment-api-development.azurewebsites.net
export default api;
