import { useEffect } from 'react';
import api from '../../../services/api';
import { useGlobalState } from '../../../services/context/GlobalStateProvider';

const LoadTransactions: any = (setTransaction: any) => {
  const { userState } = useGlobalState();

  useEffect(() => {
    async function loadTransaction(): Promise<void> {
      await api
        .get(`/Payment/GetTransactions?applicationId=${userState.appID}`, {
          headers: {
            cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
            Authorization: `Bearer ${userState.idToken}`,
          },
        })
        .then(resp => {
          const { data } = resp;
          const orderList = data.sort((a: any, b: any) => {
            const fixA = a.createdon.split('T');
            const fixB = b.createdon.split('T');
            return Date.parse(fixA[0]) - Date.parse(fixB[0]);
          });

          setTransaction(orderList.reverse());
        });
    }
    loadTransaction();
  }, [setTransaction, userState.appID, userState.email, userState.idToken]);

  return setTransaction;
};

export default LoadTransactions;
