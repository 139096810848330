/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import {
  Card,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { currencyFormatter } from '../../../../components/Utils/currencyFormatter';

interface Stages {
  id: number;
  title: string;
  description: string;
  amountDue: string;
  dueDate: string;
  bacsPayments: string;
  cardPayments: string;
  status: string;
  newAmount: string;
  outstandingBalance: string;
  toPay: string;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    fontSize: 14,
  },
});

export default function GridPayment({ makePayment }: any): any {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      variant="outlined"
      style={{ padding: '10px' }}
    >
      {makePayment ? (
        <>
          <h3 style={{ color: '#ffb81c', padding: '6px' }}>Make payments</h3>
          <Paper elevation={3}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Stage Name</TableCell>

                  <TableCell>Outstanding balance</TableCell>

                  <TableCell>This transaction</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {makePayment.map((item: Stages) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.title}</TableCell>

                    <TableCell>
                      {' '}
                      {item.outstandingBalance
                        ? currencyFormatter(Number(item.outstandingBalance))
                        : currencyFormatter(Number(item.amountDue))}
                    </TableCell>
                    <TableCell>
                      {item.toPay ? currencyFormatter(Number(item.toPay)) : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </>
      ) : (
        ''
      )}
    </Card>
  );
}
