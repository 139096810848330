/* eslint-disable no-useless-escape */
import React, { useState } from 'react';

import { Grid, Breadcrumbs } from '@material-ui/core';

import { toast, ToastContainer } from 'react-toastify';
import HomeIcon from '@material-ui/icons/Home';
import { Link, useHistory } from 'react-router-dom';
import Part1 from './Part1';

import InvoiceTabs from './InvoiceTabs';
import { initialValues, invoicesDataInitialised } from './stateInitialValues';
import { Controls } from '../../../components/dev2Controls/Controls';
import loadStages from '../hooks/loadStages';
import Title from '../../../components/items/Title';
import api from '../../../services/api';
import { StyledBreadcrumb } from '../../../components/dev2Controls/StyledBreadCrumb';
import { useGlobalState } from '../../../services/context/GlobalStateProvider';

/* const invoicesData = [
  {
    isIndividualPaymentInvoice: false,
    isCompanyPaymentInvoice: false,

    invoiceDataIfIndividual: [
      {
        InvoiceNameOfThePayee: '',
        InvoiceAddressLine1: '',
        InvoiceAddressLine2: '',
        InvoiceCountry: '',
        InvoicePostCode: '',
        InvoiceEmailAddress: '',
        InvoicePhoneNumber: '',
        InvoiceReference: '',
      },
    ],

    invoiceErrorsIfIndividual: [
      {
        InvoiceNameOfThePayee: '',
        InvoiceAddress: '',
        InvoiceCountry: '',
        InvoicePostCode: '',
        InvoiceEmailAddress: '',
        InvoicePhoneNumber: '',
        InvoiceReference: '',
      },
    ],

    invoiceDataIfCompanyPayment: [
      {
        CompanyPayCompanyName: '',
        CompanyPayInvoiceAddressLine1: '',
        CompanyPayInvoiceAddressLine2: '',
        CompanyPayCountry: '',
        CompanyPayPostCode: '',
        CompanyPayRegistrationNumber: '',
        CompanyPayVatNo: '',
        CompanyPayBillingContactName: '',
        CompanyPayBillingContactNumber: '',
        CompanyPayPurchaseOrderNoOrInvoiceRef: '',
        CompanyPayEmailAddressToSendInvoice: '',
        CompanyPayDoesInvoiceNeedsUploadToPortal: '',
        CompanyPayInvoiceUploadPortalUrl: '',
      },
    ],
  },
];
 */

export default function RequestInvoice(): any {
  const { userState } = useGlobalState();
  const [globalState, setGlobalState] = useState<any>(initialValues);
  const history = useHistory();

  // const [appId, setAppId] = useState(null);

  loadStages(setGlobalState, globalState);

  // console.log(globalState.appId);

  // const test1 = invoicesDataInitialised(123);

  // console.log(test1);

  const handleGobackToPreviousInvoice = (): any => {
    const varActiveTabIndex = globalState.activeTabIndex - 1;
    setGlobalState({
      ...globalState,
      activeTabIndex: varActiveTabIndex,
    });
  };

  // used pointers notation just to avoid repeated text of long statements and easy code maintainance

  const validateIndividualPaymentInvoiceData = (): boolean => {
    const individualInvoiceDataPointer =
      globalState.invoicesData[globalState.activeTabIndex]
        .invoiceDataIfIndividual[0];
    let isValid = true;

    const newGlobalState = { ...globalState };

    const newStatePointerForErrors =
      newGlobalState.invoicesData[globalState.activeTabIndex]
        .invoiceErrorsIfIndividual[0];

    newStatePointerForErrors.InvoiceNameOfThePayee =
      individualInvoiceDataPointer.InvoiceNameOfThePayee ? '' : '*';
    newStatePointerForErrors.InvoiceAddressLine1 =
      individualInvoiceDataPointer.InvoiceAddressLine1 ? '' : '***';
    newStatePointerForErrors.InvoiceCountryState =
      individualInvoiceDataPointer.InvoiceCountryState ? '' : '*';
    newStatePointerForErrors.InvoiceCountry =
      individualInvoiceDataPointer.InvoiceCountry ? '' : '*';
    newStatePointerForErrors.InvoicePostCode =
      individualInvoiceDataPointer.InvoicePostCode ? '' : '*';
    newStatePointerForErrors.InvoiceEmailAddress =
      individualInvoiceDataPointer.InvoiceEmailAddress ? '' : '*';

    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    // const re =
    // '^(([^<>()[].,;:s@"]+(.[^<>()[].,;:s@"]+)*)|(".+"))@(([^<>()[].,;:s@"]+.)+[^<>()[].,;:s@"]{2,})$/i';

    newStatePointerForErrors.InvoiceEmailAddress = re.test(
      individualInvoiceDataPointer.InvoiceEmailAddress,
    )
      ? ''
      : 'Email not valid';

    newStatePointerForErrors.InvoicePhoneNumber =
      individualInvoiceDataPointer.InvoicePhoneNumber ? '' : '*';
    newStatePointerForErrors.InvoiceReference =
      individualInvoiceDataPointer.InvoiceReference ? '' : '*';

    isValid = Object.values(newStatePointerForErrors).every(x => x === '');

    return isValid;
  };

  const validateCompanyPaymentInvoceData = (): boolean => {
    const companyPaymentInvoiceDataPointer =
      globalState.invoicesData[globalState.activeTabIndex]
        .invoiceDataIfCompanyPayment[0];
    let isValid = true;

    const newGlobalState = { ...globalState };

    const newStatePointerForErrorsCompanyPmt =
      newGlobalState.invoicesData[globalState.activeTabIndex]
        .invoiceErrorsIfCompanyPayment[0];

    newStatePointerForErrorsCompanyPmt.CompanyPayCompanyName =
      companyPaymentInvoiceDataPointer.CompanyPayCompanyName ? '' : '*';
    newStatePointerForErrorsCompanyPmt.CompanyPayInvoiceAddressLine1 =
      companyPaymentInvoiceDataPointer.CompanyPayInvoiceAddressLine1 ? '' : '*';
    newStatePointerForErrorsCompanyPmt.CompanyPayCountryState =
      companyPaymentInvoiceDataPointer.CompanyPayCountryState ? '' : '*';

    newStatePointerForErrorsCompanyPmt.CompanyPayCountry =
      companyPaymentInvoiceDataPointer.CompanyPayCountry ? '' : '*';
    newStatePointerForErrorsCompanyPmt.CompanyPayPostCode =
      companyPaymentInvoiceDataPointer.CompanyPayPostCode ? '' : '*';
    newStatePointerForErrorsCompanyPmt.CompanyPayRegistrationNumber =
      companyPaymentInvoiceDataPointer.CompanyPayRegistrationNumber ? '' : '*';
    newStatePointerForErrorsCompanyPmt.CompanyPayVatNo =
      companyPaymentInvoiceDataPointer.CompanyPayVatNo ? '' : '*';
    newStatePointerForErrorsCompanyPmt.CompanyPayBillingContactName =
      companyPaymentInvoiceDataPointer.CompanyPayBillingContactName ? '' : '*';

    newStatePointerForErrorsCompanyPmt.CompanyPayBillingContactNumber =
      companyPaymentInvoiceDataPointer.CompanyPayBillingContactNumber
        ? ''
        : '*';
    newStatePointerForErrorsCompanyPmt.CompanyPayPurchaseOrderNoOrInvoiceRef =
      companyPaymentInvoiceDataPointer.CompanyPayPurchaseOrderNoOrInvoiceRef
        ? ''
        : '*';
    newStatePointerForErrorsCompanyPmt.CompanyPayEmailAddressToSendInvoice =
      companyPaymentInvoiceDataPointer.CompanyPayEmailAddressToSendInvoice
        ? ''
        : '*';
    // eslint-disable-next-line no-useless-escape
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    // const re =
    // '^(([^<>()[].,;:s@"]+(.[^<>()[].,;:s@"]+)*)|(".+"))@(([^<>()[].,;:s@"]+.)+[^<>()[].,;:s@"]{2,})$/i';

    newStatePointerForErrorsCompanyPmt.CompanyPayEmailAddressToSendInvoice =
      re.test(
        companyPaymentInvoiceDataPointer.CompanyPayEmailAddressToSendInvoice,
      )
        ? ''
        : 'Email not valid';

    newStatePointerForErrorsCompanyPmt.CompanyPayDoesInvoiceNeedsUploadToPortal =
      companyPaymentInvoiceDataPointer.CompanyPayDoesInvoiceNeedsUploadToPortal
        ? ''
        : '*';
    /*
    newStatePointerForErrorsCompanyPmt.CompanyPayInvoiceUploadPortalUrl =
      companyPaymentInvoiceDataPointer.CompanyPayDoesInvoiceNeedsUploadToPortal ===
        'yes' &&
      !companyPaymentInvoiceDataPointer.CompanyPayInvoiceUploadPortalUrl
        ? '*'
        : ''; */

    /*   newStatePointerForErrorsCompanyPmt.CompanyPayInvoiceUploadPortalUrl =
      companyPaymentInvoiceDataPointer.CompanyPayInvoiceUploadPortalUrl &&
      companyPaymentInvoiceDataPointer.CompanyPayDoesInvoiceNeedsUploadToPortal ===
        'yes'
        ? ''
        : '*';

    newStatePointerForErrorsCompanyPmt.CompanyPayInvoiceUploadPortalUrl =
      companyPaymentInvoiceDataPointer.CompanyPayDoesInvoiceNeedsUploadToPortal ===
      'no'
        ? ''
        : '*'; */

    if (
      companyPaymentInvoiceDataPointer.CompanyPayDoesInvoiceNeedsUploadToPortal ===
      'yes'
    ) {
      newStatePointerForErrorsCompanyPmt.CompanyPayInvoiceUploadPortalUrl =
        companyPaymentInvoiceDataPointer.CompanyPayInvoiceUploadPortalUrl
          ? ''
          : '*';
    } else if (
      companyPaymentInvoiceDataPointer.CompanyPayDoesInvoiceNeedsUploadToPortal ===
      'no'
    ) {
      newStatePointerForErrorsCompanyPmt.CompanyPayInvoiceUploadPortalUrl = '';
    }

    isValid = Object.values(newStatePointerForErrorsCompanyPmt).every(
      x => x === '',
    );
    // console.log(
    //  companyPaymentInvoiceDataPointer.CompanyPayInvoiceUploadPortalUrl,
    // );

    return isValid;
  };

  const validateBeforeSubmitOrNext = (): boolean => {
    let isValid = true;
    const newGlobalState = { ...globalState };
    if (
      !globalState.invoicesData[globalState.activeTabIndex]
        .isIndividualPaymentInvoice &&
      !globalState.invoicesData[globalState.activeTabIndex]
        .isCompanyPaymentInvoice
    ) {
      newGlobalState.invoicesData[
        globalState.activeTabIndex
      ].isIndividualOrCompanyErrors = 'Please select who pays the invoice';

      setGlobalState(newGlobalState);
      // isValid = false;
      return false;
    }

    if (
      globalState.invoicesData[globalState.activeTabIndex]
        .isIndividualPaymentInvoice
    ) {
      isValid = validateIndividualPaymentInvoiceData();
    } else if (
      globalState.invoicesData[globalState.activeTabIndex]
        .isCompanyPaymentInvoice
    ) {
      // console.log(
      //   '#######Attempting to validate company payment invoice data########',
      // );
      isValid = validateCompanyPaymentInvoceData();
    }

    if (!isValid) {
      newGlobalState.errorMessage = 'Please input all the required fields';
      setGlobalState(newGlobalState);
      // isValid = false;
      /*
      setGlobalState({
        ...globalState,
        errorMessage: 'Please input all the required fields',
      }); */
    } else {
      newGlobalState.errorMessage = '';
      setGlobalState(newGlobalState);
    }

    // console.log(newGlobalState);

    return isValid;
  };

  const handleGoToNextInvoice = (): any => {
    const newInvoicesData = { ...globalState.invoicesData };

    let isValid = true;

    isValid = validateBeforeSubmitOrNext();

    if (isValid) {
      if (!newInvoicesData[globalState.activeTabIndex + 1].newValuesEntered) {
        // copy the address details from this tab to next tab
        const currentIndex = globalState.activeTabIndex;
        const newIndex = globalState.activeTabIndex + 1;
        newInvoicesData[
          newIndex
        ].invoiceDataIfIndividual[0].InvoiceNameOfThePayee =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfIndividual[0].InvoiceNameOfThePayee;
        newInvoicesData[
          newIndex
        ].invoiceDataIfIndividual[0].InvoiceEmailAddress =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfIndividual[0].InvoiceEmailAddress;
        newInvoicesData[
          newIndex
        ].invoiceDataIfIndividual[0].InvoiceAddressLine1 =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfIndividual[0].InvoiceAddressLine1;
        newInvoicesData[
          newIndex
        ].invoiceDataIfIndividual[0].InvoiceAddressLine2 =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfIndividual[0].InvoiceAddressLine2;

        newInvoicesData[newIndex].invoiceDataIfIndividual[0].InvoiceCity =
          newInvoicesData[currentIndex].invoiceDataIfIndividual[0].InvoiceCity;

        newInvoicesData[
          newIndex
        ].invoiceDataIfIndividual[0].InvoiceCountryState =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfIndividual[0].InvoiceCountryState;

        newInvoicesData[newIndex].invoiceDataIfIndividual[0].InvoiceCountry =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfIndividual[0].InvoiceCountry;
        newInvoicesData[newIndex].invoiceDataIfIndividual[0].InvoicePostCode =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfIndividual[0].InvoicePostCode;
        newInvoicesData[
          newIndex
        ].invoiceDataIfIndividual[0].InvoicePhoneNumber =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfIndividual[0].InvoicePhoneNumber;

        /*  newInvoicesData[newIndex].invoiceDataIfIndividual[0].InvoiceReference =
        newInvoicesData[
          currentIndex
        ].invoiceDataIfIndividual[0].InvoiceReference; */

        // copy company payment section values to next screen

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayCompanyName =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayCompanyName;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayInvoiceAddressLine1 =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayInvoiceAddressLine1;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayInvoiceAddressLine2 =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayInvoiceAddressLine2;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayCity =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayCity;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayCountryState =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayCountryState;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayCountry =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayCountry;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayPostCode =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayPostCode;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayRegistrationNumber =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayRegistrationNumber;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayVatNo =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayVatNo;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayBillingContactName =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayBillingContactName;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayBillingContactNumber =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayBillingContactNumber;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayPurchaseOrderNoOrInvoiceRef =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayPurchaseOrderNoOrInvoiceRef;

        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayEmailAddressToSendInvoice =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayEmailAddressToSendInvoice;
        newInvoicesData[
          newIndex
        ].invoiceDataIfCompanyPayment[0].CompanyPayDoesInvoiceNeedsUploadToPortal =
          newInvoicesData[
            currentIndex
          ].invoiceDataIfCompanyPayment[0].CompanyPayDoesInvoiceNeedsUploadToPortal;
      }

      // if not valid error message state is already set by now in the validation function
      setGlobalState({
        ...globalState,
        errorMessage: '',
        activeTabIndex: globalState.activeTabIndex + 1,
        invoicesData: newInvoicesData,
      });
    }
  };

  const handleGobackToPrevStep = (): any => {
    const newStep = globalState.activeStep - 1;
    setGlobalState({
      ...globalState,
      activeStep: newStep,
      // selectedData: [],
      // invoicesData: [],
      isInvoiceTabsActive: false,
    });

    /* if (newStep === 1) {
      setGlobalState({
        ...globalState,
        activeStep: newStep,
        isInvoiceTabsActive: false,
      });
    } */
  };

  const validatePart1 = (): any => {
    let varFormValid = true;
    let varReasonForInvoiceFromChoiceErrors = '';
    let varOtherReasonError = '';
    let varErrorMessage = '';
    let varIsSinglePaymentOrCompanyPaymentErrors = '';
    let varNoPaymentStageSelected = false;

    if (
      !globalState.oneInoiceForAllStages &&
      !globalState.oneInvoiceEachForEachStage
    ) {
      varIsSinglePaymentOrCompanyPaymentErrors =
        'Please select invoice type ( one for all stages or one for each stage ! )';
      // console.log(varErrorMessage);
      varFormValid = false;
    }

    if (!globalState.reasonForInvoiceFromChoice) {
      varReasonForInvoiceFromChoiceErrors = 'Please select invoice reason';

      varFormValid = false;
    }

    if (
      globalState.reasonForInvoiceFromChoice === 862090005 &&
      (globalState.otherReason === null || globalState.otherReason === '')
    ) {
      varOtherReasonError = 'Please input other reason';
      varFormValid = false;
    }

    // make sure atleast one stage selected
    if (
      globalState.selectedData.length === 0 ||
      !globalState.atleastOneStageSelected
    ) {
      varNoPaymentStageSelected = true;
      varErrorMessage = 'Please select atleast one stage';
      varFormValid = false;
    }

    // make sure the selected stage has amount entered or fullamount checkbox checked

    let ii = 0;
    // eslint-disable-next-line no-plusplus
    const varStagesInfo = [...globalState.stageData];

    // loop through all the stages rows
    // eslint-disable-next-line no-plusplus
    for (ii = 0; ii < globalState.stageData.length; ii++) {
      // console.log('inside first for');
      // console.log('inside second loop');
      // console.log(varStagesInfo[ii].invoiceAmountIfNotFullamount);
      if (varStagesInfo[ii].selected) {
        //   console.log(varStagesInfo[ii].invoiceAmountIfNotFullamount);
        if (!varStagesInfo[ii].invoiceAmountIfNotFullamount) {
          //   console.log('Amount empty');

          // console.log(varStagesInfo[ii].isInvoiceForFullAmount);
          // console.log('Either check full amount or enter amount');

          // varStagesInfo[ii].invoiceAmountError =
          varStagesInfo[ii].amountRequiredError = 'Enter amount';

          varFormValid = false;
        } else varStagesInfo[ii].amountRequiredError = '';
        // else if (
        //   varStagesInfo[ii].isInvoiceForFullAmount === true &&
        //   varStagesInfo[ii].invoiceAmountIfNotFullamount.trim() === ''
        // ) {
        //   // varStagesInfo[ii].invoiceAmountError
        //   varStagesInfo[ii].amountRequiredError = 'Please enter the amount';
        //   varFormValid = false;
        //   console.log('Please enter the amount');
        // }
      }
    } // invoiceAmountIfNotFullamount
    // isInvoiceForFullAmount

    setGlobalState({
      ...globalState,
      errorMessage: varErrorMessage,
      reasonForInvoiceFromChoiceErrors: varReasonForInvoiceFromChoiceErrors,
      isSinglePaymentOrCompanyPaymentErrors:
        varIsSinglePaymentOrCompanyPaymentErrors,
      otherReasonError: varOtherReasonError,
      isTheCurrentFormValid: varFormValid,
      noPaymentStageSelected: varNoPaymentStageSelected,

      stageData: varStagesInfo,
    });

    return varFormValid;
  };

  // invoicesDataInitialised(123)

  const GetInvoicesInitializationData = (): any => {
    const newOne: any[] = [];
    // eslint-disable-next-line array-callback-return
    if (globalState.oneInvoiceEachForEachStage) {
      // eslint-disable-next-line array-callback-return
      globalState.selectedData.map((item: any) => {
        newOne.push(invoicesDataInitialised(item.id));
      });
    } else {
      // only one invoice for all
      newOne.push(invoicesDataInitialised(73628736283628362));
    }

    // console.log(newOne);
    return newOne;
  };

  /*  const GetInvoicesInitializationData1 = (): any => {
    const newOne: any[] = [];
    // eslint-disable-next-line array-callback-return
    if (globalState.oneInvoiceEachForEachStage) {
      // eslint-disable-next-line array-callback-return
      globalState.selectedData.map((item: any) => {
        newOne.push({
          isIndividualPaymentInvoice: false,
          isCompanyPaymentInvoice: false,
          id: item.id,
          invoiceDataIfIndividual: [
            {
              InvoiceNameOfThePayee: '',
              InvoiceAddressLine1: '',
              InvoiceAddressLine2: '',
              InvoiceCountryState: '',
              InvoiceCountry: '',
              InvoicePostCode: '',
              InvoiceEmailAddress: '',
              InvoicePhoneNumber: '',
              InvoiceReference: '',
            },
          ],

          invoiceErrorsIfIndividual: [
            {
              InvoiceNameOfThePayee: '',
              InvoiceAddressLine1: '',
              InvoiceAddressLine2: '',
              InvoiceAddress: '',
              InvoiceCountryState: '',
              InvoiceCountry: '',
              InvoicePostCode: '',
              InvoiceEmailAddress: '',
              InvoicePhoneNumber: '',
              InvoiceReference: '',
            },
          ],

          invoiceDataIfCompanyPayment: [
            {
              CompanyPayCompanyName: '',
              CompanyPayInvoiceAddressLine1: '',
              CompanyPayInvoiceAddressLine2: '',
              CompanyPayCountryState: '',
              CompanyPayCountry: '',
              CompanyPayPostCode: '',
              CompanyPayRegistrationNumber: '',
              CompanyPayVatNo: '',
              CompanyPayBillingContactName: '',
              CompanyPayBillingContactNumber: '',
              CompanyPayPurchaseOrderNoOrInvoiceRef: '',
              CompanyPayEmailAddressToSendInvoice: '',
              CompanyPayDoesInvoiceNeedsUploadToPortal: '',
              CompanyPayInvoiceUploadPortalUrl: '',
            },
          ],

          invoiceErrorsIfCompanyPayment: [
            {
              CompanyPayCompanyName: '',
              CompanyPayInvoiceAddressLine1: '',
              CompanyPayInvoiceAddressLine2: '',
              CompanyPayCountryState: '',
              CompanyPayCountry: '',
              CompanyPayPostCode: '',
              CompanyPayRegistrationNumber: '',
              CompanyPayVatNo: '',
              CompanyPayBillingContactName: '',
              CompanyPayBillingContactNumber: '',
              CompanyPayPurchaseOrderNoOrInvoiceRef: '',
              CompanyPayEmailAddressToSendInvoice: '',
              CompanyPayDoesInvoiceNeedsUploadToPortal: '',
              CompanyPayInvoiceUploadPortalUrl: '',
            },
          ],
        });
      });
    } else {
      // only one invoice for all
      newOne.push({
        isIndividualPaymentInvoice: false,
        isCompanyPaymentInvoice: false,
        id: '736287362836283628368randomid',
        invoiceDataIfIndividual: [
          {
            InvoiceNameOfThePayee: '',
            InvoiceAddress: '',
            InvoiceCountryState: '',
            InvoiceCountry: '',
            InvoicePostCode: '',
            InvoiceEmailAddress: '',
            InvoicePhoneNumber: '',
            InvoiceReference: '',
          },
        ],

        invoiceErrorsIfIndividual: [
          {
            InvoiceNameOfThePayee: '',
            InvoiceAddress: '',
            InvoiceCountryState: '',
            InvoiceCountry: '',
            InvoicePostCode: '',
            InvoiceEmailAddress: '',
            InvoicePhoneNumber: '',
            InvoiceReference: '',
          },
        ],

        invoiceDataIfCompanyPayment: [
          {
            CompanyPayCompanyName: '',
            CompanyPayInvoiceAddress: '',
            CompanyPayCountryState: '',
            CompanyPayCountry: '',
            CompanyPayPostCode: '',
            CompanyPayRegistrationNumber: '',
            CompanyPayVatNo: '',
            CompanyPayBillingContactName: '',
            CompanyPayBillingContactNumber: '',
            CompanyPayPurchaseOrderNoOrInvoiceRef: '',
            CompanyPayEmailAddressToSendInvoice: '',
            CompanyPayDoesInvoiceNeedsUploadToPortal: '',
            CompanyPayInvoiceUploadPortalUrl: '',
          },
        ],

        invoiceErrorsIfCompanyPayment: [
          {
            CompanyPayCompanyName: '',
            CompanyPayInvoiceAddress: '',
            CompanyPayCountryState: '',
            CompanyPayCountry: '',
            CompanyPayPostCode: '',
            CompanyPayRegistrationNumber: '',
            CompanyPayVatNo: '',
            CompanyPayBillingContactName: '',
            CompanyPayBillingContactNumber: '',
            CompanyPayPurchaseOrderNoOrInvoiceRef: '',
            CompanyPayEmailAddressToSendInvoice: '',
            CompanyPayDoesInvoiceNeedsUploadToPortal: '',
            CompanyPayInvoiceUploadPortalUrl: '',
          },
        ],
      });
    }

    return newOne;
  }; */

  const handleGoToNext = (): any => {
    if (!validatePart1()) return;

    // console.log('validated##############');

    const newStep = globalState.activeStep + 1;
    setGlobalState({
      ...globalState,
      activeStep: newStep,
    });

    if (newStep === 2) {
      // show the tab  invoices
      // const varInvoicesData = globalState.invoicesData;

      let newOne: any[] = [];
      newOne = GetInvoicesInitializationData();

      let totalInvoiceAmountIfIneInvoiceForAll = 0.0;
      let totalStageAmountOfAllStages = 0.0;
      if (globalState.oneInoiceForAllStages) {
        // eslint-disable-next-line array-callback-return
        globalState.selectedData.map((item: any) => {
          totalInvoiceAmountIfIneInvoiceForAll += parseFloat(
            item.invoiceAmountIfNotFullamount,
          );
          totalStageAmountOfAllStages += parseFloat(item.amountDue);
        });
      }

      if (validatePart1()) {
        setGlobalState({
          ...globalState,
          activeStep: newStep,

          isInvoiceTabsActive: true,
          invoicesData: newOne,
          totalTabsCount: globalState.selectedData.length,
          totalInvoiceAmountIfOneInvoiceForAllStages:
            totalInvoiceAmountIfIneInvoiceForAll,
          totalStagesAmountIfOneInvoiceForAllStages:
            totalStageAmountOfAllStages,
        });
      }
      //
      /*   console.log(
        `varSelectedStagesInfo: ${JSON.stringify(
          globalState.selectedData.length,
        )}`,
      ); */
    }
  };

  const getdataForSubmission = (): any => {
    // globalState.selectedInvoiceData[globalState.selectedTabIndex].,

    const invoiceDataPointer =
      globalState.invoicesData[globalState.activeTabIndex];
    const individualDataPointer = invoiceDataPointer.invoiceDataIfIndividual[0];
    const companyDataPointer =
      invoiceDataPointer.invoiceDataIfCompanyPayment[0];
    /*    InvoiceNameOfThePayee: string;
  InvoiceAddress: string;
  InvoiceCountry: string;
  InvoicePostCode: string;
  InvoiceEmailAddress: string;
  InvoicePhoneNumber: string;
  InvoiceReference: string; */

    /*

  CompanyPayRegistrationNumber: string;
  CompanyPayVatNo: string;
  CompanyPayBillingContactName: string;
  CompanyPayBillingContactNumber: string;
  CompanyPayPurchaseOrderNoOrInvoiceRef: string;
  CompanyPayEmailAddressToSendInvoice: string;
  CompanyPayDoesInvoiceNeedsUploadToPortal: string; */

    const stageIdsAndAmounts: any[] = [];
    // let stageIdsAndAmounts
    if (globalState.oneInvoiceEachForEachStage) {
      stageIdsAndAmounts.push({
        stageId: invoiceDataPointer.id,
        amount:
          globalState.selectedData[globalState.activeTabIndex]
            .invoiceAmountIfNotFullamount,
      });
    } else {
      // eslint-disable-next-line array-callback-return
      globalState.selectedData.map((item: any) => {
        stageIdsAndAmounts.push({
          stageId: item.id,
          amount: item.invoiceAmountIfNotFullamount,
        });
      });
    }

    const dataToSubmit = {
      jbs_additionalinformation: '',

      // jbs_billingcontactname
      jbs_billingcontactname: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoiceNameOfThePayee
        : companyDataPointer.CompanyPayBillingContactName,

      jbs_billingcontactphonenumber:
        invoiceDataPointer.isIndividualPaymentInvoice
          ? individualDataPointer.InvoicePhoneNumber
          : companyDataPointer.CompanyPayBillingContactNumber,
      // jbs_billingcontactphonenumber: 447584325691,

      jbs_addressline1: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoiceAddressLine1
        : companyDataPointer.CompanyPayInvoiceAddressLine1,

      jbs_addressline2: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoiceAddressLine2
        : companyDataPointer.CompanyPayInvoiceAddressLine2,
      // jbs_applicationinvoiceid: '', // '4990b731-46c8-eb11-bacc-000d3ab727b3',

      // jbs_applicationinvoiceid: '', // '4990b731-46c8-eb11-ba
      //  jbs_city: 'cambridge',
      jbs_city: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoiceCity
        : companyDataPointer.CompanyPayCity,
      jbs_companyregistrationnumber:
        invoiceDataPointer.isIndividualPaymentInvoice
          ? ''
          : companyDataPointer.CompanyPayRegistrationNumber,
      // jbs_country: '100000241',
      jbs_country: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoiceCountry
        : companyDataPointer.CompanyPayCountry,

      jbs_countystate: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoiceCountryState
        : companyDataPointer.CompanyPayCountryState,

      /*   jbs_country: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoiceAddress
        : companyDataPointer.CompanyPayInvoiceAddress, */

      // jbs_VATnumber  --add

      jbs_VATnumber: invoiceDataPointer.isIndividualPaymentInvoice
        ? ''
        : companyDataPointer.CompanyPayVatNo,

      // jbs_countystate: 'camb shire',
      jbs_emailaddress: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoiceEmailAddress
        : companyDataPointer.CompanyPayEmailAddressToSendInvoice,
      jbs_name: globalState.selectedData[globalState.activeTabIndex].title, //   'TEST INVOICE FROM END POINT', // need to get the name from somewhere
      jbs_paymentname: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoiceNameOfThePayee
        : companyDataPointer.CompanyPayCompanyName,

      /* jbs_phonenumber: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoicePhoneNumber
        : companyDataPointer.CompanyPayBillingContactNumber, */
      jbs_ponumber: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoiceReference
        : companyDataPointer.CompanyPayPurchaseOrderNoOrInvoiceRef,
      jbs_postcode: invoiceDataPointer.isIndividualPaymentInvoice
        ? individualDataPointer.InvoicePostCode
        : companyDataPointer.CompanyPayPostCode,

      // Change_BEFORE_LIVE
      jbs_reason:
        globalState.reasonForInvoiceFromChoice === 862090005
          ? globalState.otherReason
          : '',

      jbs_invoicereason: globalState.reasonForInvoiceFromChoice,
      // reasonForInvoiceFromChoice
      // jbs_uploadrequired: false,

      jbs_uploadrequired:
        invoiceDataPointer.isCompanyPaymentInvoice &&
        companyDataPointer.CompanyPayDoesInvoiceNeedsUploadToPortal === 'yes',

      // CHECk_THIS before live
      jbs_companyportalurl:
        invoiceDataPointer.isCompanyPaymentInvoice &&
        companyDataPointer.CompanyPayDoesInvoiceNeedsUploadToPortal === 'yes'
          ? companyDataPointer.CompanyPayInvoiceUploadPortalUrl
          : '',

      _jbs_application_value: userState.appID,
      Stages: stageIdsAndAmounts,

      // jbs_requestforindividual: invoiceDataPointer.isIndividualPaymentInvoice,

      // jbs_requestforcompany: invoiceDataPointer.isCompanyPaymentInvoice,

      individualRequest: invoiceDataPointer.isIndividualPaymentInvoice,

      companyRequest: invoiceDataPointer.isCompanyPaymentInvoice,
      /*   Stages: [
        {
          id: invoiceDataPointer.id,
          amount:
            globalState.selectedData[globalState.activeTabIndex]
              .invoiceAmountIfNotFullamount,
        },
      ], */
    };

    //  console.log('Invoice for each stage data:');
    //  console.log(dataToSubmit);

    return dataToSubmit;
  };

  // const submitStatus = false;

  const handleSubmitThisInvoice = async (): Promise<boolean> => {
    const isValid = validateBeforeSubmitOrNext();
    if (!isValid) return false;

    // return false;

    const status = await submitData(); // ask, why this status not returned by submit function
    // console.log('Data submitted');
    // console.log(`status: ${submitStatus}`);
    if (status) {
      // setGlobalState(newGlobalState);
      // console.log()
      // toast.success('Invoice request sent');

      // eslint-disable-next-line react-hooks/rules-of-hooks

      return true;
    }

    return false;
  };

  const submitData = async (): Promise<boolean> => {
    const data = getdataForSubmission();

    // console.log('submitting ......');
    try {
      const newData = data;

      await api
        .post(`/Payment/RequestInvoice`, newData, {
          headers: {
            cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
            Authorization: `Bearer ${userState.idToken}`,
          },
        })

        .then(() => {
          // console.log('response data invoice submit:');
          // console.log(resp.data);
          // setAward(resp.data);
          const newGlobalState = { ...globalState };
          if (globalState.oneInoiceForAllStages)
            newGlobalState.submittedAllSelectedStagesDataForSingleInvoice =
              true;
          else if (globalState.oneInvoiceEachForEachStage) {
            newGlobalState.selectedData[
              globalState.activeTabIndex
            ].invoiceRequestSubmitted = true;

            newGlobalState.invoicesData[
              globalState.activeTabIndex
            ].requestSubmitted = true;
          }
          const varNoOfInvoicesSubmitted =
            newGlobalState.noOfInvoicesSubmitted + 1;

          newGlobalState.noOfInvoicesSubmitted = varNoOfInvoicesSubmitted;

          newGlobalState.errorMessage = '';

          setGlobalState(newGlobalState);
          // eslint-disable-next-line react-hooks/rules-of-hooks

          toast.success('Invoice request sent');

          // eslint-disable-next-line react-hooks/rules-of-hooks
          if (
            varNoOfInvoicesSubmitted === globalState.selectedData.length ||
            globalState.oneInoiceForAllStages
          ) {
            // if this is last invoices to be submitted , take the user back to home after success
            setTimeout(() => {
              history.push('/');
            }, 5200);
          }
          // submitStatus = true;
          return true;
          // handleClose();
        });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    return false;
  };

  const handleSubmitAll = async (): Promise<boolean> => {
    const isValid = validateBeforeSubmitOrNext();
    if (!isValid) return false;

    const newGlobalState = { ...globalState };
    newGlobalState.submittedAllSelectedStagesDataForSingleInvoice = true;

    // setGlobalState(newGlobalState);
    const status = await submitData(); // ask, why this status not returned by submit function

    return status;
  };

  return (
    <>
      <ToastContainer />

      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to="/"
          style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
        >
          <StyledBreadcrumb label="Home" icon={<HomeIcon fontSize="small" />} />
        </Link>
        <Link
          to="/makeInvoice"
          style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
        >
          <StyledBreadcrumb label="Invoices" />
        </Link>
      </Breadcrumbs>
      <form>
        <div
          style={{
            padding: '10px',
          }}
        >
          <Title>Request invoice</Title>
          <Grid container>
            <Grid>
              {globalState.activeStep === 1 ? (
                <Part1
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                />
              ) : (
                ''
              )}

              {globalState.activeStep === 2 ? (
                <InvoiceTabs
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                />
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            style={{ padding: '20px' }}
          >
            {globalState.oneInvoiceEachForEachStage &&
            globalState.activeTabIndex > 0 ? (
              <Grid
                item
                xs={8}
                sm={8}
                hidden={!globalState.isInvoiceTabsActive}
              >
                <Controls.Button
                  onClick={handleGobackToPreviousInvoice}
                  type="button"
                  text="PREVIOUS"
                  variant="contained"
                  color="primary"
                  style={
                    {
                      // backgroundColor: '#DCDCDC',
                      // color: '#000',
                    }
                  }
                />
              </Grid>
            ) : (
              <Grid
                item
                xs={8}
                sm={8}
                hidden={!globalState.isInvoiceTabsActive}
              >
                {globalState.noOfInvoicesSubmitted > 0 ? (
                  ''
                ) : (
                  <Controls.Button
                    onClick={handleGobackToPrevStep}
                    type="button"
                    text="CANCEL"
                    variant="contained"
                    color="primary"
                    // style={{ color: 'brown' }}

                    // backgroundColor: '#DCDCDC'
                  />
                )}
              </Grid>
            )}

            <Grid
              item
              xs={4}
              sm={4}
              hidden={
                !globalState.isInvoiceTabsActive
                // ||
                // globalState.oneInoiceForAllStages
              }
            >
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                {globalState.oneInvoiceEachForEachStage &&
                globalState.atleastOneStageSelected ? (
                  <Controls.Button
                    onClick={handleSubmitThisInvoice}
                    type="button"
                    // hidden={!globalState.oneInvoiceEachForEachStage}
                    text={
                      !globalState.selectedData[globalState.activeTabIndex]
                        .invoiceRequestSubmitted
                        ? 'SUBMIT'
                        : 'SUBMITTED'
                    }
                    disabled={
                      globalState.selectedData[globalState.activeTabIndex]
                        .invoiceRequestSubmitted
                    }
                    variant="contained"
                    color="primary"
                  />
                ) : (
                  ''
                )}

                {globalState.oneInoiceForAllStages &&
                globalState.atleastOneStageSelected ? (
                  <Controls.Button
                    onClick={handleSubmitAll}
                    type="button"
                    // hidden={!globalState.oneInvoiceEachForEachStage}
                    text={
                      !globalState.submittedAllSelectedStagesDataForSingleInvoice
                        ? 'SUBMIT'
                        : 'SUBMITTED'
                    }
                    disabled={
                      globalState.submittedAllSelectedStagesDataForSingleInvoice
                    }
                    variant="contained"
                    color="primary"
                  />
                ) : (
                  ''
                )}
                {globalState.oneInvoiceEachForEachStage &&
                globalState.activeTabIndex < globalState.totalTabsCount - 1 ? (
                  <Controls.Button
                    onClick={handleGoToNextInvoice}
                    type="button"
                    text="NEXT"
                    variant="contained"
                    color="primary"
                    disabled={
                      !globalState.selectedData[globalState.activeTabIndex]
                        .invoiceRequestSubmitted
                    }
                    style={{ marginLeft: '10px' }}
                  />
                ) : (
                  ''
                )}
              </div>
            </Grid>
          </Grid>
          {globalState.activeStep <= globalState.totalSteps ? (
            <Grid container>
              <Grid
                container
                justifyContent="flex-end"
                style={{ padding: '5px' }}
              >
                <Grid item xs={2}>
                  <Controls.Button
                    onClick={handleGoToNext}
                    text="INVOICE DETAILS"
                    //  variant="outlined"
                    variant="contained"
                    color="primary"
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ''
          )}

          <Grid container>
            <div
              style={{
                display: 'block',
                width: '100%',
                marginRight: '10px',
                marginTop: '10px',
                textAlign: 'right',
                color: 'red',
              }}
            >
              {globalState.errorMessage}
            </div>
          </Grid>

          {/*  <input
            value="show state"
            type="button"
            onClick={() => {
              console.log(globalState);
              console.log(globalState.invoicesData[0]);
              console.log(
                globalState.invoicesData[0].invoiceDataIfIndividual[0],
              );

              // console.log(datato)
            }}
          /> */}
        </div>
      </form>
    </>
  );
}
