import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { toast, ToastContainer } from 'react-toastify';

import { useDropzone } from 'react-dropzone';

import { PhotoCamera } from '@material-ui/icons';
import ReactLoading from 'react-loading';

import styled from 'styled-components';

import {
  Paper,
  Grid,
  Button,
  Typography,
  CardContent,
  Card,
  Breadcrumbs,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import HomeIcon from '@material-ui/icons/Home';
import api from '../../../services/api';
import GridPayment from './GridPayment';
import { currencyFormatter } from '../../../components/Utils/currencyFormatter';
import { StyledBreadcrumb } from '../../../components/dev2Controls/StyledBreadCrumb';
import { useGlobalState } from '../../../services/context/GlobalStateProvider';

const getColor = (props: any): any => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    fontSize: 14,
  },
});

interface Props {
  location: any;
}

export default function BacsDetail({ location }: Props): any {
  const { userState } = useGlobalState();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map(file => (
    <li key={file.name} style={{ listStyleType: 'none' }}>
      - {file.name}
    </li>
  ));

  const classes = useStyles();
  const [loading, setLoading] = useState<any>(false);
  // const [, setImages] = useState<any>('');

  const history = useHistory();
  const day = new Date().toISOString().slice(0, 10);

  const { state } = location;

  const validateGemba = state.makePayment[0].appNumber;
  state.makePayment[0].appNumber = validateGemba.replace('GEMBA', 'GEMB');

  function checkIfFilesAreTooBig(file: any): boolean {
    let valid = true;

    const size = file.size / 1024 / 1024;

    if (size > 4) {
      valid = false;
    }

    return valid;
  }

  function checkIfFilesAreCorrectType(file: any): boolean {
    let valid = true;

    if (
      !['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'].includes(
        file.type,
      )
    ) {
      valid = false;
    }
    return valid;
  }

  const handleSubmit = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.set('document', acceptedFiles[0]);
      formData.set('applicationId', userState.appID);

      if (acceptedFiles.length === 0) {
        try {
          const stages: any[] = [];

          state.makePayment.map((e: any) =>
            stages.push({
              stageID: e.id,
              amount: e.toPay,
            }),
          );
          const transactionAmount = state.makePayment.reduce(
            (r: any, a: any) => {
              return r + a.toPay;
            },
            0,
          );

          const newData = {
            title: `${state.makePayment[0].appNumber}-${day}`,
            transactionAmount,
            paymentReference: `NS-${state.makePayment[0].appNumber}`,
            reference: null,
            planStageID: state.makePayment[0].id,
            paymentEvidence: null,
            transactionTypeID: 862090001,
            paymentMethodId: 862090000,
            billingAmount: transactionAmount,
            billingAuthorisation: null,
            billingResponse: null,
            applicationId: userState.appID,
            stages,
          };

          await api
            .post(`/Payment/MakeTransaction`, newData, {
              headers: {
                cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
                Authorization: `Bearer ${userState.idToken}`,
              },
            })
            .then(() => {
              toast.success('Payment sent');
              setTimeout(() => {
                history.push(`/`);
              }, 4200);
            });
        } catch (err) {
          // console.log(err.response.error);
        }
      } else {
        try {
          if (!checkIfFilesAreTooBig(acceptedFiles[0])) {
            setLoading(false);
            return toast.error('File too big');
          }
          if (!checkIfFilesAreCorrectType(acceptedFiles[0])) {
            setLoading(false);
            return toast.error('Wrong type file');
          }
          await api
            .post('Document/UploadFile/', formData, {
              headers: {
                cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
                Authorization: `Bearer ${userState.idToken}`,
              },
            })
            .then(async response => {
              const { data } = response;

              const newUrl = data;

              const stages: any[] = [];

              state.makePayment.map((e: any) =>
                stages.push({
                  stageID: e.id,
                  amount: e.toPay,
                }),
              );
              const transactionAmount = state.makePayment.reduce(
                (r: any, a: any) => {
                  return r + a.toPay;
                },
                0,
              );

              const newData = {
                title: `${state.makePayment[0].appNumber}-${day}`,
                transactionAmount,
                paymentReference: `NS-${state.makePayment[0].appNumber}`,
                reference: null,
                planStageID: state.makePayment[0].id,
                paymentEvidence: newUrl,
                transactionTypeID: 862090001,
                paymentMethodId: 862090000,
                billingAmount: transactionAmount,
                billingAuthorisation: null,
                billingResponse: null,
                applicationId: userState.appID,
                stages,
              };

              await api
                .post(`/Payment/MakeTransaction`, newData, {
                  headers: {
                    cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
                    Authorization: `Bearer ${userState.idToken}`,
                  },
                })
                .then(() => {
                  toast.success('Payment sent');
                  setTimeout(() => {
                    history.push(`/`);
                  }, 4200);
                });
            });
        } catch (err) {
          // console.log(err.response.error);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      // console.log(err.response);
    }
    return '';
  }, [
    acceptedFiles,
    state.makePayment,
    day,
    userState.appID,
    userState.idToken,
    history,
  ]);

  // const onChange = (): any => {
  //   // console.log(acceptedFiles);
  //   // console.log(imageList);
  //   state.images = acceptedFiles;
  //   setImages(acceptedFiles);
  // };

  const totalValue = state.makePayment.reduce((r: any, a: any) => {
    return r + a.toPay;
  }, 0);

  return (
    <Paper style={{ padding: '10px' }}>
      <ToastContainer />
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          to="/"
          style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
        >
          <StyledBreadcrumb label="Home" icon={<HomeIcon fontSize="small" />} />
        </Link>
        <Link
          to="/makepayments"
          style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
        >
          <StyledBreadcrumb label="Make Payments" />
        </Link>

        <StyledBreadcrumb label="Details" />
      </Breadcrumbs>
      <br />
      <Alert severity="info">
        <b>
          The University manages a single bank account for its 150+ departments,
          handling numerous daily payments. For smooth BACS payment processing,
          use the reference shown below and attach your remittance advice.
          Missing these may cause delays in confirming and displaying your
          payment on the portal.
        </b>
      </Alert>

      <br />

      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <GridPayment makePayment={state.makePayment} />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Card
            className={classes.root}
            variant="outlined"
            style={{ height: '100%' }}
          >
            <h3
              style={{ color: '#ffb81c', padding: '6px', marginLeft: '10px' }}
            >
              Information:
            </h3>
            <CardContent>
              <Typography className={classes.pos} color="textSecondary">
                <b>Step 1</b>: Make your payment of{' '}
                <b>{currencyFormatter(Number(totalValue))}</b> using the bank
                account details provided, ensuring that you quote the unique
                payment reference of :<br />
              </Typography>
              <Alert severity="warning">
                {`NS-${state.makePayment[0].appNumber}`}
              </Alert>
              <br />

              <Typography className={classes.pos} color="textSecondary">
                <b>Step 2</b>: Upload a copy of your payment confirmation where
                indicated.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Card
            className={classes.root}
            variant="outlined"
            style={{ height: '100%' }}
          >
            <h3
              style={{ color: '#ffb81c', padding: '6px', marginLeft: '10px' }}
            >
              Bank account details
            </h3>
            <CardContent>
              <Typography variant="h5" component="h2">
                University of Cambridge
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Barclays Bank, St. Andrews Street Branch
                <br />
                9-11 St. Andrews Street, Cambridge CB2 3AA
              </Typography>
              <Typography variant="body2" component="p">
                Swift Code – BARCGB22
                <br />
                IBAN - GB21 BARC 2017 6810 9210 84
                <br />
                Account No –10921084
                <br />
                Sort Code – 20 – 17 – 68
                <br />
                Currency GBP
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Card
            className={classes.root}
            variant="outlined"
            style={{ height: '100%' }}
          >
            <h3
              style={{ color: '#ffb81c', padding: '6px', marginLeft: '10px' }}
            >
              Upload payment confirmation.
            </h3>

            <h4 style={{ padding: '6px', marginLeft: '10px' }}>
              Once you have made your BACS payment, please upload (or drag) a
              copy of your payment confirmation below, then press ‘Confirm
              payment’
            </h4>
            <CardContent>
              <Alert severity="warning">
                Supported file types - <b>jpeg, png, jpg, pdf</b>
              </Alert>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12} style={{ padding: '10px' }}>
                  <section className="container">
                    <div className="container">
                      <Container {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>
                          <PhotoCamera fontSize="large" />
                        </p>
                      </Container>
                    </div>
                    {acceptedFiles.length !== 0 ? (
                      <aside>
                        <h4>File</h4>
                        <ul>{files}</ul>
                      </aside>
                    ) : (
                      ''
                    )}
                  </section>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{
            padding: '15px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {loading ? (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <ReactLoading
                type="bubbles"
                color="#ffb81c"
                height="3%"
                width="3%"
              />
            </Grid>
          ) : (
            <Button
              variant="outlined"
              style={{ marginRight: '10px', color: '#00b33c' }}
              onClick={() => {
                setLoading(true);
                handleSubmit();
              }}
              // onClick={{ handleSubmit }}
            >
              {/* <FiCheck style={{ marginRight: '10px' }} /> */}
              Confirm payment
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
