import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: #ffb81c;
`;

export const Item = styled.div`
  display: flex;

  align-items: center;
  justify-content: first baseline;
  flex-direction: column;
  height: 100%;
  width: 100%;

  img {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
`;
