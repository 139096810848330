import { Configuration, RedirectRequest } from '@azure/msal-browser';

const production = 'pay.jbs.cam.ac.uk';
const url = window.location.hostname;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId:
      production === url
        ? 'be4d33a2-d85b-4ea2-9169-19813f41963f'
        : 'a72f1642-9244-4d43-91b4-66d1840e2d4b',
    authority:
      production === url
        ? 'https://cjbsadb2cproduction.b2clogin.com/cjbsadb2cproduction.onmicrosoft.com/B2C_1_signupsignin'
        : 'https://cjbsadb2cdev.b2clogin.com/cjbsadb2cdev.onmicrosoft.com/B2C_1_signupsignin',
    knownAuthorities:
      production === url
        ? ['cjbsadb2cproduction.b2clogin.com']
        : ['cjbsadb2cdev.b2clogin.com'],
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: false,
  },
  // Enable logging of MSAL events for easier troubleshooting.
  // This should be disabled in production builds.
  system: {
    // logger: logger as any,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ['https://cjbsadb2cdev.onmicrosoft.com/read/user_impersonation'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMePhoto: 'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
  graphPersonal: 'https://graph.microsoft.com/v1.0/me',
};
