/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ReactLoading from 'react-loading';
import { format } from 'date-fns';
import { useStateValue } from '../StateContext';
import api from '../../../../../../services/api';
import { useGlobalState } from '../../../../../../services/context/GlobalStateProvider';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Confirmation({ makePayment }: any): any {
  const { userState } = useGlobalState();
  const [{ formValues }] = useStateValue();

  const [wpm, setWpm] = useState<any>('');
  const [loading, setLoading] = useState<any>(false);
  const classes = useStyles();

  const handleURL = (): any => {
    const production = 'pay.jbs.cam.ac.uk';
    const staging = 'cjbs-webapp-payment-portal-staging.azurewebsites.net';
    const dev = `cjbs-webapp-payment-portal-development.azurewebsites.net`;
    const localhost = 'localhost';
    const url = window.location.hostname;

    if (url === dev || url === localhost) {
      return 'https://onlinepayments.admin.cam.ac.uk/cjbstest';
    }
    if (url === staging) {
      return 'https://onlinepayments.admin.cam.ac.uk/cjbstest';
    }
    if (url === production) {
      return 'https://onlinepayments.admin.cam.ac.uk/cjbs';
    }
    return 'none';
  };

  const handleSubmit = useCallback(async () => {
    try {
      const day = new Date().toISOString().slice(0, 10);
      const stages: any[] = [];

      makePayment.map((e: any) =>
        stages.push({
          stageID: e.id,
          amount: e.toPay,
        }),
      );
      const transactionAmount = parseFloat(
        makePayment.reduce((r: any, a: any) => {
          return r + a.toPay;
        }, 0),
      );

      const newData = {
        title: `${makePayment[0].appNumber}-${format(
          new Date(),
          'dd/MM/yyyy',
        )}`,
        transactionAmount,
        paymentReference: `NS-${makePayment[0].appNumber}-${day}`,
        reference: null,
        planStageID: makePayment[0].id,
        paymentEvidence: null,
        transactionTypeID: 862090001,
        paymentMethodId: 862090001,
        billingAmount: transactionAmount,
        billingAuthorisation: null,
        billingResponse: null,
        applicationId: userState.appID,
        stages,
      };

      await api
        .post(`/Payment/MakeTransaction`, newData, {
          headers: {
            cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
            Authorization: `Bearer ${userState.idToken}`,
          },
        })
        .then(response => {
          const programme = makePayment[0].appNumber.split('-');
          const newData1 = {
            programme: programme[0],
            ProgrammeCode: userState.programme,
            firstname: formValues.firstname,
            middlename: formValues.middlename,
            title: formValues.title,
            lastname: formValues.lastname,
            emailfrom: 'no-reply@jbs.cam.ac.uk',
            toemail: formValues.toemail,
            redirecturl: `http://localhost:3000/payment/${response.data.id}`,
            billaddress1: formValues.billaddress1,
            billaddress2: formValues.billaddress2,
            billaddress3: formValues.billaddress3,
            billtown: formValues.billtown,
            billcounty: formValues.billcounty,
            billpostcode: formValues.billpostcode,
            billcountry: formValues.billcountry.code,
            amounttopay: transactionAmount,
            amounttopayvat: 0,
            amounttopayexvat: transactionAmount,
            vatrate: 0,
            customfield1: null,
            id: response.data.id,
            customerId: makePayment[0].appNumber,
            fullname: `${formValues.firstname} ${formValues.middlename} ${formValues.lastname}`,
            // paymentdate: '2021-08-19T13:46:48.801Z',
            programName: programme[0],
            applicationId: userState.appID,
          };

          api
            .post(`/Payment/MakePayment`, newData1, {
              headers: {
                cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
                Authorization: `Bearer ${userState.idToken}`,
              },
            })
            .then(response1 => {
              setLoading(false);

              setWpm(response1.data);
            });
        });
    } catch (err) {
      // eslint-disable-next-line no-console
      // console.log(err.response);
    }
    return '';
  }, [
    formValues.billaddress1,
    formValues.billaddress2,
    formValues.billaddress3,
    formValues.billcountry.code,
    formValues.billcounty,
    formValues.billpostcode,
    formValues.billtown,
    formValues.firstname,
    formValues.lastname,
    formValues.middlename,
    formValues.title,
    formValues.toemail,
    makePayment,
    userState.idToken,
    userState.appID,
  ]);

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12}>
          <Typography variant="h6">Confirmation</Typography>
        </Grid>
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Grid item xs={12} sm={12}>
              <b>Title:</b> {formValues.title}
            </Grid>
            <Grid item xs={12} sm={12}>
              <b>Name:</b> {formValues.firstname} {formValues.middlename}{' '}
              {formValues.lastname}
              <br /> <b>Email:</b> {formValues.toemail}
            </Grid>

            <Grid item xs={12} sm={12}>
              <b>Bill Address 1:</b> {formValues.billaddress1}
              <br /> <b>City:</b> {formValues.billtown} {formValues.billcounty}{' '}
              <br />
              <b>Post Code:</b> {formValues.billpostcode}
            </Grid>
            {formValues.billaddress2 !== '' && (
              <Grid item xs={12} sm={12}>
                <b>Bill Address 2:</b> {formValues.billaddress2}
              </Grid>
            )}
            {formValues.billaddress3 !== '' && (
              <Grid item xs={12} sm={12}>
                <b>Bill Address 3:</b> {formValues.billaddress3}
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <b>Country:</b> {formValues.billcountry.name}
            </Grid>
            <Grid item xs={12} sm={12}>
              {' '}
              <br />
              {wpm === '' ? (
                <Alert severity="info">Confirm your details</Alert>
              ) : (
                <Alert severity="warning">
                  When you click pay, you will be redirected to another site
                </Alert>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12}>
        {wpm === '' ? (
          loading ? (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <ReactLoading
                type="bubbles"
                color="#ffb81c"
                height="3%"
                width="3%"
              />
            </Grid>
          ) : (
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff' }}
              onClick={() => {
                setLoading(true);
                handleSubmit();
              }}
              fullWidth
            >
              <b>Confirm </b>
            </Button>
          )
        ) : (
          <form
            method="post"
            action={handleURL()}
            encType="application/x-www-form-urlencoded"
          >
            <input
              id="wpmpaymentrequestmsgid"
              name="wpmpaymentrequestmsgid"
              defaultValue={wpm.wpmpaymentrequestmsgid}
              hidden
            />

            <input id="xml" name="xml" defaultValue={wpm.xml} hidden />

            <Button
              variant="contained"
              style={{
                color: '#fff',
                backgroundColor: '#00b33c',
              }}
              fullWidth
              type="submit"
            >
              <b>Pay </b>
            </Button>
          </form>
        )}
      </Grid>
    </>
  );
}
