import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Description from '@material-ui/icons/Description';

import { Link } from 'react-router-dom';

import AssignmentIcon from '@material-ui/icons/Assignment';

export const mainListItems = (
  <div>
    <Link
      to="/"
      style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
    >
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
    <Link
      to="/Award"
      style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
    >
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Payment Awards" />
      </ListItem>
    </Link>
    <Link
      to="/paymentplans"
      style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
    >
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Payment Plans" />
      </ListItem>
    </Link>
    <Link
      to="/myinvoices"
      style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
    >
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Invoices" />
      </ListItem>
    </Link>
    <Link
      to="/makePayments"
      style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
    >
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Outstanding payments" />
      </ListItem>
    </Link>
    <Link
      to="/history"
      style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
    >
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Payment History" />
      </ListItem>
    </Link>
  </div>
);

export const documents = (
  <div>
    <a
      href="/doc/CJBS%20Finance%20Portal%20-%20Student%20User%20Guide%201.0.pdf"
      target="_blank"
      style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
    >
      <ListItem button>
        <ListItemIcon>
          <Description />
        </ListItemIcon>
        <ListItemText primary="User Guide" />
      </ListItem>
    </a>
  </div>
);
