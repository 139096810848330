/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
// <Switch> is unique in that it renders a route exclusively.
import { Switch, Route } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import Error from '../components/Error';
import Maintenance from '../components/Maintenance';
import Home from '../pages/Home';
import Award from '../pages/Award';
import PaymentPlans from '../pages/PaymentPlans';
import MakePayments from '../pages/MakePayments';
import BacsDetail from '../pages/MakePayments/components/BacsDetail';
import CardDetail from '../pages/MakePayments/components/CardDetails';
import PaymentResult from '../pages/MakePayments/components/CardDetails/PaymentResult';
import Upload from '../pages/MakePayments/components/Upload';
import History from '../pages/PaymentHistory';

import Layout from '../components/layout';
import { StateProvider } from '../pages/MakePayments/components/CardDetails/Stepper/StateContext';
import MakeInvoice from '../pages/MakeInvoice';
import MyInvoices from '../pages/MakeInvoice/components/MyInvoices';
import { useGlobalState } from '../services/context/GlobalStateProvider';
import api from '../services/api';
import MakePayment from '../pages/MakePayments/components/MakePayment';

function Routes(): JSX.Element {
  // maintenance - true / false
  const [maintenance] = useState(false);
  //
  const { instance, accounts } = useMsal();
  const { setUserState } = useGlobalState();
  const [idToken, setIdToken] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [valueError, setValueError] = useState('');

  useEffect(() => {
    if (accounts.length > 0) {
      const request = {
        scopes: ['openid'],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then(response => {
          setIdToken({
            idToken: response.idToken,
            email: accounts[0]!.idTokenClaims!.emails![0],
            name: accounts[0].name,
          });
        })
        .catch(value => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          if (value instanceof InteractionRequiredAuthError) {
            instance.acquireTokenPopup(request).then(response => {
              setIdToken({
                idToken: response.idToken,
                email: accounts[0]!.idTokenClaims!.emails![0],
                name: accounts[0].name,
              });
            });
          }
        });
    }
  }, [accounts, instance]);

  useEffect(() => {
    async function loadData(): Promise<void> {
      await api
        .get(`/payment/getApplication?email=${idToken.email}`, {
          headers: {
            cjbsapikey: '044d94f6-dc61-4504-b4a1-7a9ec236e0a5',
            Authorization: `Bearer ${idToken.idToken}`,
          },
        })
        .then(async response => {
          const values = response.data;
          if (
            values.appStatus !== 935520002 &&
            values.appStatus !== 935520009 &&
            values.appStatus !== 935520007
          ) {
            setError(true);
            setValueError('Offer not found');
            return '';
          }
          setUserState({
            idToken: idToken.idToken,
            email: idToken.email,
            name: idToken.name,
            appID: values.appID,
            appNumber: values.appNumber,
            programmeID: values.programmeID,
            programme: values.programme,
          });
        })
        .catch(() => {
          setError(true);
          setValueError('User not found.');
        });
      setLoading(false);
    }

    if (idToken) {
      loadData();
    }
  }, [idToken, setUserState]);

  if (maintenance) {
    return <Maintenance />;
  }

  if (loading) {
    return <div>loading</div>;
  }

  if (error) {
    return <Error valueError={valueError} />;
  }

  return (
    <Layout>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/award" exact component={Award} />
        <Route path="/paymentplans" exact component={PaymentPlans} />
        <Route path="/makepayment" exact component={MakePayment} />
        <Route path="/makepayments" exact component={MakePayments} />
        <Route path="/bacsDetail" exact component={BacsDetail} />
        <Route path="/upload" exact component={Upload} />
        <Route path="/history" exact component={History} />
        <Route path="/makeInvoice" exact component={MakeInvoice} />
        <Route path="/myInvoices" exact component={MyInvoices} />
        <Route path="/Payment/Details/:id" exact component={PaymentResult} />
        <StateProvider>
          <Route path="/cardDetail" exact component={CardDetail} />
        </StateProvider>
      </Switch>
    </Layout>
  );
}

export default Routes;
