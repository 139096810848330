import React from 'react';
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Paper,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import { IglobalState } from './interfaces';
import CompanyPayment from './CompanyPayment';
import IndividualPayment from './IndividualPayment';
import Title from '../../../components/items/Title';

function TabPanel(props: any): any {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

interface Props {
  globalState: IglobalState;
  setGlobalState: any;
}
export default function InvoiceTabs({
  globalState,
  setGlobalState,
}: Props): any {
  let tabsData = [];

  const tabsDataIfSingleInvoiceFoaAll = [{ title: 'Invoice', index: 0 }];
  if (globalState.oneInvoiceEachForEachStage)
    tabsData = globalState.selectedData;
  else tabsData = tabsDataIfSingleInvoiceFoaAll;

  const value = globalState.activeTabIndex;

  const handleTabChange = (event: any, newValue: any): any => {
    setGlobalState({
      ...globalState,
      activeTabIndex: newValue,
    });
  };

  if (!globalState.invoicesData) {
    return 'Loading';
  }

  return (
    <>
      <Paper style={{ padding: '10px', width: '100%' }}>
        <Grid container spacing={3} style={{ padding: '10px' }}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons="auto"
              // indicatorColor="primary"
              // textColor="primary"

              // style={{ backgroundColor: '#F5F5F5', color: '#000' }}
            >
              {tabsData?.map(row => (
                <Tab label={row.title} key={row.title} disabled />
              ))}
            </Tabs>
          </AppBar>
          <br />
          <Grid container spacing={3} alignContent="center" alignItems="center">
            <Grid item xs={12} style={{ padding: '20px' }}>
              <br />
              {globalState.oneInvoiceEachForEachStage ? (
                <Alert severity="info">
                  You have choosen one invoice for each stage
                </Alert>
              ) : (
                <Alert severity="info">
                  You have choosen one invoice for all stages , submit the data
                  in the final section.
                </Alert>
              )}
            </Grid>
          </Grid>

          {tabsData?.map(row => {
            return (
              <TabPanel
                value={value}
                index={row.index}
                key={row.index}
                style={{ width: '100%' }}

                // disabled={globalState.invoicesData[row.index].requestSubmitted}
              >
                <div>
                  <Title>{row.title}</Title>
                  <Paper style={{ padding: '10px' }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3} lg={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isIndividualPAyment" //         {row.title}
                              disabled={
                                globalState.invoicesData[row.index]
                                  .requestSubmitted
                              }
                              checked={
                                globalState.invoicesData[value]
                                  .isIndividualPaymentInvoice
                              }
                              onChange={(event: any): any => {
                                const newGlobalState = { ...globalState };
                                newGlobalState.invoicesData[
                                  row.index
                                ].isIndividualPaymentInvoice =
                                  event.target.checked; //
                                newGlobalState.invoicesData[
                                  row.index
                                ].isIndividualOrCompanyErrors = '';
                                newGlobalState.invoicesData[
                                  row.index
                                ].isCompanyPaymentInvoice =
                                  !event.target.checked; //

                                setGlobalState(newGlobalState);
                              }}
                            />
                          }
                          label="Is individual payment"
                        />
                      </Grid>

                      <Grid item xs={12} sm={3} lg={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isCompanyPayment"
                              disabled={
                                globalState.invoicesData[row.index]
                                  .requestSubmitted
                              }
                              checked={
                                globalState.invoicesData[row.index]
                                  .isCompanyPaymentInvoice
                              }
                              onChange={event => {
                                const checked1 = event.target.checked;

                                const newInvoicesData = {
                                  ...globalState.invoicesData,
                                };

                                const singleInvoiceData =
                                  newInvoicesData[row.index];
                                singleInvoiceData.isIndividualPaymentInvoice =
                                  !checked1;
                                singleInvoiceData.isIndividualOrCompanyErrors =
                                  '';
                                singleInvoiceData.isCompanyPaymentInvoice =
                                  checked1;
                                newInvoicesData[row.index] = singleInvoiceData;

                                setGlobalState({
                                  ...globalState,
                                  invoicesData: newInvoicesData,
                                });
                              }}
                            />
                          }
                          label="Is company payment"
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <Typography component="legend" color="error">
                          <b>
                            {
                              globalState.invoicesData[value]
                                .isIndividualOrCompanyErrors
                            }
                          </b>
                        </Typography>
                      </Grid>
                    </Grid>

                    {globalState.invoicesData != null &&
                    globalState.invoicesData[row.index]
                      .isIndividualPaymentInvoice ? (
                      <IndividualPayment
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                        // arrayIndex={value}
                      />
                    ) : (
                      ''
                    )}
                    {globalState.invoicesData != null &&
                    globalState.invoicesData[row.index]
                      .isCompanyPaymentInvoice ? (
                      <CompanyPayment
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                        // arrayIndex={value}
                      />
                    ) : (
                      ''
                    )}
                  </Paper>
                </div>{' '}
              </TabPanel>
            );
          })}
        </Grid>
      </Paper>
    </>
  );
}
