import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';
import MakePayments from './components/MakePayments';

// import loadAwards from './hooks/loadAwards';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 250,
  },
}));

const Home: React.FC = () => {
  const classes = useStyles();

  // const awards = loadAwards();

  // console.log(awards);

  return (
    <>
      <Grid container spacing={1}>
        {/* Chart */}

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <MakePayments />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
