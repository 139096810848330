import React, { useState } from 'react';

import { Stepper, Step, StepLabel, Button, Grid } from '@material-ui/core';
import StepperIcons from './StepperIcons';
import StepConnector from './StepConnector';
import ContactForm from './Forms/ContactForm';
import AddressForm from './Forms/AddressForm';
import Confirmation from './Forms/Confirmation';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Steppers({ makePayment }: any): any {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (): any =>
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  const handleBack = (): any =>
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  const handleReset = (): any => setActiveStep(0);

  const StepContent = ({ step }: any): any => {
    switch (step) {
      case 0:
        return <ContactForm />;
      case 1:
        return <AddressForm />;
      case 2:
        return <Confirmation makePayment={makePayment} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Stepper
        alternativeLabel
        connector={<StepConnector />}
        activeStep={activeStep}
        style={{ padding: '10px' }}
      >
        {/* Change the number of loops here based on StepContent */}
        {[1, 2, 3].map(e => (
          <Step key={e}>
            <StepLabel StepIconComponent={StepperIcons} />
          </Step>
        ))}
      </Stepper>

      <Grid
        container
        spacing={3}
        direction="column"
        justifyContent="space-around"
        style={{ padding: '20px' }}
      >
        {activeStep === 3 ? (
          <Button onClick={handleReset}>Reset</Button>
        ) : (
          <Grid container spacing={3}>
            <StepContent step={activeStep} />
            <Grid container item justifyContent="flex-end">
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                style={{ marginRight: '10px' }}
              >
                Back
              </Button>
              {activeStep === 2 ? (
                ''
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
