const initialState = {
  formValues: {
    title: '',
    firstname: '',
    middlename: '',
    lastname: '',
    emailfrom: 'no-reply@jbs.cam.ac.uk',
    toemail: '',
    billaddress1: '',
    billaddress2: '',
    billaddress3: '',
    billtown: '',
    billcountry: null,
    billcounty: '',
    billpostcode: '',
    currency: null,
    amount: '',
  },
};

interface IValues {
  date: any;
  service: any;
  facebook: any;
  twitter: any;
  firstname: any;
  lastname: any;
  email: any;
  line1: any;
  line2: any;
  postalCode: any;
  city: any;
  country: any;
  currency: any;
  amount: any;
}

interface IState {
  formValues: [IValues];
}

interface IAction {
  key: any;
  type: any;
  value: any;
}

const reducer = (state: IState, action: IAction): any => {
  switch (action.type) {
    case 'editFormValue':
      // eslint-disable-next-line no-param-reassign
      state.formValues[action.key.toLowerCase()] = action.value;
      return { ...state };

    case 'emptyFormValue':
      return {
        ...state,
        formValues: initialState.formValues,
      };
    default:
  }
  return state;
};

export { initialState, reducer };
