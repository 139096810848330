/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TextField } from '@material-ui/core';
import React from 'react';

// interface Props {
//   name: string;
//   label: string;
//   value: string;
//   onChange: any;
//   error: string;
//   other: string;
// }

export default function Input(props: any): any {
  const { name, label, value, onChange, error = null, ...other } = props;

  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      {...other}
      // {...(error && { error: true, helperText: error })}
      {...(error && { error: true })}
    />
  );
}

/* import { TextField } from '@material-ui/core';
import React from 'react';

interface Props {
  name: string;
  label: string;
  value: string;
  // onChange: void;
  onChange: (e: React.FormEvent<HTMLFormElement>) => void;
  error: string;
  other: string;
}

export default function Input(props: Props): any {
  const { name, label, value, onChange, error = null, ...other } = props;

  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
 */
